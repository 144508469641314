import { sha256 } from 'js-sha256';
import { v4 as uuidv4 } from 'uuid';

/**
 * Use it to generate a unique ID based on the provided content
 * Combines SHA-256 hash of the content with a UUID to ensure uniqueness
 *
 * @param content - The text content to hash
 * @returns A unique, stable ID string
 */
export const generateUnique = (content: string): string => {
  const hash = content ? sha256(content) : '';
  const uuid = uuidv4();
  return `${hash}-${uuid}`;
};

/**
 * Use it to generate a unique ID based on the provided blob
 *
 * @param blob - The blob to hash
 * @returns A unique, stable ID string
 */
export const generateBinaryHash = async (blob: Blob): Promise<string> => {
  const arrayBuffer = await blob.arrayBuffer();
  const uint8Array = new Uint8Array(arrayBuffer);
  return sha256(uint8Array);
};
