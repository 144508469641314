import { alertController, modalController } from '@ionic/vue';
import { cloneDeep, pick } from 'lodash';
import { openDocBrowserContextSheet } from './actionSheetComponents';
import { isBlob, isWikiTemplateGuard } from './guards';
import {
  openTitleChangeModal,
  openWikiFollowersModal,
  openWikiTemplatesModal,
  openWikiTemplateSaveModal,
  openShareArchiveLinkModal,
} from './modalComponents';
import {
  DataViewMode,
  DocumentTypeEnum,
  WikiActionEnum,
  WikiEditControlsEnum,
  FileStatusEnum,
  DocumentExtensionEnum,
  WikiDeleteOptionsEnum,
  WikiDownloadOptionsEnum,
  WikiVersionEnum,
  WikiEditOptionsEnum,
  WikiSaveModeEnum,
  WikiMajorFilterEnum,
  EditModeEnum,
  AppIconsEnum,
  UserRoleEnum,
  GroupsAccessEnum,
  ActionAccessEnum,
  ShareArchiveLinkType,
  ShareEntityType,
} from '@/enums';
import {
  useFilesHybrid,
  openWikiReplaceActionsPopover,
  openDocsAttachmentModal,
  openDocsCreateFileModal,
  openDocsMoveFileModal,
  openWikiCreateModal,
  openWikiRelationsModal,
  openWikiHistoryModal,
  formatDateHelper,
  openWikiTemplatesPopover,
  isNativeMobile,
  useToasts,
  canShare,
  canSendArchivedLink,
  shareEntity,
  useErrors,
  openDocsActionsPopover,
  createCellTemplate,
  openWikiSavePopover,
} from '@/helpers';
import { useI18n } from '@/i18n';
import { defaultAdvancedWikiParticipantsModel } from '@/models';
import router, { ROUTES_NAME } from '@/router';
import { useWikiStore, useNetworkStore, useAppStore, useDocStore, useUserStore, useGroupsStore } from '@/store';
import type {
  WikiModel,
  WikiHistoryModel,
  MediaModel,
  AppActionButton,
  WikiActionsMenuModel,
  EditOptions,
  EditControls,
  CreateWikiPayload,
  UpdateWikiPayload,
  WikiEditFormModel,
  TabCategories,
  WikiTemplateModel,
  CreateWikiTemplateModel,
  WikiActionPayload,
  WikiControlPayload,
  WikiOptionPayload,
  WikiHistoricalModel,
  MenuItemModel,
} from '@/types';
import { CellTemplateProp, ColumnRegular } from '@revolist/vue3-datagrid';
import { VGridVueTemplate } from '@revolist/vue3-datagrid';
import { AppTableActionBtn } from '@/components';

export type HistoricalButtons = {
  left: AppActionButton;
  right: AppActionButton;
};

export type IUseWiki = {
  //#region Getters
  /** Use to get menu items for wiki actions */
  getActionsMenuItems: (wiki: WikiModel) => WikiActionsMenuModel[];
  /** Use to get menu items for templates */
  getTemplatesMenuItems: () => TabCategories<WikiEditControlsEnum>[];
  /** Use to get edit options based on the edit mode */
  getEditOptions: (mode: EditModeEnum) => EditOptions[];
  /** Use to get edit controls based on the edit mode */
  getEditControls: (mode: EditModeEnum) => EditControls[];
  /** Use to get the table headers for templates */
  getTemplatesTableHeader: () => ColumnRegular[];
  /**
   * Use to check if the user is allowed to create a wiki in the given group or in the general feed.
   * If `groupId` is NOT null, checks access for the group.
   * If `groupId` is null, checks access for the general feed.
   */
  getCreateAccess: (groupId: number | null) => boolean;
  /** Use to get historical buttons for a wiki version */
  getHistoricalButtons: (mode: DataViewMode) => HistoricalButtons;
  /** Use to get the avatar of the user who modified the wiki */
  getHistoricalModifiedByAvatar: (wiki: WikiHistoryModel) => MediaModel | null;
  //#endregion

  //#region Handlers
  /** Use to handle a specific action for a wiki */
  handleAction: (payload: WikiActionPayload) => Promise<number | undefined>;
  /** Use to handle the selected edit option */
  handleOption: (payload: WikiOptionPayload) => Promise<void>;
  /** Use to handle the selected edit control */
  handleControl(payload: WikiControlPayload): Promise<void>;
  //#endregion

  //#region Miscellaneous
  /** Use to check if the user is not allowed to edit a wiki with the given ID */
  preventEdit: (id: number) => Promise<boolean>;
  /** Use to open the actions menu for a wiki */
  openActionsMenu: (event: Event, wiki: WikiModel) => Promise<void>;
  //#endregion
};

export const useWiki = (): IUseWiki => {
  //#region Helpers
  const { t } = useI18n();
  const { showSonnerToast } = useToasts();
  //#endregion

  //#region Private methods
  /** @private */
  async function _replaceFromDocBrowser(wiki: WikiModel): Promise<boolean> {
    try {
      const result = await openDocsAttachmentModal(null);

      if (result) {
        const data = result;

        data.forEach(async (element) => {
          if (element.documentType === DocumentTypeEnum.Wiki) {
            await useWikiStore().deleteWithReplace(wiki.id, element.data.id, null);
          } else {
            await useWikiStore().deleteWithReplace(wiki.id, null, element.data.id);
          }
        });
      }

      return false;
    } catch (e) {
      console.error('[ERROR] Failed to _replaceFromDocBrowser', e);
      return false;
    }
  }

  /** @private */
  async function _replaceWithNewWiki(wiki: WikiModel): Promise<boolean> {
    const result = await openWikiCreateModal(wiki.group?.id, wiki?.parentFolderId ?? undefined, false);

    if (result) {
      await useWikiStore().deleteWithReplace(wiki.id, result, null);
    }

    return false;
  }

  /** @private */
  async function _replaceWithNewFile(wiki: WikiModel): Promise<boolean> {
    const result = await openDocsCreateFileModal();

    if (result !== null) {
      await useWikiStore().deleteWithReplace(wiki.id, null, result.id);
    }

    return false;
  }

  /**
   * @private
   * @note Not in use
   * @todo Refactor deletion wih replacement flow
   * @link https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1578
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function _replace(wiki: WikiModel): Promise<boolean> {
    const result = await openWikiReplaceActionsPopover();

    if (!result) return false;

    if (result === WikiDeleteOptionsEnum.ReplaceFromDocBrowser) {
      await _replaceFromDocBrowser(wiki);
      return true;
    }

    if (result === WikiDeleteOptionsEnum.ReplaceWithNewWiki) {
      await _replaceWithNewWiki(wiki);
      return true;
    }

    if (result === WikiDeleteOptionsEnum.ReplaceWithNewFile) {
      await _replaceWithNewFile(wiki);
      return true;
    }

    return false;
  }

  /** @private */
  async function _deleteTemplate(templateId: number, templateName: string): Promise<void> {
    const deleteTemplate = async () => {
      const result = await useWikiStore().deleteTemplateById(templateId);
      if (result) {
        showSonnerToast(t('wiki.templates.delete.success'), true);
      } else {
        showSonnerToast(t('wiki.templates.delete.error'), false);
      }
    };

    const alert = await alertController.create({
      header: t('wiki.templates.delete.title'),
      message: t('wiki.templates.delete.confirm', { name: templateName }),
      buttons: [
        {
          text: t('cancel'),
          role: 'cancel',
          cssClass: 'custom-alert-buttons',
        },
        {
          text: t('confirm'),
          cssClass: 'custom-alert-buttons',
          handler: async () => {
            await deleteTemplate();
            await alertController.dismiss();
          },
        },
      ],
    });

    await alert.present();
  }

  /** @private */
  async function _updateExistingWiki(payload: UpdateWikiPayload, isModal: boolean): Promise<number | null> {
    const result = await useWikiStore().update({
      mentionedUserIds: useWikiStore().editForm.mentionedUserIds ?? [],
      ...payload,
    });

    if (!result) {
      showSonnerToast(t('wiki.editMessages.updateError'), false);
      return null;
    }

    showSonnerToast(t('wiki.editMessages.updatedSuccess'), true);

    await _leaveEditPage(payload.wikiId, isModal);
    return payload.wikiId;
  }

  /** @private */
  async function _onNewWikiCreate(payload: CreateWikiPayload, isModal: boolean): Promise<number | null> {
    const createdId = await useWikiStore().create(payload);

    if (!createdId) {
      console.warn('[WARN] Error during creating wiki');
      showSonnerToast(t('wiki.editMessages.createError'), false);
      return null;
    }

    showSonnerToast(t('wiki.editMessages.createSuccess'), true);
    await _leaveEditPage(createdId, isModal);
    return createdId;
  }

  /** @private */
  async function _createConfirmationAlert(message: string, buttons: any[]): Promise<void> {
    try {
      const alert = await alertController.create({
        message,
        buttons,
      });
      await alert.present();
    } catch (e) {
      console.error('[ERROR] Failed to create confirmation alert', e);
      showSonnerToast(t('documents.popup.deleteError'), false);
    }
  }

  /**
   * @private
   * @param versionId Deletion of version is not implemented yet. Do not use it
   */
  async function _performDelete(id: number, versionId?: number): Promise<void> {
    try {
      let success;
      if (versionId) {
        success = await useWikiStore().deleteVersion(versionId);
      } else {
        success = await useWikiStore().delete(id);
      }

      if (success) {
        showSonnerToast(t('documents.popup.deleteSuccess'), true);
      } else {
        showSonnerToast(t('documents.popup.deleteError'), false);
      }
    } catch (e) {
      console.error('[ERROR] Failed to delete wiki', e);
      showSonnerToast(t('documents.popup.deleteError'), false);
    }
  }
  //#endregion

  //#region Getters
  function getActionsMenuItems(wiki: WikiModel): WikiActionsMenuModel[] {
    const menuItems = [
      {
        title: t(`wiki.menuActions.edit`),
        value: WikiActionEnum.Edit,
        icon: AppIconsEnum.PencilSquare,
        disabled: !wiki.access.includes(ActionAccessEnum.Edit),
      },
      {
        title: t(`files.menu.downloadAsPDF`),
        value: WikiDownloadOptionsEnum.DownloadAsPDF,
        icon: AppIconsEnum.Download,
        disabled: false,
      },
      {
        title: t(`files.menu.downloadAsDOCX`),
        value: WikiDownloadOptionsEnum.DownloadAsDOCX,
        icon: AppIconsEnum.Download,
        disabled: false,
      },
      {
        title: t('files.menu.share'),
        icon: AppIconsEnum.Share,
        disabled: !(wiki.access.includes(ActionAccessEnum.Share) && canShare()),
        value: WikiActionEnum.Share,
      },
      {
        title: t('feed.conversationPostMenu.send.shareArchiveLink'),
        icon: AppIconsEnum.ArchiveLink,
        disabled: !(wiki.access.includes(ActionAccessEnum.Share) && canShare() && canSendArchivedLink()),
        value: WikiActionEnum.ShareArchiveLink,
      },
      {
        title: wiki.isOfficial ? t(`wiki.menuActions.unmarkOfficial`) : t(`wiki.menuActions.markOfficial`),
        value: WikiActionEnum.MarkOfficial,
        icon: wiki.isOfficial ? AppIconsEnum.Star : AppIconsEnum.StarOutline,
        disabled: !wiki.access.includes(ActionAccessEnum.MarkAsOfficial),
      },
      {
        title: t(`wiki.menuActions.showRelations`),
        value: WikiActionEnum.ShowRelations,
        icon: AppIconsEnum.Link,
        disabled: true,
      },
      {
        title: wiki.isFollowed ? t(`subscribe.unfollow`) : t(`subscribe.follow`),
        value: wiki.isFollowed ? WikiActionEnum.Unfollow : WikiActionEnum.Follow,
        icon: wiki.isFollowed ? AppIconsEnum.NotificationsOff : AppIconsEnum.Notifications,
        disabled: false,
      },
      {
        title: t(`wiki.menuActions.showFollowers`),
        value: WikiActionEnum.ShowFollowers,
        icon: AppIconsEnum.Users,
        disabled: !useNetworkStore().showFollowLists,
      },
      {
        title: t(`wiki.menuActions.move.title`),
        value: WikiActionEnum.Move,
        icon: AppIconsEnum.Move,
        disabled: !wiki.access.includes(ActionAccessEnum.Move),
      },
      {
        title: t(`wiki.menuActions.showHistory`),
        value: WikiActionEnum.ShowHistory,
        icon: AppIconsEnum.DocVersions,
        disabled: !wiki.access.includes(ActionAccessEnum.Edit),
      },
      {
        title: t(`wiki.menuActions.rollback.title`),
        value: WikiActionEnum.Rollback,
        icon: AppIconsEnum.History,
        disabled: !wiki.access.includes(ActionAccessEnum.Edit) || !useWikiStore().historicalWiki,
      },
      {
        title: t(`wiki.menuActions.delete`),
        value: WikiActionEnum.Delete,
        icon: AppIconsEnum.Remove,
        disabled: !wiki.access.includes(ActionAccessEnum.Delete),
      },
    ];

    return menuItems.filter(({ disabled }) => !disabled);
  }

  function getTemplatesMenuItems(): TabCategories<WikiEditControlsEnum>[] {
    const currentUserRoleId = useUserStore().current?.roleId ?? UserRoleEnum.ExternalGroupUserReadLike;

    return [
      {
        value: WikiEditControlsEnum.ChooseTemplate,
        active: true,
        icon: AppIconsEnum.DocText,
        title: t('wiki.templates.choose.title'),
      },
      {
        value: WikiEditControlsEnum.SaveAsTemplate,
        active: currentUserRoleId >= UserRoleEnum.Administrator,
        icon: AppIconsEnum.Download,
        title: t('wiki.templates.save.title'),
      },
      {
        value: WikiEditControlsEnum.UpdateTemplate,
        active: currentUserRoleId >= UserRoleEnum.Administrator,
        icon: AppIconsEnum.Refresh,
        title: t('wiki.templates.update.title'),
      },
    ].filter((item) => item.active);
  }

  /** @private */
  function _getSaveOptions(showMinorSaveOption: boolean): MenuItemModel<WikiSaveModeEnum>[] {
    return [
      {
        value: WikiSaveModeEnum.Major,
        title: t('wiki.editMessages.saveMode.major'),
        icon: AppIconsEnum.None,
        disabled: false,
      },
      {
        value: WikiSaveModeEnum.Minor,
        title: t('wiki.editMessages.saveMode.minor'),
        icon: AppIconsEnum.None,
        disabled: !showMinorSaveOption,
      },
    ].filter(({ disabled }) => !disabled);
  }

  function getEditOptions(mode: EditModeEnum): EditOptions[] {
    const options = [
      {
        title: t('wiki.editOptions.title'),
        action: WikiEditOptionsEnum.EditTitle,
        value: useWikiStore().editForm.name || t('titleChangeModal.placeholder.title'),
      },
      {
        title: t('wiki.editOptions.group'),
        action: WikiEditOptionsEnum.None,
        value: useWikiStore().getGroupTitle,
      },
      {
        title: t('wiki.editOptions.folder'),
        action: WikiEditOptionsEnum.None,
        value: useWikiStore().getFolderTitle,
      },
      {
        title: t('wiki.editOptions.author'),
        action: WikiEditOptionsEnum.None,
        value: mode === EditModeEnum.Edit ? (useWikiStore().getCreatedBy?.fullName ?? '') : '',
      },
      {
        title: t('wiki.editOptions.createdAt'),
        action: WikiEditOptionsEnum.None,
        value: mode === EditModeEnum.Edit ? formatDateHelper(useWikiStore().getCreationDate, 'long') : '',
      },
      {
        title: t('wiki.editOptions.lastEditedBy'),
        action: WikiEditOptionsEnum.None,
        value: mode === EditModeEnum.Edit ? (useWikiStore().getLastEditor?.fullName ?? '') : '',
      },
      {
        title: t('wiki.editOptions.lastEditedAt'),
        action: WikiEditOptionsEnum.None,
        value: mode === EditModeEnum.Edit ? formatDateHelper(useWikiStore().getLastEditDate, 'long') : '',
      },
      {
        title: t('wiki.editOptions.outdated'),
        action: WikiEditOptionsEnum.Outdated,
        value: '',
      },
    ];

    return options.filter(({ action, value }) => action === WikiEditOptionsEnum.EditTitle || value !== '');
  }

  function getEditControls(mode: EditModeEnum): EditControls[] {
    const controls = [
      {
        title: t('wiki.editControls.goToCurrentVersion'),
        icon: AppIconsEnum.Eye,
        disabled: true,
        value: WikiActionEnum.ToCurrent,
        isDropdown: false,
      },
      {
        title: t('wiki.editControls.deleteNote'),
        icon: AppIconsEnum.Trash,
        disabled: mode !== EditModeEnum.Edit && !useWikiStore().existingWiki?.id,
        value: WikiEditControlsEnum.Delete,
        isDropdown: false,
      },
      {
        title: t('wiki.templates.title'),
        icon: AppIconsEnum.DocText,
        disabled: !useNetworkStore().isAdvancedWikiesEditor,
        value: WikiEditControlsEnum.TemplatesMenu,
        isDropdown: true,
      },
    ];

    return controls.filter(({ disabled }) => !disabled);
  }

  function getTemplatesTableHeader(): ColumnRegular[] {
    const isSMWidth = useAppStore().isSMWidth;
    const currentUserRoleId = useUserStore().current?.roleId ?? UserRoleEnum.ExternalGroupUserReadLike;

    const _nameRenderer = (data: CellTemplateProp) => {
      const wiki = data.model;
      if (!isWikiTemplateGuard(wiki)) return '';

      return `<span style="display:block; font-size: 0.9rem; padding-bottom: 0.4rem; font-weight: 500;">${wiki.name}</span><span style="display:block;padding-bottom: 0.2rem;font-size: 0.8rem">${wiki.createdBy.fullName}</span><span style="font-size: 0.8rem"> ${formatDateHelper(wiki.createdAt, 'short')}</span>`;
    };

    const _deleteColumn = {
      prop: 'table-action-button',
      size: 64,
      name: '',
      cellTemplate: VGridVueTemplate(AppTableActionBtn, {
        onAction: (data: WikiTemplateModel) => {
          try {
            _deleteTemplate(data?.id, data?.name);
          } catch (e) {
            console.error('[ERROR] Failed to delete template', e);
          }
        },
        icon: AppIconsEnum.Trash,
      }),
    };

    const _mobileHeader = [
      {
        prop: 'name' as keyof WikiTemplateModel,
        size: 240,
        name: t('wiki.templates.templateName'),
        cellTemplate: createCellTemplate(_nameRenderer, 'lh-1 ta-start'),
      },
      ...(currentUserRoleId >= UserRoleEnum.Administrator ? [_deleteColumn] : []),
    ];

    const _desktopHeader = [
      {
        prop: 'name' as keyof WikiTemplateModel,
        size: 240,
        name: t('wiki.templates.templateName'),
        cellTemplate: createCellTemplate((props) => props.model.name),
      },
      {
        prop: 'createdBy' as keyof WikiTemplateModel,
        size: 180,
        name: t('wiki.table.author'),
        cellTemplate: createCellTemplate((props) => props.model.createdBy.fullName),
      },
      {
        prop: 'createdAt' as keyof WikiTemplateModel,
        size: 120,
        name: t('wiki.table.date'),
        cellTemplate: createCellTemplate((props) => formatDateHelper(props.model.createdAt, 'short')),
      },
      ...(currentUserRoleId >= UserRoleEnum.Administrator ? [_deleteColumn] : []),
    ];

    return isSMWidth ? _desktopHeader : _mobileHeader;
  }

  function getCreateAccess(groupId: number | null): boolean {
    const allowPostToFeed = useNetworkStore().settings?.allowPostToFeed ?? false;
    const currentUserRoleId = useUserStore().current?.roleId ?? UserRoleEnum.ExternalGroupUserReadLike;

    return groupId
      ? useGroupsStore().getGroupById(groupId).accessType >= GroupsAccessEnum.Member
      : allowPostToFeed && currentUserRoleId >= UserRoleEnum.User;
  }

  function getHistoricalButtons(mode: DataViewMode): HistoricalButtons {
    return {
      left: {
        title: t('wiki.menuActions.rollback.title'),
        value: WikiActionEnum.Rollback,
        type: 'main',
        icon: AppIconsEnum.History,
        showIcon: mode === DataViewMode.List,
        showTooltip: mode === DataViewMode.List,
        showTitle: false,
      },
      right: {
        title: t('wiki.menuActions.compare'),
        value: WikiActionEnum.CompareHistorical,
        type: 'main',
        icon: AppIconsEnum.Comparison,
        showIcon: mode === DataViewMode.List,
        showTooltip: mode === DataViewMode.List,
        showTitle: false,
      },
    };
  }

  function getHistoricalModifiedByAvatar(wiki: WikiHistoryModel): MediaModel | null {
    if (wiki.modifyUserId) {
      const uId = wiki.modifyUserId;
      const user = useUserStore().getUserProfile(uId);
      return user?.avatar;
    }
    return null;
  }

  /** @private */
  function _getPreparedWikiTemplateData(editForm: WikiEditFormModel): CreateWikiTemplateModel | null {
    const isAdvancedWikiesEditor = useNetworkStore().isAdvancedWikiesEditor;
    if (isAdvancedWikiesEditor && editForm.wikiContent) {
      return {
        name: '',
        content: pick(editForm.wikiContent, ['body', 'head', 'content']),
      };
    } else if (editForm.wikiText) {
      console.warn('[WARN] Simple-wiki templates is not supported');
      return null;
    } else {
      console.warn('[WARN] No data to create template');
      return null;
    }
  }
  //#endregion

  //#region Actions
  /** @private */
  async function _create(): Promise<undefined> {
    try {
      await router.push({
        name: ROUTES_NAME.WIKI_CREATE,
      });
    } catch (e) {
      console.error('[ERROR] Failed to go to wiki edit page', e);
    }
  }

  async function _open(id: number, versionId?: number): Promise<void> {
    await router.push({
      name: ROUTES_NAME.WIKI_BY_ID,
      params: { id, versionId },
    });
  }

  /** @private */
  async function _edit(wiki: WikiModel | undefined): Promise<undefined> {
    if (!wiki) {
      console.warn('[WARN] Wiki is not defined');
      return;
    }

    useWikiStore().setPartialEditForm({
      groupId: wiki.group?.id ?? null,
      folderId: wiki.parentFolderId ?? null,
    });

    await router.push({
      name: ROUTES_NAME.WIKI_EDIT,
      params: { id: wiki.id },
    });
  }

  /** @private */
  async function _move(id: number): Promise<undefined> {
    try {
      const result = await openDocsMoveFileModal(null);
      if (!result) {
        console.warn('[WARN] No folder selected');
        return;
      }

      await useDocStore().moveWiki(result.folderId, result.groupId, id);

      showSonnerToast(t('wiki.menuActions.move.success'), true);
      await useWikiStore().wikiById(id);
    } catch (e) {
      console.error('[ERROR] Failed to move wiki', e);
      showSonnerToast(t('wiki.menuActions.move.error'), false);
    }
  }

  /** @private */
  async function _markOfficial(id: number): Promise<undefined> {
    try {
      const topModal = await modalController.getTop();
      if (topModal?.id === 'wiki_actions') {
        await modalController.dismiss(null, 'end', 'wiki_actions');
      }
    } catch (e) {
      console.error('[ERROR] Error dismissing modal:', e);
    }

    await useWikiStore().markOfficial(id);
  }

  /** @private */
  async function _showRelations(id: number): Promise<undefined> {
    await useWikiStore().getRelations(id);
    await openWikiRelationsModal();
  }

  /** @private */
  async function _showFollowers(id: number): Promise<undefined> {
    await useWikiStore().getFollowers(id);
    await openWikiFollowersModal();
  }

  /** @private */
  async function _showHistory(id: number, fromComparePage?: boolean): Promise<number | undefined> {
    const existingWiki = useWikiStore().existingWiki;
    if (!existingWiki || existingWiki.id !== id) {
      await useWikiStore().wikiById(id);
    }

    if (!useWikiStore().getHistory.length || !fromComparePage) {
      const result = await useWikiStore().allHistory(id);
      if (!result) {
        console.warn('[WARN] Failed to get history');
        showSonnerToast('Failed to get updated history. Please refresh the page.', false);
        return;
      }
    }

    return await openWikiHistoryModal(id, fromComparePage);
  }

  /** @private */
  async function _rollback(id: number, versionId: number | undefined): Promise<undefined> {
    if (!id || !versionId) {
      console.warn('[WARN] Failed to rollback wiki: id or versionId is not defined');
      return;
    }

    try {
      await useWikiStore().rollback(id, versionId);
      showSonnerToast(t('wiki.menuActions.rollback.success'), true);
    } catch (e) {
      console.error('[ERROR] Failed to rollback wiki', e);
      showSonnerToast(t('wiki.menuActions.rollback.error'), false);
    }
  }

  /** @private */
  async function _compareHistorical(
    sourceId?: number,
    versionIds?: number[],
    fromComparePage = false
  ): Promise<undefined> {
    if (!sourceId && !versionIds) {
      console.warn('[WARN] Failed to compare historical: sourceId or ids are not defined');
      return;
    }

    let tId = 0;
    let sId = 0;

    if (versionIds) {
      tId = versionIds[0];
      sId = versionIds[1];
    } else if (sourceId) {
      tId = useWikiStore().getHistory[0].id;
      sId = sourceId;
    }

    useWikiStore().setOnCompare(
      useWikiStore().getHistoryWikiById(tId),
      useWikiStore().getHistoryWikiById(sId),
      useWikiStore().existingWiki?.name ?? ''
    );

    if (!tId || !sId) {
      console.warn('[WARN] Failed to compare historical: tId or sId is not defined');
      return;
    }

    if (!fromComparePage) {
      await router.push({
        name: ROUTES_NAME.WIKI_COMPARE,
        params: { id: useWikiStore().existingWiki?.id },
        query: {
          targetId: tId,
          sourceId: sId,
        },
      });
    }
  }

  /**
   * @private
   * @param versionId Deletion of version is not implemented yet. Do not use it
   */
  async function _deleteWiki(id: number, versionId?: number): Promise<undefined> {
    if (!id && !versionId) {
      console.warn('[WARN] Failed to delete wiki: id and versionId are empty');
      return;
    }

    try {
      let name;
      if (versionId) {
        const historical = await useWikiStore().historyById(versionId);
        name = historical?.name;
      } else if (id) {
        let wiki: WikiModel | null = null;

        const existingWiki = useWikiStore().existingWiki;
        if (!existingWiki || existingWiki.id !== id) {
          wiki = await useWikiStore().wikiById(id);
        } else {
          wiki = existingWiki;
        }

        if (!wiki) {
          console.warn('[WARN] Failed to delete wiki');
          return;
        }

        name = wiki?.name;
      }

      const message = `${t('documents.popup.deleteWiki')} <strong>${name}</strong>?`;
      const buttons = [
        {
          text: t('no'),
          role: 'cancel',
          cssClass: 'custom-alert-buttons',
        },
        {
          text: t('yes'),
          cssClass: 'custom-alert-buttons',
          handler: async () => {
            await _performDelete(id, versionId);
            await alertController.dismiss();
            if (router.currentRoute.value.name === ROUTES_NAME.DOCS) return;
            await router.push({ name: ROUTES_NAME.DOCS });
          },
        },
      ];

      await _createConfirmationAlert(message, buttons);
    } catch (e) {
      console.error('[ERROR] Failed to initiate wiki deletion', e);
      showSonnerToast(t('documents.popup.deleteError'), false);
    }
  }

  /** @private */
  async function _follow(id: number): Promise<undefined> {
    await useWikiStore().follow(id);
  }

  /** @private */
  async function _unfollow(id: number): Promise<undefined> {
    await useWikiStore().unfollow(id);
  }

  /** @private */
  async function _download(wiki: WikiModel | undefined, documentExtension: DocumentExtensionEnum): Promise<undefined> {
    if (!wiki) {
      console.warn('[WARN] Wiki is not defined');
      return;
    }

    const response = await useWikiStore().download(wiki.id, documentExtension);

    if (isBlob(response)) {
      const result = await useFilesHybrid().downloadWiki(wiki, response);
      showSonnerToast(t('files.successDownloaded'), result === FileStatusEnum.Success);
    } else {
      console.warn(`[WARN] Response is not a Blob: ${JSON.stringify(response)}`);
    }
  }
  //#endregion

  //#region Edit Options
  /** @private */
  async function _editTitle(): Promise<void> {
    const existingWiki = useWikiStore().existingWiki;
    const editForm = useWikiStore().editForm;

    const result = await openTitleChangeModal(null, existingWiki?.name ?? editForm.name ?? '');
    if (result?.title) {
      useWikiStore().setHasChanged(true);
      useWikiStore().setSimpleWiki('name', result?.title);
    }
  }

  /**
   * @private
   * @todo Implement
   */
  function _showAuthor() {
    console.warn('[WARN] _showAuthor is not implemented yet');
  }

  /**
   * @private
   * @todo Implement
   */
  function _showLastEditor() {
    console.warn('[WARN] _showLastEditor is not implemented yet');
  }
  /**
   * @private
   * @todo Implement
   */
  function _showGroup() {
    console.warn('[WARN] _showGroup is not implemented yet');
  }
  /**
   * @private
   * @todo Implement
   */
  function _showFolder() {
    console.warn('[WARN] _showFolder is not implemented yet');
  }
  /**
   * @private
   * @todo Implement
   */
  function _showOutdated() {
    console.warn('[WARN] _showOutdated is not implemented yet');
  }
  //#endregion

  //#region Edit Controls
  /** @private */
  async function _leaveEditPage(id?: number, isModal?: boolean): Promise<undefined> {
    try {
      useWikiStore().resetFromEditPage();

      if (isModal) {
        await modalController.dismiss(id);
        return;
      }

      if (!id) {
        router.back();
        return;
      }

      await _open(id);
    } catch (e) {
      console.error('[ERROR] Failed to leave edit page:', e);
    }
  }

  /** @private */
  async function _save(
    ev: Event | undefined,
    isModal: boolean,
    showMinorSaveOption: boolean = true
  ): Promise<number | null> {
    const saveOption = await openWikiSavePopover(ev, _getSaveOptions(showMinorSaveOption));
    if (!saveOption) {
      console.warn('[WARN] No save option is selected');
      return null;
    }

    if (saveOption === WikiSaveModeEnum.Minor) {
      useWikiStore().setSaveMode(saveOption);
    }

    useWikiStore().setSaveLoading(true);

    try {
      const check = useWikiStore().checkFields();
      if (!check.ok) {
        showSonnerToast(check.reason, false, undefined, undefined, undefined, undefined, undefined, undefined, true);
        return null;
      }

      const editForm = useWikiStore().editForm;
      const advancedContent = {
        head: editForm.wikiContent.head,
        content: editForm.wikiContent.content,
        body: editForm.wikiContent.body,
        participants: {
          name: editForm.wikiContent.participants.name,
          participantsIds: editForm.participantsIds,
          settings: {
            allUsers: false,
            isVisible: true,
            isDeleted: false,
          },
        },
      };

      const isAdvancedWikiesEditor = useNetworkStore().isAdvancedWikiesEditor;
      const selectedGroup = useDocStore().selectedGroup;
      const selectedFolder = useDocStore().selectedFolder;
      const saveMode = useWikiStore().currentSaveMode;
      const payload: CreateWikiPayload = {
        name: editForm.name,
        groupId: selectedGroup?.id || editForm.groupId,
        folderId: false ? selectedFolder?.id || null : selectedFolder?.id || editForm.folderId,
        isMajor: saveMode !== WikiSaveModeEnum.Minor,
        text: !isAdvancedWikiesEditor ? editForm.wikiText : null,
        content: isAdvancedWikiesEditor ? advancedContent : null,
        participantsIds: editForm.participantsIds,
        accessOnlyForGroupId: null,
        mentionedUserIds: useWikiStore().editForm.mentionedUserIds ?? [],
      };

      const existingId = useWikiStore().existingWiki?.id;
      if (existingId) {
        return await _updateExistingWiki(
          {
            wikiId: existingId,
            ...payload,
          },
          isModal
        );
      }

      return await _onNewWikiCreate(payload, isModal);
    } catch (e) {
      console.error('[ERROR] Failed to save wiki', e);
      return null;
    } finally {
      useWikiStore().setSaveLoading(false);
    }
  }

  /** @private */
  async function _toCurrent(id: number): Promise<undefined> {
    useWikiStore().resetFromHistorical();

    await useWikiStore().wikiById(id);

    await _open(id);
  }

  /** @private */
  async function _toHistorical(id: number, versionId?: number, date?: string, routeName?: string): Promise<undefined> {
    if (!versionId && !date) {
      console.warn('[WARN] Failed to go to historical wiki: versionId and date are empty.');
      return;
    }

    try {
      let historical: WikiHistoricalModel | null = null;
      if (versionId) {
        historical = await useWikiStore().historyById(versionId);
      } else if (date) {
        historical = await useWikiStore().historyByDate(id, date, WikiMajorFilterEnum.All);
      }

      if (!historical) {
        console.warn('[WARN] Failed to get historical wiki: no historical wiki found');
        return;
      }

      await useWikiStore().wikiById(id);

      useWikiStore().setHistoricalWiki(historical);

      useWikiStore().setVersionId(historical.id);

      if (routeName !== ROUTES_NAME.WIKI_BY_ID) await _open(id, historical.id);
    } catch (e) {
      console.error('[ERROR] An error occurred while navigating to historical wiki:', e);
    }
  }

  /** @private */
  async function _saveAsTemplate(): Promise<undefined> {
    const templateData = _getPreparedWikiTemplateData(useWikiStore().editForm);
    if (!templateData) return;

    const data = await openWikiTemplateSaveModal(templateData);

    if (!data) showSonnerToast(t('wiki.templates.save.error'), false);

    showSonnerToast(t('wiki.templates.save.success'), true);
  }

  /** @private */
  async function _updateTemplate(): Promise<undefined> {
    let selectedTemplate: WikiTemplateModel | undefined = undefined;
    const templateData = _getPreparedWikiTemplateData(useWikiStore().editForm);
    if (!templateData) return;

    const updateTemplate = async () => {
      if (selectedTemplate && templateData) {
        const result = await useWikiStore().updateTemplateById({
          ...selectedTemplate,
          ...templateData,
        });
        if (result) {
          showSonnerToast(t('wiki.templates.update.success'), true);
        } else {
          showSonnerToast(t('wiki.templates.update.error'), false);
        }
      } else {
        console.warn('[WARN] Error while updating template');
      }
    };

    const alert = await alertController.create({
      header: t('wiki.templates.update.title'),
      message: t('wiki.templates.update.confirm'),
      buttons: [
        {
          text: t('cancel'),
          role: 'cancel',
          cssClass: 'custom-alert-buttons',
        },
        {
          text: t('confirm'),
          cssClass: 'custom-alert-buttons',
          handler: () => {
            updateTemplate();
          },
        },
      ],
    });

    const data = await openWikiTemplatesModal(t('wiki.templates.update.title'));
    selectedTemplate = data;
    selectedTemplate && (await alert.present());
  }

  /** @private */
  async function _chooseTemplate(): Promise<undefined> {
    let selectedTemplate: WikiTemplateModel | undefined;

    const alert = await alertController.create({
      header: t('wiki.templates.choose.title'),
      message: t('wiki.templates.choose.confirm'),
      buttons: [
        {
          text: t('cancel'),
          role: 'cancel',
          cssClass: 'custom-alert-buttons',
        },
        {
          text: t('confirm'),
          cssClass: 'custom-alert-buttons',
          handler: async () => {
            if (!selectedTemplate) {
              console.warn('[WARN] Selected template is not defined');
              return;
            }

            selectedTemplate = await useWikiStore().templateById(selectedTemplate.id);
            if (selectedTemplate?.wikiContent) {
              useWikiStore().setAdvancedWikiContent({
                ...selectedTemplate.wikiContent,
                participants: cloneDeep(defaultAdvancedWikiParticipantsModel),
              });
              showSonnerToast(t('wiki.templates.choose.success'), true);
            } else {
              console.warn('[WARN] Selected template content is not defined');
              showSonnerToast(t('wiki.templates.choose.error'), false);
            }
          },
        },
      ],
    });

    const data = await openWikiTemplatesModal(t('wiki.templates.choose.title'));
    selectedTemplate = data;
    selectedTemplate && (await alert.present());
  }

  /** @private */
  async function _handleTemplatesMenu(ev: Event | undefined): Promise<undefined> {
    const items = getTemplatesMenuItems();
    const result = items.length > 1 ? await openWikiTemplatesPopover(ev, items) : { data: items[0].value };
    if (!result) return;

    if (result === WikiEditControlsEnum.SaveAsTemplate) return await _saveAsTemplate();

    if (result === WikiEditControlsEnum.ChooseTemplate) return await _chooseTemplate();

    if (result === WikiEditControlsEnum.UpdateTemplate) return await _updateTemplate();
  }
  //#endregion

  //#region Handlers
  async function handleAction(p: WikiActionPayload): Promise<number | undefined> {
    const actionsMap: Record<WikiActionEnum | WikiDownloadOptionsEnum, () => Promise<number | undefined>> =
      Object.freeze({
        [WikiActionEnum.Create]: _create,
        [WikiActionEnum.Edit]: async () => _edit(p.model),
        [WikiActionEnum.Move]: async () => _move(p.id),
        [WikiActionEnum.MarkOfficial]: async () => _markOfficial(p.id),
        [WikiActionEnum.ShowRelations]: async () => _showRelations(p.id),
        [WikiActionEnum.ShowFollowers]: async () => _showFollowers(p.id),
        [WikiActionEnum.ShowHistory]: async () => _showHistory(p.id, p.fromComparePage),
        [WikiActionEnum.Rollback]: async () => _rollback(p.id, p.versionId),
        [WikiActionEnum.CompareHistorical]: async () => _compareHistorical(p.id, p.versionIds, p.fromComparePage),
        /** @note Since deletion of version is not implemented yet => passing it as `undefined` */
        [WikiActionEnum.Delete]: async () => _deleteWiki(p.id, undefined),
        [WikiActionEnum.Follow]: async () => _follow(p.id),
        [WikiActionEnum.Unfollow]: async () => _unfollow(p.id),
        [WikiActionEnum.ShareArchiveLink]: async () => openShareArchiveLinkModal(p.id, ShareArchiveLinkType.Wiki),
        [WikiActionEnum.Share]: async () => shareEntity(p.model as WikiModel, ShareEntityType.Wiki),
        [WikiActionEnum.ToCurrent]: async () => _toCurrent(p.id),
        [WikiActionEnum.ToHistorical]: async () => await _toHistorical(p.id, p.versionId, p.date, p.routeName),
        [WikiDownloadOptionsEnum.DownloadAsPDF]: async () => _download(p.model, DocumentExtensionEnum.PDF),
        [WikiDownloadOptionsEnum.DownloadAsDOCX]: async () => _download(p.model, DocumentExtensionEnum.DOCX),
      });

    const handler = actionsMap[p.type];
    if (!handler) {
      await router.push({
        name: ROUTES_NAME.WIKI_EDIT,
        params: { id: p.id },
      });
      console.warn('No handler found for action: ', p.type);
      return;
    }

    try {
      const result = await handler();
      if (p.type === WikiActionEnum.ShowHistory) return result;
    } catch (e) {
      console.error(`[ERROR] Error executing action ${p.type}:`, e);
      showSonnerToast(t('errorResponse'), false);
    }
  }

  async function handleOption(p: WikiOptionPayload): Promise<void> {
    const optionsMap: Record<WikiEditOptionsEnum, () => Promise<void> | void> = Object.freeze({
      [WikiEditOptionsEnum.None]: () => console.warn('[WARN] No handler for editing options'),
      [WikiEditOptionsEnum.EditTitle]: _editTitle,
      [WikiEditOptionsEnum.ShowAuthor]: _showAuthor /** @note Not implemented yet */,
      [WikiEditOptionsEnum.ShowLastEditor]: _showLastEditor /** @note Not implemented yet */,
      [WikiEditOptionsEnum.EditGroup]: _showGroup /** @note Not implemented yet */,
      [WikiEditOptionsEnum.EditFolder]: _showFolder /** @note Not implemented yet */,
      [WikiEditOptionsEnum.Outdated]: _showOutdated /** @note Not implemented yet */,
    });

    const handler = optionsMap[p.type];
    if (!handler) {
      console.warn('No handler found for edit option: ', p.type);
      return;
    }

    try {
      await handler();
    } catch (e) {
      console.error(`[ERROR] Error executing edit option ${p.type}:`, e);
      showSonnerToast(t('errorResponse'), false);
    }
  }

  async function handleControl(p: WikiControlPayload): Promise<void> {
    const controlsMap: Record<WikiEditControlsEnum, () => Promise<number | null | undefined>> = Object.freeze({
      [WikiEditControlsEnum.Cancel]: async () => await _leaveEditPage(undefined, p.isModal),
      [WikiEditControlsEnum.Save]: async () => await _save(p.ev, p.isModal, p.showMinorSaveOption),
      [WikiEditControlsEnum.Delete]: async () => _deleteWiki(p.id, undefined),
      [WikiEditControlsEnum.SaveAsTemplate]: _saveAsTemplate,
      [WikiEditControlsEnum.UpdateTemplate]: _updateTemplate,
      [WikiEditControlsEnum.ChooseTemplate]: _chooseTemplate,
      [WikiEditControlsEnum.TemplatesMenu]: async () => await _handleTemplatesMenu(p.ev),
    });

    const handler = controlsMap[p.type];
    if (!handler) {
      console.warn('No handler found for edit control:', p.type);
      return;
    }

    try {
      await handler();
    } catch (error) {
      console.error(`[ERROR] Error executing edit control ${p.type}:`, error);
      showSonnerToast(t('errorResponse'), false);
    }
  }
  //#endregion

  //#region Miscellaneous
  async function preventEdit(id: number): Promise<boolean> {
    const { handleError } = useErrors();

    /** @note Failed to lock wiki - not 200 */
    const lockStatus = await useWikiStore().lockEdit(id);
    if (!lockStatus) {
      handleError(true, undefined, t('errorResponse'));
      return true;
    }

    /** @note Failed to lock wiki - somebody is editing it */
    if (!lockStatus.success) {
      const name = { name: lockStatus.name ?? 'Someone' };
      const lockAlert = await alertController.create({
        header: t('wiki.preventAlert.lock.title'),
        message: t('wiki.preventAlert.lock.message', name),
        buttons: [
          {
            text: 'Ok',
            role: 'confirm',
            cssClass: 'custom-alert-buttons',
            handler: async () => {
              await alertController.dismiss();
            },
          },
        ],
      });

      await lockAlert.present();
      return true;
    }

    /** @note Check for isAdvanced on / off */
    let wiki: WikiModel | null = null;
    const existingWiki = useWikiStore().existingWiki;
    if (!existingWiki || existingWiki.id !== id) {
      wiki = await useWikiStore().wikiById(id);
    } else {
      wiki = existingWiki;
    }

    const isAdvancedWikiesEditor = useNetworkStore().isAdvancedWikiesEditor;
    const chosenWikiIsAdvanced = wiki?.version === WikiVersionEnum.V2;
    if (chosenWikiIsAdvanced && !isAdvancedWikiesEditor) {
      const advancedIsOffAlert = await alertController.create({
        header: t('wiki.preventAlert.advanced.title'),
        message: t('wiki.preventAlert.advanced.message'),
        buttons: [
          {
            text: 'Ok',
            role: 'confirm',
            cssClass: 'custom-alert-buttons',
            handler: async () => {
              await alertController.dismiss();
            },
          },
        ],
      });

      await advancedIsOffAlert.present();
      return true;
    }

    return false;
  }

  async function openActionsMenu(ev: Event, wiki: WikiModel): Promise<void> {
    const payload = { documentType: DocumentTypeEnum.Wiki, data: wiki };

    const action = isNativeMobile
      ? await openDocBrowserContextSheet(payload)
      : await openDocsActionsPopover(ev, payload);

    if (!action) {
      console.warn('No action for wiki', wiki.id);
      return;
    }

    await handleAction({
      type: action as WikiActionEnum,
      id: wiki.id,
      model: wiki,
      ev,
    });
  }
  //#endregion

  return {
    //#region Getters
    getActionsMenuItems,
    getTemplatesMenuItems,
    getEditOptions,
    getEditControls,
    getTemplatesTableHeader,
    getCreateAccess,
    getHistoricalButtons,
    getHistoricalModifiedByAvatar,
    //#endregion

    //#region Handlers
    handleAction,
    handleOption,
    handleControl,
    //#endregion

    //#region Miscellaneous
    openActionsMenu,
    preventEdit,
    //#endregion
  };
};
