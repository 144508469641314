import type { Component } from 'vue';
import { createApp, h, markRaw } from 'vue';

import DocIcon from '../icons/docx.vue';
import PptIcon from '../icons/pptx.vue';
import XlsxIcon from '../icons/xlsx.vue';
import type { AppIconsEnum } from '@/enums';
import { IconPropsModel } from '@/types';

type IconComponent = {
  default: Component;
};

type IUseIcons = {
  /**
   * Use it to get an icon Component by its name
   */
  getIcon: (iconName: AppIconsEnum) => Promise<Component | null>;
  /**
   * Use it to get an array of all available cached icons
   */
  getIconFileNames: () => AppIconsEnum[];
  /**
   * Use it to generate, cache and get (or retrieve from cache if already generated)
   * the SVG string of an icon by its name and props (uses getIcon internally)
   *
   * @see `getIcon` for more details
   */
  getSvgFromIcon: (iconName: AppIconsEnum, props: IconPropsModel) => Promise<string>;
  /**
   * Use it to generate, cache and get (or retrieve from cache if already generated)
   * the Blob URL of an icon's SVG (uses getSvgFromIcon internally)
   *
   * @see `getSvgFromIcon` for more details
   */
  getIconBlobUrl: (iconName: AppIconsEnum, props: IconPropsModel) => Promise<string>;

  /**
   * Use it to clear all cached icons and blob URLs to free memory
   */
  clearCache: () => void;
};

let instance: IUseIcons | null = null;

export function useIcons(): IUseIcons {
  if (instance) return instance;

  //#region Private variables
  const _docExtensions = new Set([
    'docx',
    'doc',
    'docm',
    'vnd.openxmlformats-officedocument.wordprocessingml.document',
  ]);
  const _excelExtensions = new Set(['xlsx', 'xls', 'xlsm', 'vnd.openxmlformats-officedocument.spreadsheetml.sheet']);
  const _pptExtensions = new Set([
    'pptx',
    'ppt',
    'pptm',
    'vnd.openxmlformats-officedocument.presentationml.presentation',
  ]);

  /**
   * Map of icon paths to their async import functions
   *
   * @example { 'icons/doc.vue': () => import('./icons/doc.vue') }
   */
  const _iconPathToFn = new Map<string, () => Promise<IconComponent>>();

  /**
   * Map of icon names to their respective paths (lowercase)
   *
   * @example { 'doc': 'icons/doc.vue' }
   */
  const _iconNameToPath = new Map<string, string>();

  /**
   * Loop through the imported icons and add them to the _icons and _iconMap maps
   */
  for (const [path, importFunction] of Object.entries(import.meta.glob('../icons/**/*.vue'))) {
    _iconPathToFn.set(path, importFunction as () => Promise<IconComponent>);
    const fileName = path.split('/').pop()?.replace('.vue', '').toLowerCase() || path;
    _iconNameToPath.set(fileName, path);
  }

  /**
   * Cache for loaded icon components
   *
   * @example { 'home': <HomeIcon /> }
   */
  const _iconsCache = new Map<string, Component>();

  /**
   * Cache for Blob URLs of rendered SVG icons
   *
   * @example { 'home|width=20;height=20;fill=#c5c8c6': 'blob:url...' }
   */
  const _blobUrlCache = new Map<string, string>();

  /**
   * Pre-import office icons using static imports for performance
   *
   * @is { doc: DocIcon, xlsx: XlsxIcon, ppt: PptIcon }
   */
  const _officeIcons = {
    doc: markRaw(DocIcon),
    xlsx: markRaw(XlsxIcon),
    ppt: markRaw(PptIcon),
  };
  //#endregion

  //#region Private methods
  const _isDoc = (ext: string): boolean => _docExtensions.has(ext.toLowerCase());
  const _isExcel = (ext: string): boolean => _excelExtensions.has(ext.toLowerCase());
  const _isPpt = (ext: string): boolean => _pptExtensions.has(ext.toLowerCase());
  const _isOffice = (ext: string): boolean => _isDoc(ext) || _isExcel(ext) || _isPpt(ext);

  /**
   * Convert SVG string to Blob URL
   *
   * @param svgString - The SVG markup as a string
   * @returns The Blob URL representing the SVG
   */
  const _svgToBlobUrl = (svgString: string): string => {
    const blob = new Blob([svgString], { type: 'image/svg+xml' });
    return URL.createObjectURL(blob);
  };
  //#endregion

  //#region Public methods
  const getIcon = async (iconName: AppIconsEnum): Promise<Component | null> => {
    if (!iconName) {
      console.warn('[WARN] No icon name provided.');
      return null;
    }

    const name = iconName.toLowerCase();

    if (_iconsCache.has(name)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return _iconsCache.get(name)!;
    }

    let selectedIcon: Component | null = null;

    if (_isOffice(name)) {
      if (_isDoc(name)) selectedIcon = _officeIcons.doc;
      else if (_isExcel(name)) selectedIcon = _officeIcons.xlsx;
      else if (_isPpt(name)) selectedIcon = _officeIcons.ppt;

      if (selectedIcon) _iconsCache.set(name, selectedIcon);

      return selectedIcon;
    }

    const iconPath = _iconNameToPath.get(name);
    if (iconPath) {
      try {
        const iconFn = _iconPathToFn.get(iconPath);
        if (iconFn) {
          const iconModule = await iconFn();
          selectedIcon = markRaw(iconModule.default);
          _iconsCache.set(name, selectedIcon);
        } else {
          console.warn(`[WARN] No import function found for icon at path: ${iconPath}`);
        }
      } catch (e) {
        console.error('[ERROR] Error loading icon:', e);
      }
    } else {
      console.warn(`[WARN] Icon file not found for the given name: ${iconName}`);
    }

    return selectedIcon;
  };

  const getIconFileNames = (): AppIconsEnum[] => {
    return Array.from(_iconNameToPath.keys()) as AppIconsEnum[];
  };

  const getSvgFromIcon = async (iconName: AppIconsEnum, props: IconPropsModel): Promise<string> => {
    const iconComponent = await getIcon(iconName);
    if (!iconComponent) {
      console.warn(`[WARN] Icon "${iconName}" not found.`);
      return '';
    }

    return new Promise<string>((resolve, reject) => {
      try {
        const container = document.createElement('div');
        const app = createApp({
          render() {
            return h(iconComponent, props);
          },
        });

        app.mount(container);
        const svg = container.innerHTML;
        app.unmount();
        resolve(svg);
      } catch (e) {
        console.error('[ERROR] Error rendering SVG:', e);
        reject(e);
      }
    });
  };

  const getIconBlobUrl = async (iconName: AppIconsEnum, props: IconPropsModel): Promise<string> => {
    const svgString = await getSvgFromIcon(iconName, props);
    if (!svgString) {
      console.warn(`[WARN] SVG string for icon "${iconName}" is empty.`);
      return '';
    }

    const propsKey = Object.entries(props)
      .sort()
      .map(([key, value]) => `${key}=${value}`)
      .join(';');
    const cacheKey = `${iconName}|${propsKey}`;

    if (_blobUrlCache.has(cacheKey)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return _blobUrlCache.get(cacheKey)!;
    }

    const blobUrl = _svgToBlobUrl(svgString);
    _blobUrlCache.set(cacheKey, blobUrl);

    return blobUrl;
  };

  /**
   * Clears all cached icons and blob URLs, freeing memory.
   */
  const clearCache = (): void => {
    _iconsCache.clear();
    _blobUrlCache.clear();
  };
  //#endregion

  instance = {
    getIcon,
    getIconFileNames,
    getSvgFromIcon,
    getIconBlobUrl,
    clearCache,
  };

  return instance;
}

/**
 * Resets the singleton instance
 */
export const resetInstance = (): void => {
  instance = null;
};
