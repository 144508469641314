<template>
  <ion-button mode="md" size="default" fill="clear" class="menu" @click.stop="openPostMenu($event, post)">
    <icons-provider
      v-if="feedFlag !== FeedFlagEnum.SharedPost"
      slot="icon-only"
      :icon-props="{ width: '22', height: '22' }"
      :name="AppIconsEnum.MenuDots"
    />
  </ion-button>
</template>

<script lang="ts" setup>
import { IonButton } from '@ionic/vue';
import type { PropType } from 'vue';
import 'swiper/css';
import { useRouter } from 'vue-router';

import { IconsProvider } from '@/components';
import {
  FeedFlagEnum,
  FeedTypeEnum,
  PostMenuActionEnum,
  FeedFilterTypeEnum,
  ShareArchiveLinkType,
  DocumentExtensionEnum,
  FileStatusEnum,
  PostMenuOptionsFlagEnum,
  AppIconsEnum,
  ShareEntityType,
} from '@/enums';
import {
  openAppDatePickerPopover,
  DateHelper,
  openPostContextMenuModal,
  openPostContextSheet,
  openPostEditModal,
  openPostSendToExternalEmailModal,
  openPostComplainModal,
  htmlToText,
  isAnyMobile,
  openShareArchiveLinkModal,
  useFilesHybrid,
  useToasts,
  copyToClipboard,
  shareEntity,
} from '@/helpers';
import { isBlob } from '@/helpers/guards';
import { useI18n } from '@/i18n';
import { ROUTES_NAME } from '@/router';
import { usePostStore, useLinksStore } from '@/store';
import type { PostModel } from '@/types';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

//#region Props
const props = defineProps({
  post: {
    type: Object as PropType<PostModel>,
    required: true,
  },
  feedFlag: {
    type: String as PropType<FeedFlagEnum>,
    required: true,
  },
  feedType: {
    type: String as PropType<FeedFilterTypeEnum | null>,
    default: FeedFilterTypeEnum.Recommended,
  },
});
//#endregion

//#region Variables
const { t } = useI18n();
const { showSonnerToast } = useToasts();

const postStore = usePostStore();
const linksStore = useLinksStore();

const router = useRouter();
//#endregion

//#region Methods
const switchActions = async (ev: Event, result: PostMenuActionEnum | undefined, postData: PostModel) => {
  switch (result) {
    case PostMenuActionEnum.Share:
      {
        await shareEntity(postData, ShareEntityType.Post, PostMenuActionEnum.Share);
      }
      break;

    case PostMenuActionEnum.Repost:
      {
        await shareEntity(postData, ShareEntityType.Post, PostMenuActionEnum.Repost);
      }
      break;

    case PostMenuActionEnum.Send:
      {
        const innerResult = await openPostContextSheet(
          postData,
          FeedFlagEnum.ConversationPage,
          FeedTypeEnum.Announcement,
          PostMenuOptionsFlagEnum.Share
        );
        await switchActions(ev, innerResult, postData);
      }
      break;

    case PostMenuActionEnum.Download:
      {
        const innerResult = await openPostContextSheet(
          postData,
          FeedFlagEnum.ConversationPage,
          FeedTypeEnum.Announcement,
          PostMenuOptionsFlagEnum.Download
        );
        await switchActions(ev, innerResult, postData);
      }
      break;
    case PostMenuActionEnum.Open:
      await openPost(postData.id);
      break;
    case PostMenuActionEnum.Delete:
      if (
        !postData.plannedPostData
          ? await postStore.postDelete(postData.id)
          : await postStore.switchPlannedDelete(postData)
      ) {
        if (props.feedFlag === FeedFlagEnum.ConversationPage) {
          await router.push({
            name: ROUTES_NAME.FEED,
          });
        }
      } else {
        showSonnerToast(t('feed.conversationPostMenu.delete.postNotDeleted'), false);
      }
      break;

    case PostMenuActionEnum.AddToBookmarks:
      await addToBookmarks(postData.id);
      break;

    case PostMenuActionEnum.RemoveFromBookmarks:
      await removeFromBookmarks(postData.id);
      break;

    case PostMenuActionEnum.Pin:
      if (await postStore.pinPost(postData.id)) {
        showSonnerToast(t('feed.conversationPostMenu.attach.postPinned'), true);
      } else {
        showSonnerToast(t('feed.conversationPostMenu.attach.postNotPinned'), false);
      }
      break;

    case PostMenuActionEnum.UnPin:
      if (await postStore.unPinPost(postData.id)) {
        showSonnerToast(t('feed.conversationPostMenu.attach.postUnpinned'), true);
      } else {
        showSonnerToast(t('feed.conversationPostMenu.attach.postNotUnpinned'), false);
      }
      break;

    case PostMenuActionEnum.Follow:
      if (await postStore.followToPost(postData.id)) {
        showSonnerToast(t('feed.conversationPostMenu.following.followed'), true);
      } else {
        showSonnerToast(t('feed.conversationPostMenu.following.notFollowed'), false);
      }
      break;

    case PostMenuActionEnum.UnFollow:
      if (await postStore.unFollowFromPost(postData.id)) {
        showSonnerToast(t('feed.conversationPostMenu.following.unfollowed'), true);
      } else {
        showSonnerToast(t('feed.conversationPostMenu.following.notUnfollowed'), false);
      }
      break;

    case PostMenuActionEnum.Edit:
      {
        const result = await openPostEditModal(postData);
        if (!result) {
          linksStore.$patch({
            currentInternalPostLinks: [],
            currentInternalPostLinksPreviewData: [],
            currentLinksPreviewData: [],
          });
        }
      }
      break;

    case PostMenuActionEnum.SendToEmail:
      if (await postStore.sendPostToEmail(postData.id)) {
        showSonnerToast(t('feed.conversationPostMenu.send.mailSend'), true);
      } else {
        showSonnerToast(t('feed.conversationPostMenu.send.mailNotSend'), false);
      }
      break;

    case PostMenuActionEnum.SendToExternalEmail:
      await openPostSendToExternalEmailModal(postData.id);
      break;

    case PostMenuActionEnum.ShareArchiveLink:
      {
        await openShareArchiveLinkModal(postData.id, ShareArchiveLinkType.UserItem);
      }
      break;

    case PostMenuActionEnum.DownloadAsPDF:
      {
        const response = await postStore.postDownload(postData.messageType, DocumentExtensionEnum.PDF, postData.id);
        console.log('Response:', response); //! DEBUG

        if (isBlob(response)) {
          const status = await useFilesHybrid().downloadPost(postData, response as Blob);
          showSonnerToast(t('files.successDownloaded'), status === FileStatusEnum.Success);
        } else {
          showSonnerToast(t('files.failedDownloaded'), false);
          console.warn(`[WARN] Response is not a Blob: ${JSON.stringify(response)}`);
        }
      }
      break;

    case PostMenuActionEnum.DownloadAsZIP:
      {
        const response = await postStore.postDownload(postData.messageType, DocumentExtensionEnum.ZIP, postData.id);
        console.log('Response:', response); //! DEBUG

        if (isBlob(response)) {
          const status = await useFilesHybrid().downloadPost(postData, response as Blob);
          showSonnerToast(t('files.successDownloaded'), status === FileStatusEnum.Success);
        } else {
          showSonnerToast(t('files.failedDownloaded'), false);
          console.warn(`[WARN] Response is not a Blob: ${JSON.stringify(response)}`);
        }
      }
      break;

    case PostMenuActionEnum.Complain:
      await openPostComplainModal(postData.id);
      break;

    case PostMenuActionEnum.CopyText: {
      let text = '';
      if (props.post.messageType === FeedTypeEnum.Idea && props.post.ideaData) {
        text = htmlToText(
          props.post.ideaData.title +
            ` ${t('feed.idea.problem')}: ` +
            props.post.ideaData.problem +
            ` ${t('feed.idea.solution')}: ` +
            props.post.ideaData.solution
        );
      } else {
        text = htmlToText(props.post.bodyHtml);
      }

      await copyToClipboard(text);

      showSonnerToast(t('appPopoverMenu.copy.textCopied'), true);
      break;
    }

    case PostMenuActionEnum.SendNow:
      await postStore.switchPlannedForce(postData);
      break;
    case PostMenuActionEnum.ReSchedule:
      {
        const result = await openAppDatePickerPopover(
          ev,
          DateHelper.addMinutesToDateInISO(new Date().toISOString(), 15),
          true,
          true,
          5
        );
        if (postData.plannedPostData && result && !DateHelper.isDateInPast(result)) {
          postData.plannedPostData.plannedDate = result;
          await postStore.switchPlannedUpdate(postData);
        } else if (result && DateHelper.isDateInPast(result)) {
          showSonnerToast(t('validations.pastDate'), false);
        }
      }
      break;
  }
};

const openPostMenu = async (ev: Event, postData: PostModel) => {
  if (props.feedFlag === FeedFlagEnum.SharedPost) {
    return await openPost(postData.id);
  }

  const isAnnouncement = props.feedType === FeedFilterTypeEnum.Announcement ? FeedFilterTypeEnum.Announcement : '';
  let data;
  if (isAnyMobile) {
    data = await openPostContextSheet(postData, FeedFlagEnum.ConversationPage, isAnnouncement);
  } else {
    data = await openPostContextMenuModal(postData, props.feedFlag, isAnnouncement);
  }

  await switchActions(ev, data, postData);
};

const addToBookmarks = async (id: number) => {
  if (await postStore.addBookmark(id)) {
    showSonnerToast(t('feed.conversationPostMenu.bookmark.bookmarkAdded'), true);
  } else {
    showSonnerToast(t('feed.conversationPostMenu.bookmark.bookmarkNotAdd'), false);
  }
};

const removeFromBookmarks = async (id: number) => {
  if (await postStore.removeBookmark(id)) {
    showSonnerToast(t('feed.conversationPostMenu.bookmark.bookmarkRemoved'), true);
  } else {
    showSonnerToast(t('feed.conversationPostMenu.bookmark.bookmarkNotRemoved'), false);
  }
};

const openPost = async (id: number) => {
  await router.push({
    name: ROUTES_NAME.POST_BY_ID,
    params: { id: id },
  });
};
//#endregion
</script>

<style scoped lang="scss">
ion-button {
  &.menu {
    @include resetStyleFromIonicButton;
    --color: var(--ion-color-medium);
    --background-hover: transparent;
    --background-activated: transparent;
    --background-focused: transparent;
    --ripple-color: transparent;
    --padding-end: 0;
    --padding-start: 0;
    margin: 0;
    z-index: 1;

    &:hover {
      ion-icon {
        opacity: 0.7;
      }
    }
  }
}
</style>
