<template>
  <component :is="selectedIcon" v-if="selectedIcon !== null" v-bind="computedIconProps" />
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import type { Component, PropType } from 'vue';

import { AppIconsEnum } from '@/enums';
import { useIcons } from '@/helpers';
import type { IconPropsModel } from '@/types';

//#region Props
const props = defineProps({
  name: {
    type: String as PropType<AppIconsEnum | string>,
    required: true,
  },
  iconProps: {
    type: Object as PropType<IconPropsModel>,
    default: () => ({}),
  },
});
//#endregion

//#region Variables
const selectedIcon = ref<Component | null>(null);

const computedIconProps = computed(() => {
  return {
    ...props.iconProps,
  };
});
//#endregion

//#region Watchers
watch(
  () => props.name,
  async (newName) => {
    if (typeof newName === 'string') {
      const iconName = newName.toLowerCase() as AppIconsEnum;
      selectedIcon.value = await useIcons().getIcon(iconName);
    } else {
      console.warn('name prop is not a string:', newName);
      selectedIcon.value = null;
    }
  },
  { immediate: true }
);
//#endregion
</script>
