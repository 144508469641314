import type { OverlayEventDetail } from '@ionic/core';
import { alertController } from '@ionic/core';
import { lockClosed, star, shieldCheckmark, globeOutline } from 'ionicons/icons';

import {
  DataViewMode,
  AllowExternalUsersToGroupEnum,
  AppCardsActionEnum,
  GroupInviteModeEnum,
  GroupsAccessEnum,
  GroupsTypeEnum,
  UserRoleEnum,
  EnvVariablesEnum,
  SortingTypeEnum,
  AppIconsEnum,
} from '@/enums';
import { useErrors, useToasts, useEnvs } from '@/helpers';
import { useI18n } from '@/i18n';
import { useAppStore, useGroupsStore, useNetworkStore, useUserStore } from '@/store';
import type { AppActionButton, CompaniesListType2, GroupModel } from '@/types';
type IUseGroups = {
  /**
   * Use it to get the invite modes to a group.
   * Depends on the user's role.
   */
  getInviteModesToGroup(groupData: GroupModel): { name: string; value: GroupInviteModeEnum }[];
  /**
   * Use it to get the name of the invite mode.
   */
  getInviteModeName(mode: GroupInviteModeEnum): string;
  getMainGroupAction(
    groupData: GroupModel,
    mode: DataViewMode
  ): {
    left: AppActionButton;
    right: AppActionButton;
  };
  getGroupStats(groupData: GroupModel): string;
  getGroupInfoAccess(groupId: number): boolean;
  getGroupType(groupId: number): string;
  getGroupIcon(groupId: number): string | null;
  getGroupIconByData(isMandant: boolean, isOfficial: boolean, type: GroupsTypeEnum): string;
  /**
   * Use it to check get the group id to be permanently pinned for the current company.
   *
   * @returns groupId to be permanently pinned
   *
   * @see src/store/menu.pinia.ts - permanentlyPinGroup
   * @see src/store/menu.pinia.ts - getGroupsPermanentlyPinned
   * @see src/components/Common/AppLeftMenu.vue - groupsPermanentlyPinned
   * @link https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1836
   */
  getGroupIdForPermanentPin(): number | null;

  onJoinGroup(id: number): Promise<void>;
  onLeaveGroup(id: number): Promise<void>;
  onSendRequest(id: number): Promise<void>;

  isUserJoined(groupData: GroupModel): boolean;
  isGroupAdmin(groupId: number): boolean;
  getSortTitle(sort: SortingTypeEnum): string;
};

export const useGroups = (): IUseGroups => {
  //#region Helpers
  const { showSonnerToast } = useToasts();
  const { getEnvValue } = useEnvs();
  //#endregion

  //#region Icons
  const icons = {
    lock: lockClosed,
    star: star,
    shield: shieldCheckmark,
    globe: globeOutline,
  };
  //#endregion

  //#region Private methods
  /** @private */
  const _leaveConfirm = async (groupData: GroupModel, lastUser: boolean): Promise<boolean> => {
    const userStore = useUserStore();
    const { t } = useI18n();
    const currentUserRoleId: number = userStore.current?.roleId ?? 0;

    const alert = await alertController.create({
      message:
        lastUser && currentUserRoleId >= 30
          ? t('groupPage.leaveConfirmIsLast')
          : t('groupPage.leaveConfirm', { group: groupData.title }),
      buttons: [
        {
          text: t('cancel'),
          role: 'cancel',
          cssClass: 'custom-alert-buttons',
        },
        {
          text: t('confirm'),
          cssClass: 'custom-alert-buttons',
          role: 'confirm',
        },
      ],
    });
    await alert.present();

    return alert.onDidDismiss().then(async (result: OverlayEventDetail<boolean>) => {
      return result.role === 'confirm';
    });
  };
  //#endregion

  //#region Getters
  const getInviteModesToGroup = (groupData: GroupModel): { name: string; value: GroupInviteModeEnum }[] => {
    const userStore = useUserStore();
    const networkStore = useNetworkStore();

    const allowExternalUsersToGroup: AllowExternalUsersToGroupEnum =
      networkStore.settings?.allowExternalUsersToGroup ?? AllowExternalUsersToGroupEnum.Off;

    const currentUserId: number = userStore.current?.id ?? 0;

    const currentUserRoleId: number = userStore.current?.roleId ?? 0;

    const currentUserIsAdmin: boolean = userStore.current ? currentUserRoleId >= UserRoleEnum.Administrator : false;

    const currentUserIsGroupAdmin: boolean = groupData.adminIds.includes(currentUserId);

    const modes = [];

    // Можно добавить в группу если юзер админ группы
    const caseOne = currentUserIsGroupAdmin;

    // Можно пригласить внешних в группу - если разрешено в настройках сети приглашать внешних пользователей от лица с ролью админа сети и одновременно админа группы
    const caseTwo =
      allowExternalUsersToGroup === AllowExternalUsersToGroupEnum.NetworkAdmins &&
      currentUserIsGroupAdmin &&
      currentUserIsAdmin;

    // Можно пригласить внешних в группу - если разрешено в настройках сети приглашать внешних пользователей от лица с ролью админа группы
    const caseThree =
      allowExternalUsersToGroup === AllowExternalUsersToGroupEnum.GroupAdmins && currentUserIsGroupAdmin;

    // Можно пригласить внешних в группу - если разрешено в настройках сети приглашать внешних пользователей от лица с ролью от 30
    const caseFour =
      allowExternalUsersToGroup === AllowExternalUsersToGroupEnum.All && currentUserRoleId >= UserRoleEnum.User;

    if (caseOne) {
      modes.push(
        {
          name: getInviteModeName(GroupInviteModeEnum.AddById),
          value: GroupInviteModeEnum.AddById,
        },
        {
          name: getInviteModeName(GroupInviteModeEnum.AddByEmail),
          value: GroupInviteModeEnum.AddByEmail,
        }
      );
    }

    if (caseTwo || caseThree || caseFour) {
      modes.push({
        name: getInviteModeName(GroupInviteModeEnum.AddExternal),
        value: GroupInviteModeEnum.AddExternal,
      });
    }

    return modes;
  };

  const getInviteModeName = (mode: GroupInviteModeEnum): string => {
    const { t } = useI18n();
    switch (mode) {
      case GroupInviteModeEnum.AddById:
        return t('invites.byNetwork');
      case GroupInviteModeEnum.AddByEmail:
        return t('invites.byEmail');
      case GroupInviteModeEnum.AddExternal:
        return t('invites.external');
    }
    return '';
  };

  const getMainGroupAction = (
    groupData: GroupModel,
    mode: DataViewMode
  ): { left: AppActionButton; right: AppActionButton } => {
    const { t } = useI18n();
    const userIsSendRequest = (): boolean => groupData.accessType === GroupsAccessEnum.SendRequest;
    const isJoined = isUserJoined(groupData);

    const defaultAction: AppActionButton = {
      title: '',
      type: 'main',
      value: AppCardsActionEnum.None,
      icon: AppIconsEnum.None,
      showIcon: false,
      showTooltip: false,
      showTitle: false,
    };

    const actionData: { left: AppActionButton; right: AppActionButton } = {
      left: { ...defaultAction },
      right: { ...defaultAction, showTitle: true },
    };

    if (groupData.type !== GroupsTypeEnum.Public && !isJoined) {
      if (userIsSendRequest()) {
        actionData.right = {
          ...defaultAction,
          title: t('groupPage.requestIsSend'),
          type: 'secondary',
          value: AppCardsActionEnum.OpenRequested,
          icon: AppIconsEnum.UserTime,
          showTitle: true,
        };
      } else {
        actionData.right = {
          ...defaultAction,
          title: t('groupPage.sendRequest'),
          value: AppCardsActionEnum.GroupSendRequest,
          icon: AppIconsEnum.UserCheck,
          showTitle: true,
        };
      }
    } else if (isJoined) {
      actionData.left = {
        ...defaultAction,
        title: t('groupPage.leave'),
        type: 'secondary',
        value: AppCardsActionEnum.GroupLeave,
        icon: AppIconsEnum.UserDeleteCross,
        showTitle: mode === DataViewMode.Grid,
      };
      actionData.right = {
        ...defaultAction,
        title: t('open'),
        value: AppCardsActionEnum.Open,
        showTitle: mode === DataViewMode.Grid,
      };
    } else {
      actionData.right = {
        ...defaultAction,
        title: t('groupPage.join'),
        value: AppCardsActionEnum.GroupJoin,
        icon: AppIconsEnum.UserCheck,
        showTitle: true,
      };
    }

    return actionData;
  };

  const getGroupStats = (groupData: GroupModel): string => {
    const { t } = useI18n();
    const title = [];
    if (groupData.stats.members !== 0) {
      title.push(t('appLists.members', groupData.stats.members));
    }
    if (groupData.stats.messages !== 0) {
      title.push(t('appLists.posts', groupData.stats.messages));
    }
    return title.join(' &#8729; ');
  };

  const getGroupInfoAccess = (groupId: number) => {
    const groupStore = useGroupsStore();
    const group = groupStore.getGroupById(groupId);
    return group.showInformation;
  };

  const getGroupType = (groupId: number): string => {
    const { t } = useI18n();
    const groupStore = useGroupsStore();
    const group = groupStore.getGroupById(groupId);

    const groupTypes = [
      group.type === GroupsTypeEnum.PrivateVisible && t('groupPage.privateVisible'),
      group.type === GroupsTypeEnum.PrivateHidden && t('groupPage.privateHidden'),
      group.isMandant && t('groupPage.mandant'),
      group.isOfficial && t('groupPage.official'),
    ].filter(Boolean);

    return groupTypes.join(', ');
  };

  const getGroupIcon = (groupId: number): string | null => {
    const groupStore = useGroupsStore();
    const group = groupStore.getGroupById(groupId);
    if (group.isMandant) return icons.globe;
    if (group.isOfficial) return icons.star;
    if (group.type === GroupsTypeEnum.PrivateHidden) return icons.shield;
    if (group.type === GroupsTypeEnum.PrivateVisible) return icons.lock;
    return null;
  };

  const getGroupIconByData = (isMandant: boolean, isOfficial: boolean, type: GroupsTypeEnum): string => {
    if (isMandant) return icons.globe;
    if (isOfficial) return icons.star;
    if (type === GroupsTypeEnum.PrivateHidden) return icons.shield;
    if (type === GroupsTypeEnum.PrivateVisible) return icons.lock;
    return '';
  };

  /**
   * For testing purposes, I've added dev group id - `1833` to the list of groups for permanent pinning
   * Target group id - `8195` is only available in production
   *
   * @link https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1836
   */
  const getGroupIdForPermanentPin = (): number | null => {
    const currentCompanyId = useAppStore().companyRowId;
    const companiesList: CompaniesListType2 = getEnvValue(EnvVariablesEnum.CompaniesListWithPermanentlyPinnedGroups);
    const arrayFromCompaniesList = Object.values(companiesList);

    if (!arrayFromCompaniesList.length) {
      console.log('[INFO] There is no company with a permanent pinning list'); //! DEBUG
      return null;
    }

    const groupToFind = arrayFromCompaniesList.find((c) => c.id === currentCompanyId)?.groupId;
    if (!groupToFind) {
      console.log('[INFO] The current company is not in the list of companies with a permanent pinning list'); //! DEBUG
      return null;
    }

    return groupToFind;
  };

  const getSortTitle = (sort: SortingTypeEnum) => {
    const { t } = useI18n();
    switch (sort) {
      case SortingTypeEnum.Name:
        return t('files.table.title');
      case SortingTypeEnum.Type:
        return t('files.table.type');
      case SortingTypeEnum.UnreadMessagesCount:
        return t('files.table.unreadMessagesCount');
      case SortingTypeEnum.StatsMembers:
        return t('files.table.statsMembers');
      case SortingTypeEnum.StatsMessages:
        return t('files.table.statsMessages');
      default:
        return t('files.table.title');
    }
  };
  //#endregion

  //#region On-hooks
  const onJoinGroup = async (id: number): Promise<void> => {
    const { t } = useI18n();
    const { handleError } = useErrors();

    const groupStore = useGroupsStore();

    if (await groupStore.onJoin(id)) {
      showSonnerToast(t('groupPage.youAreJoined'), true);
    } else {
      handleError(true, undefined, t('groupPage.notJoined'));
    }
  };

  const onLeaveGroup = async (id: number): Promise<void> => {
    const { t } = useI18n();
    const { handleError } = useErrors();

    const groupStore = useGroupsStore();
    const group = groupStore.getGroupById(id);

    const result = group.stats.members <= 1 ? await _leaveConfirm(group, true) : await _leaveConfirm(group, false);
    if (result) {
      if (await groupStore.onLeave(group.id, group.stats.members <= 1)) {
        showSonnerToast(t('groupPage.youAreLeft', { group: group.title }), true);
      } else {
        handleError(true, undefined, t('groupPage.notLeft', { group: group.title }));
      }
    }
  };

  const onSendRequest = async (id: number): Promise<void> => {
    const { t } = useI18n();
    const { handleError } = useErrors();

    const groupStore = useGroupsStore();

    if (await groupStore.onJoin(id)) {
      showSonnerToast(t('groupPage.requestIsSend'), true);
    } else {
      handleError(true, undefined, t('groupPage.requestNotSend'));
    }
  };
  //#endregion

  //#region Is-hooks
  const isUserJoined = (groupData: GroupModel): boolean => {
    return groupData.accessType >= GroupsAccessEnum.Member;
  };

  const isGroupAdmin = (groupId: number) => {
    const userStore = useUserStore();
    const groupStore = useGroupsStore();
    const currentUserRoleId = userStore.current?.roleId ?? 0;
    const currentUserId = userStore.getId ?? null;

    const groupData = groupStore.getGroupById(Number(groupId));

    return (
      (groupData.accessType === GroupsAccessEnum.Admin && groupData.adminIds.includes(currentUserId)) ||
      currentUserRoleId >= UserRoleEnum.SuperAdministrator
    );
  };
  //#endregion

  return {
    getInviteModesToGroup,
    getInviteModeName,
    getMainGroupAction,
    getGroupStats,
    getGroupInfoAccess,
    getGroupType,
    getGroupIcon,
    getGroupIconByData,

    onJoinGroup,
    onLeaveGroup,
    onSendRequest,

    isUserJoined,
    isGroupAdmin,

    getGroupIdForPermanentPin,
    getSortTitle,
  };
};
