import { IonicVue } from '@ionic/vue';
import { createHead } from '@unhead/vue';
import FloatingVue from 'floating-vue';
import { createApp } from 'vue';
import VueObserveVisibility from 'vue-observe-visibility';

import App from './App.vue';
import { initSentry } from './plugins/sentry';
import router from './router';

import { useErrors } from '@/helpers/useErrorsHelper';
import { useIcons } from '@/helpers/useIconsHelper';
import { IndexedDBStoresEnum } from './enums';
import { useIDB } from './helpers/useIDBHelper';
import { initI18n } from '@/i18n';
import { pinia } from '@/store';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Fonts */
import './theme/fonts.css';

/* Cropper CSS */
import 'vue-advanced-cropper/dist/style.css';

/* Vue-Cal CSS */
import 'vue-cal/dist/vuecal.css';

/* Vue-DatePicker CSS */
import '@vuepic/vue-datepicker/dist/main.css';

/* Custom CSS */
import './theme/custom.css';

/* Vue-Floating */
import 'floating-vue/dist/style.css';

/* Animate.css */
import '../node_modules/animate.css/animate.min.css';

/* Table */
import './theme/table.css';

/* Color Picker CSS */
import 'vue3-colorpicker/style.css';

const app = createApp(App)
  .use(VueObserveVisibility)
  .use(initI18n())
  .use(FloatingVue, {
    themes: {
      'info-tooltip': {
        $extend: 'tooltip',
        $resetCss: true,
      },
    },
  })
  .use(IonicVue, {
    innerHTMLTemplatesEnabled: true, // To print HTML in: ion-alert, ion-infinite-scroll-content, ion-loading, ion-refresher-content, ion-toast
  })
  .use(createHead())
  .use(pinia)
  .use(router);

// Initialize IndexedDB
useIDB([IndexedDBStoresEnum.Images]);

// Preloading icons
useIcons();

// Setup global error helper
useErrors();

router.isReady().then(() => {
  app.mount('#app');
});

initSentry(app);
