import { cloneDeep, filter, find, includes, indexOf, merge, orderBy, remove, unionBy } from 'lodash';
import { defineStore } from 'pinia';

import type { DocumentExtensionEnum } from '@/enums';
import {
  WikiMajorFilterEnum,
  WikiJsonTextEnum,
  AutoUpdateStatusEnum,
  WikiVersionEnum,
  WikiLockStatusEnum,
  WikiSaveModeEnum,
} from '@/enums';
import { useRichTextEditor } from '@/helpers';
import { generateUnique } from '@/helpers/hasher';
import { useI18n } from '@/i18n';
import { defaultAdvancedWikiModel, defaultEditFormModel, defaultWikisIds } from '@/models';
import { $api } from '@/services';
import { useDocStore, useGroupsStore, useNetworkStore, useUserStore } from '@/store';
import type {
  TopicModel,
  WikiModel,
  WikiHistoryModel,
  CreateWikiPayload,
  UpdateWikiPayload,
  WikiRelationsModel,
  WikiGitDiffModel,
  ResponseWikiModel,
  ResponseWikiRelationsModel,
  ResponseWikiHistoryModel,
  ResponseWikiHistoryByIdModel,
  ResponseErrorModel,
  AdvancedWikiContentModel,
  ResponseWikiCreateModel,
  WikiEditFormModel,
  UserShortModel,
  WikisIdsModel,
  ResponseWikisModel,
  ShortWikisModel,
  UserModel,
  WikiHistoricalModel,
  ResponseWikiFollowersModel,
  WikiTemplateModel,
  ResponseWikiTemplatesModel,
  SaveWikiTemplateModel,
  ResponseWikiTemplateModel,
  CreateWikiTemplateModel,
  ResponseLockModel,
  AdvancedWikiBodyModel,
} from '@/types';

type AdvancedWikiModelKeys = keyof AdvancedWikiContentModel;

export type WikiState = {
  newId: number | null;
  hasChanged: boolean;
  isLoading: boolean;
  saveLoading: boolean;
  typeToDisable: WikiSaveModeEnum | undefined;
  editMode: boolean;
  editForm: WikiEditFormModel;
  existingWiki: WikiModel | null;
  relations: WikiRelationsModel;
  wikiFollowers: UserModel[];
  history: { data: WikiHistoryModel[]; loadMoreUrl: string | null };
  historicalWikis: WikiHistoricalModel[];
  historicalWiki: WikiModel | null;
  isOutdated: boolean;
  versionId: number | null;
  currentGitDiff: WikiGitDiffModel;
  sourceVersion: WikiHistoryModel | null;
  targetVersion: WikiHistoryModel | null;
  comparableWikiName: string;
  autoUpdateStatus: AutoUpdateStatusEnum;
  messageFromAi: { name: string; content: string } | null;
  data: WikiModel[];
  wikisIds: WikisIdsModel;
  templates: { data: WikiTemplateModel[]; loadMoreUrl: string | null };
  currentSaveMode: WikiSaveModeEnum;
};

export const useWikiStore = defineStore({
  id: 'wiki',
  state: (): WikiState => ({
    newId: null,
    hasChanged: false,
    isLoading: false,
    saveLoading: false,
    typeToDisable: undefined,
    editMode: false,
    editForm: cloneDeep(defaultEditFormModel),
    existingWiki: null,
    relations: {
      data: [],
      loadMoreUrl: null,
    },
    wikiFollowers: [],
    history: { data: [], loadMoreUrl: null },
    historicalWikis: [],
    historicalWiki: null,
    isOutdated: false,
    versionId: null,
    currentGitDiff: { sourceId: null, targetId: null, diff: '' },
    sourceVersion: null,
    targetVersion: null,
    comparableWikiName: '',
    autoUpdateStatus: AutoUpdateStatusEnum.Done,
    messageFromAi: null,
    data: [],
    wikisIds: cloneDeep(defaultWikisIds),
    templates: { data: [], loadMoreUrl: null },
    currentSaveMode: WikiSaveModeEnum.Major,
  }),
  getters: {
    isSimple: (state): boolean => {
      if (state.existingWiki) return state.existingWiki.version === WikiVersionEnum.V1;
      return !useNetworkStore().isAdvancedWikiesEditor;
    },
    isAdvanced: (state): boolean => {
      if (state.existingWiki) return state.existingWiki.version === WikiVersionEnum.V2;
      return useNetworkStore().isAdvancedWikiesEditor;
    },
    isOfficial: (state): boolean => state.existingWiki?.isOfficial || false,
    getGroupTitle: (state): string => {
      const id = state.editForm.groupId;
      return id ? useGroupsStore().getGroupById(id).title : '';
    },
    getFolderTitle: (state): string => {
      const id = state.editForm.folderId;
      return id ? useDocStore().getFolderTitle(id) : '';
    },
    getLastEditor: (state): UserShortModel | null =>
      state.existingWiki?.editedBy || state.existingWiki?.modifier || null,
    getLastEditDate: (state): string => state.existingWiki?.editedAt || state.existingWiki?.modifiedAt || '',
    getCreatedBy: (state): UserShortModel | null => (state.existingWiki ? state.existingWiki.createdBy : null),
    getCreationDate: (state): string => state.existingWiki?.createdAt || '',
    getSearchedWikis: (state) => (): ShortWikisModel => {
      const result = { data: [], loadMoreUrl: null } as ShortWikisModel;
      const data = orderBy(state.data, (obj) => indexOf(state.wikisIds.search.ids, obj.id));
      result.data = filter(data, (obj) => includes(state.wikisIds.search.ids, obj.id));
      result.loadMoreUrl = state.wikisIds.search.loadMoreUrl;
      return result;
    },
    getHistoryWikiById:
      (state) =>
      (id: number): WikiHistoricalModel | null =>
        state.history.data.find((wiki) => wiki.id === id) || null,
    getHistory: (state): WikiHistoricalModel[] => state.history.data,
    getHistoryLoadMoreUrl: (state): string | null => state.history.loadMoreUrl,
    getTemplates: (state): WikiTemplateModel[] => state.templates.data,
    getTemplatesLoadMoreUrl: (state): string | null => state.templates.loadMoreUrl,
  },
  actions: {
    //#region Hooks
    /** Use to check if required fields are empty */
    checkFields(): { ok: boolean; reason: string } {
      const { isAdvancedWikiesEditor } = useNetworkStore();
      const {
        name,
        wikiText,
        wikiContent: {
          head,
          // content,
          body,
          participants,
        },
      } = this.editForm;

      const simpleFieldsAreEmpty: Record<string, boolean> = {
        name: !name.trim(),
        wikiText: !wikiText.trim(),
      };

      const advancedFieldsAreEmpty: Record<string, boolean> = {
        headName: !head.name?.trim(),
        headText: !head.text?.trim(),
        // contentName: !content.name?.trim(),
        bodyNames: !body.every((item) => item.name?.trim()),
        bodyTexts: !body.every((item) => item.text?.trim()),
        participantsName: !participants.name?.trim(),
      };

      const simpleFail = !isAdvancedWikiesEditor && this.isSimple && Object.values(simpleFieldsAreEmpty).some(Boolean);
      const advancedFail =
        isAdvancedWikiesEditor && this.isAdvanced && Object.values(advancedFieldsAreEmpty).some(Boolean);

      if (!simpleFail && !advancedFail) return { ok: true, reason: '' };

      if (simpleFail) {
        console.warn(`
        Error during saving simple wiki:
        Name: ${simpleFieldsAreEmpty.name ? '❌' : '✅'}
        Text: ${simpleFieldsAreEmpty.wikiText ? '❌' : '✅'}
        `);

        let message = '';
        switch (true) {
          case simpleFieldsAreEmpty.name:
            message = 'Name is required.';
            break;
          case simpleFieldsAreEmpty.wikiText:
            message = 'Text is required.';
            break;
        }

        return { ok: false, reason: message };
      }

      if (advancedFail) {
        console.warn(`
        Error during saving advanced wiki:
        Head Name: ${advancedFieldsAreEmpty.headName ? '❌' : '✅'}
        Head Text: ${advancedFieldsAreEmpty.headText ? '❌' : '✅'}
        Body Names: ${advancedFieldsAreEmpty.bodyNames ? '❌' : '✅'}
        Body Texts: ${advancedFieldsAreEmpty.bodyTexts ? '❌' : '✅'}
        Participants Name: ${advancedFieldsAreEmpty.participantsName ? '❌' : '✅'}
        `);
        // Content Name: ${advancedFieldIsEmpty.contentName ? '❌' : '✅'}

        let message = '';
        switch (true) {
          case advancedFieldsAreEmpty.headName:
            message = 'Head title is required.';
            break;

          case advancedFieldsAreEmpty.headText:
            message = 'Head text is required.';
            break;

          // case advancedFieldIsEmpty.contentName:
          //   message = 'Content title is required.';
          //   break;

          case advancedFieldsAreEmpty.bodyNames:
            message = 'All body titles are required.';
            break;
          case advancedFieldsAreEmpty.bodyTexts:
            message = 'All body texts are required.';
            break;

          case advancedFieldsAreEmpty.participantsName:
            message = 'Participants title is required.';
            break;
        }

        return { ok: false, reason: message };
      }

      return { ok: true, reason: '' };
    },
    //#endregion

    //#region Re-setters
    resetHistory(): void {
      this.history = { data: [], loadMoreUrl: null };
      this.historicalWikis = [];
    },
    resetFromHistorical(): void {
      this.historicalWiki = null;
      this.versionId = null;
    },
    resetEditForm(): void {
      this.editForm = cloneDeep(defaultEditFormModel);
      this.hasChanged = false;
    },
    resetFromPreviewPage(): void {
      this.existingWiki = null;
      this.historicalWiki = null;
      this.versionId = null;
      this.resetHistory();
    },
    resetFromEditPage(): void {
      this.resetEditForm();
      this.resetWikiFromAi();
      this.existingWiki = null;
    },
    resetFromCreatePage(): void {
      this.resetEditForm();
      this.resetWikiFromAi();
      this.newId = null;
    },
    resetFromComparePage(): void {
      this.currentGitDiff = { sourceId: null, targetId: null, diff: '' };
      this.sourceVersion = null;
      this.targetVersion = null;
      this.comparableWikiName = '';
    },
    resetWikiFromAi(): void {
      this.messageFromAi = null;
    },
    //#endregion

    //#region Setters
    setVersionId(value: number): void {
      this.versionId = value;
    },
    setHasChanged(value: boolean): void {
      this.hasChanged = value;
    },
    setSaveLoading(value: boolean): void {
      this.saveLoading = value;
    },
    setEditMode(mode: boolean): void {
      this.editMode = mode;
    },
    setSaveMode(mode: WikiSaveModeEnum): void {
      this.currentSaveMode = mode;
    },
    setSimpleWiki(prop: 'name' | 'text', value: string): void {
      prop === 'name' ? (this.editForm.name = value) : (this.editForm.wikiText = value);
    },
    setSimpleContentToBody(text: string): void {
      this.editForm.wikiContent.body[0].text = text;
    },
    setPartialEditForm(form: Partial<WikiEditFormModel>): void {
      this.editForm = merge(this.editForm, form);
    },
    setOnCompare(
      target: WikiHistoricalModel | null,
      source: WikiHistoricalModel | null,
      comparableWikiName: string
    ): void {
      this.targetVersion = target;
      this.sourceVersion = source;
      this.comparableWikiName = comparableWikiName;
    },
    setOnCreate(name: string, groupId: number | null, folderId: number | null): void {
      this.editForm.name = name;
      this.editForm.groupId = groupId;
      this.editForm.folderId = folderId;
      const myId = useUserStore().current?.id;
      if (myId) this.editForm.participantsIds = [myId];
    },
    setAdvancedWiki(wiki: WikiModel): void {
      this.editForm = {
        name: wiki.name,
        groupId: wiki.group?.id ?? null,
        folderId: wiki.parentFolderId,
        wikiText: wiki.wikiText,
        wikiContent: {
          ...wiki.wikiContent,
          body: this.parseWikiBody(wiki.wikiContent?.body),
        },
        participantsIds: wiki.wikiContent.participants?.users?.map((user) => user.id) || [],
      };
    },
    setAdvancedWikiContent(content: AdvancedWikiContentModel): void {
      this.editForm.wikiContent = content;
    },
    setDefaultAdvancedWikiModel(): void {
      for (const prop of Object.keys(this.editForm.wikiContent) as AdvancedWikiModelKeys[]) {
        const section = this.editForm.wikiContent[prop];
        if (!section) continue;

        switch (prop) {
          case WikiJsonTextEnum.Head:
            this.editForm.wikiContent.head.name = useI18n().t('wiki.editFields.headTitle');
            break;
          case WikiJsonTextEnum.Content:
            this.editForm.wikiContent.content.name = useI18n().t('wiki.editFields.contentTitle');
            break;
          case WikiJsonTextEnum.Body:
            this.editForm.wikiContent.body.forEach((item, index) => {
              item.name = `${useI18n().t('wiki.editFields.bodyTitle')}_${index + 1}`;
            });
            break;
          case WikiJsonTextEnum.Participants:
            if (this.editForm.wikiContent.participants) {
              this.editForm.wikiContent.participants.name = useI18n().t('wiki.editFields.participants.title');
            }
            break;
          default:
            break;
        }
      }
    },
    setVisibility(section: WikiJsonTextEnum, value: boolean, index: number): void {
      switch (section) {
        case WikiJsonTextEnum.Head:
          this.editForm.wikiContent.head.settings.isVisible = value;
          break;
        case WikiJsonTextEnum.Content:
          this.editForm.wikiContent.content.settings.isVisible = value;
          break;
        case WikiJsonTextEnum.Body:
          this.editForm.wikiContent.body[index].settings.isVisible = value;
          break;
        case WikiJsonTextEnum.Participants:
          this.editForm.wikiContent.participants.settings.isVisible = value;
          break;
        default:
          break;
      }
    },
    setParticipants(users: UserModel[]): void {
      let participants = users.map((user) => ({
        id: user.id,
        fullName: user.fullName,
        mainAlias: user.mainAlias,
        isActive: false,
        image: user.avatar,
        badges: user.badges,
      }));
      participants = participants.filter((user) => !this.editForm.participantsIds?.includes(user.id));
      this.editForm.wikiContent.participants.users?.push(...participants);

      const ids = participants.map((user) => user.id);

      this.editForm.participantsIds = [...this.editForm.participantsIds, ...ids];
    },
    setMessageFromAi(name: string, content: string): void {
      this.messageFromAi = { name, content };
    },
    setHistoricalWiki(historical: WikiHistoricalModel): boolean {
      if (!this.existingWiki) {
        console.error('No existing wiki found');
        return false;
      }

      const fullModelFromHistorical = {
        id: this.existingWiki.id,
        name: historical.name,
        editedBy: historical.editedBy,
        editedAt: historical.editedAt,
        changeType: historical.changeType,
        isMajor: historical.isMajor,
        wikiText: historical.wikiText ?? '',
        wikiContent: historical.wikiContent ?? cloneDeep(defaultAdvancedWikiModel),
        version: this.existingWiki.version,
        createdAt: this.existingWiki.createdAt,
        createdBy: this.existingWiki.createdBy,
        group: this.existingWiki.group,
        bodyHtml: this.existingWiki.bodyHtml,
        tempWikiId: this.existingWiki.tempWikiId,
        tempWikiText: this.existingWiki.tempWikiText,
        tempWikiContent: this.existingWiki.tempWikiContent,
        tags: this.existingWiki.tags,
        access: this.existingWiki.access,
        isOfficial: this.existingWiki.isOfficial,
        parentFolderId: this.existingWiki.parentFolderId,
        isFollowed: this.existingWiki.isFollowed,
      };

      this.historicalWiki = fullModelFromHistorical;
      return true;
    },
    setMentionedUserId(ids: number[]) {
      if (!this.editForm.mentionedUserIds) {
        this.editForm.mentionedUserIds = [...ids];
      } else {
        this.editForm.mentionedUserIds = [...this.editForm.mentionedUserIds, ...ids];
      }
    },
    //#endregion

    //#region Miscellaneous
    addAdvancedWikiBodyItem(newBodyItem: AdvancedWikiBodyModel): void {
      this.editForm.wikiContent.body.push(newBodyItem);
    },
    removeAdvancedWikiBodyItem(id: string): void {
      const index = this.editForm.wikiContent.body.findIndex((body) => body.id === id);
      if (~index) this.editForm.wikiContent.body.splice(index, 1);
    },
    /**
     * Sets default titles for each section of advanced wiki model
     *
     * @deprecated
     * @see src/views/Wikis/WikiCreatePage.vue - onIonViewWillEnter
     */
    removeParticipant(id: number): void {
      this.editForm.wikiContent.participants.users = this.editForm.wikiContent.participants.users?.filter(
        (user) => user.id !== id
      );
      this.editForm.participantsIds = this.editForm.participantsIds?.filter((userId) => userId !== id);
    },
    /**
     * Use it to parse each advanced wiki body item and either set existing ID or generate a new
     *
     * @see src/helpers/hasher.ts
     */
    parseWikiBody(body: AdvancedWikiBodyModel[]): AdvancedWikiBodyModel[] {
      return body.map((item) => {
        return {
          ...item,
          id: item.id || generateUnique(item.text),
        };
      });
    },
    //#endregion

    //#region Basic API
    async wikiById(id: number, update = true): Promise<WikiModel | null> {
      this.isLoading = true;
      try {
        const response = await $api.wiki.getWikiById(id);

        if (response.statusCode === 200) {
          const model = response as ResponseWikiModel;
          model.data.bodyHtml = await useRichTextEditor().preprocessBody(model.data.wikiText);
          update && (this.existingWiki = model.data);
          return model.data;
        }

        update && (this.existingWiki = null);
        return null;
      } catch (e) {
        console.error(e);
        update && (this.existingWiki = null);
        return null;
      } finally {
        this.isLoading = false;
      }
    },
    async create(payload: CreateWikiPayload): Promise<number | false> {
      this.isLoading = true;
      try {
        if (payload.text) {
          payload.text = useRichTextEditor().preSubmit(payload.text);
        }

        const response = await $api.wiki.create(payload);
        if (response.statusCode === 200) {
          const { data } = response as ResponseWikiCreateModel;
          this.newId = data;
          return data;
        }

        return false;
      } catch (e) {
        console.error(e);
        return false;
      } finally {
        this.isLoading = false;
      }
    },
    async update(payload: UpdateWikiPayload): Promise<boolean> {
      this.isLoading = true;
      try {
        if (payload.text) {
          payload.text = useRichTextEditor().preSubmit(payload.text);
        }

        const response = await $api.wiki.update(payload);

        return response.statusCode === 200;
      } catch (e) {
        console.error('Failed to update wiki', e);
        return false;
      } finally {
        this.isLoading = false;
      }
    },
    async download(id: number, documentExtension: DocumentExtensionEnum): Promise<Blob | ResponseErrorModel> {
      try {
        return await $api.wiki.download(id, documentExtension);
      } catch (e) {
        console.error('Failed to download wiki', e);
        return e as ResponseErrorModel;
      }
    },
    async markOfficial(id: number): Promise<boolean> {
      try {
        const docStore = useDocStore();
        const response = await $api.wiki.markAsOfficial(id);

        if (response.statusCode === 200) {
          if (this.existingWiki) {
            this.existingWiki.isOfficial = !this.existingWiki.isOfficial;
          }
          docStore.updateDocsAfterMarkOfficial(
            docStore.browserMode,
            docStore.sortingType,
            id,
            docStore.activeGroup?.id,
            docStore.activeFolder?.id
          );
        }

        return response.statusCode === 200;
      } catch (e) {
        console.error('Failed to make wiki official', e);
        return false;
      }
    },
    async delete(id: number): Promise<boolean> {
      try {
        const response = await $api.wiki.delete(id);
        return response.statusCode === 200;
      } catch (e) {
        console.error('Failed to delete wiki', e);
        return false;
      }
    },
    async deleteWithReplace(
      id: number,
      relationWikiId: number | null,
      relationFileId: number | null
    ): Promise<boolean> {
      try {
        const response = await $api.wiki.deleteWithReplace(id, relationWikiId, relationFileId);

        return response.statusCode === 200;
      } catch (e) {
        console.error('Failed to delete wiki and replace', e);
        return false;
      }
    },
    //#endregion

    //#region Relations
    async getRelations(id: number): Promise<boolean> {
      try {
        const response = await $api.wiki.getRelations(id);

        if (response.statusCode === 200) {
          const { data } = response as ResponseWikiRelationsModel;
          this.relations.data = data.data;
          this.relations.loadMoreUrl = data.loadMoreUrl;

          return true;
        } else {
          return false;
        }
      } catch (e) {
        console.error('Failed to show relations', e);
        return false;
      }
    },
    async addRelation(id: number, relationFileId: number | null, relationWikiId: number | null): Promise<boolean> {
      try {
        const response = await $api.wiki.addRelation(id, relationFileId, relationWikiId);

        return response.statusCode === 200;
      } catch (e) {
        console.error('Failed to add relation', e);
        return false;
      }
    },
    async removeRelation(id: number, relationFileId: number | null, relationWikiId: number | null): Promise<boolean> {
      try {
        const response = await $api.wiki.removeRelation(id, relationFileId, relationWikiId);

        return response.statusCode === 200;
      } catch (e) {
        console.error('Failed to remove relation', e);
        return false;
      }
    },
    //#endregion

    //#region Followers
    async getFollowers(id: number): Promise<boolean> {
      try {
        const response = await $api.wiki.getFollowers(id);

        if (response.statusCode === 200) {
          const model = response as ResponseWikiFollowersModel;
          this.wikiFollowers = (model.data as UserModel[]) || [];

          return true;
        } else {
          return false;
        }
      } catch (e) {
        console.error('Failed to get followers', e);
        return false;
      }
    },
    async follow(id: number): Promise<boolean> {
      try {
        const docStore = useDocStore();
        const response = await $api.wiki.follow(id);

        if (response.statusCode === 200) {
          if (this.existingWiki) {
            this.existingWiki.isFollowed = !this.existingWiki.isFollowed;
          }
          docStore.updateDocsAfterFollow(
            docStore.browserMode,
            docStore.sortingType,
            id,
            docStore.activeGroup?.id,
            docStore.activeFolder?.id
          );
        }

        return response.statusCode === 200;
      } catch (e) {
        console.error('Failed to follow wiki', e);
        return false;
      }
    },
    async unfollow(id: number): Promise<boolean> {
      try {
        const docStore = useDocStore();
        const response = await $api.wiki.unfollow(id);

        if (response.statusCode === 200) {
          if (this.existingWiki) {
            this.existingWiki.isFollowed = !this.existingWiki.isFollowed;
          }
          docStore.updateDocsAfterFollow(
            docStore.browserMode,
            docStore.sortingType,
            id,
            docStore.activeGroup?.id,
            docStore.activeFolder?.id
          );
        }

        return response.statusCode === 200;
      } catch (e) {
        console.error('Failed to unfollow wiki', e);
        return false;
      }
    },
    //#endregion

    //#region History
    async allHistory(id: number): Promise<boolean> {
      try {
        const response = await $api.wiki.getHistory(id);

        if (response.statusCode === 200) {
          const model = response as ResponseWikiHistoryModel;
          this.$patch((state) => {
            state.history.data = model.data;
            state.history.loadMoreUrl = model.loadMoreUrl;
          });
          return true;
        }

        const error = response as ResponseErrorModel;
        console.error(error.errorMessages);
        this.resetHistory();
        return false;
      } catch (e) {
        console.error('Failed to get available wiki historical versions', e);
        this.resetHistory();
        return false;
      }
    },
    async historyLoadMore(loadMoreUrl: string): Promise<boolean> {
      const response = await $api.wiki.getHistoryLoadMore(loadMoreUrl);

      if (response.statusCode === 200) {
        const model = response as ResponseWikiHistoryModel;
        this.history.data = cloneDeep(mergeHistoryById(this.history.data, model.data));
        this.history.loadMoreUrl = model.loadMoreUrl;
        return true;
      }

      const error = response as ResponseErrorModel;
      console.error(error.errorMessages);
      return false;
    },
    async historyById(id: number): Promise<WikiHistoricalModel | null> {
      try {
        const response = await $api.wiki.getHistoryById(id);

        if (response.statusCode === 200) {
          const model = response as ResponseWikiHistoryByIdModel;
          const transformedData = mapFromObsoleteWikiHistoricalModelFields(model.data);
          const found = this.historicalWikis.find((wiki) => wiki.id === transformedData.id);
          if (!found) this.historicalWikis.push(transformedData);

          return transformedData;
        }

        return null;
      } catch (e) {
        console.error('Failed to get available wiki historical versions', e);
        return null;
      }
    },
    async historyByDate(
      id: number,
      date: string,
      majorFilter: WikiMajorFilterEnum
    ): Promise<WikiHistoricalModel | null> {
      try {
        const response = await $api.wiki.getHistoryByDate(id, date, majorFilter);

        if (response.statusCode === 200) {
          const model = response as ResponseWikiHistoryByIdModel;
          const transformedData = mapFromObsoleteWikiHistoricalModelFields(model.data);
          const found = this.historicalWikis.find((wiki) => wiki.id === transformedData.id);
          if (!found) this.historicalWikis.push(transformedData);

          return transformedData;
        }

        return null;
      } catch (e) {
        console.error('Failed to get available wiki historical versions', e);
        return null;
      }
    },
    async updateHistoryById(payload: UpdateWikiPayload): Promise<boolean> {
      try {
        const response = await $api.wiki.updateHistoryById(payload);

        return response.statusCode === 200;
      } catch (e) {
        console.error('Failed to update wiki revision', e);
        return false;
      }
    },
    async rollback(id: number, historyId: number): Promise<WikiHistoryModel | null> {
      try {
        const response = await $api.wiki.rollback(id, historyId);

        if (response.statusCode === 200) {
          const model = response as ResponseWikiHistoryByIdModel;
          return model.data;
        } else {
          return null;
        }
      } catch (e) {
        console.error('Failed to rollback wiki', e);
        return null;
      }
    },
    async checkIfOutdated(wiki: WikiModel, date?: string): Promise<boolean> {
      let result = false;

      try {
        const latest = await this.historyByDate(wiki.id, new Date().toISOString(), WikiMajorFilterEnum.All);
        if (!latest) {
          console.warn('Error during checking if outdated: no latest wiki');
          return result;
        }

        const checkDate = new Date(date ?? wiki.editedAt).getTime();
        const latestEditDate = new Date(latest.editedAt).getTime();
        result = checkDate < latestEditDate;
      } catch (e) {
        console.error('Error during checking if outdated:', e);
      }

      return result;
    },
    /**
     * @note Not implemented yet. Marking deprecated for now
     * @todo Get info from backend team
     * @deprecated
     */
    async deleteVersion(versionId: number): Promise<boolean> {
      try {
        const response = await $api.wiki.deleteVersion(versionId);

        if (response.statusCode === 200) {
          return true;
        } else {
          return false;
        }
      } catch (e) {
        console.error('Failed to delete wiki', e);
        return false;
      }
    },
    //#endregion

    //#region Templates
    async templateById(id: number): Promise<WikiTemplateModel | undefined> {
      const response = await $api.wiki.getTemplateById(id);

      if (response.statusCode === 200) {
        const model = response as ResponseWikiTemplateModel;
        const index = this.templates.data.findIndex((t) => t.id === model.data?.id);
        if (~index) {
          this.templates.data[index] = model.data;
        }
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        console.error(error.errorMessages);
      }

      return undefined;
    },
    async allTemplates(): Promise<boolean> {
      try {
        const response = await $api.wiki.getTemplates();

        if (response.statusCode === 200) {
          const model = response as ResponseWikiTemplatesModel;
          this.$patch((state) => {
            state.templates.data = cloneDeep(model.data);
            state.templates.loadMoreUrl = model.loadMoreUrl;
          });
          return true;
        }

        if (response.statusCode !== 200) {
          const error = response as ResponseErrorModel;
          console.error(error.errorMessages);
        }
        return false;
      } catch (e) {
        console.error('Failed to get wiki templates', e);
        return false;
      }
    },
    async templatesLoadMore(loadMoreUrl: string): Promise<boolean> {
      const response = await $api.wiki.getTemplatesLoadMore(loadMoreUrl);

      if (response.statusCode === 200) {
        const model = response as ResponseWikiTemplatesModel;
        this.templates.data = cloneDeep(mergeTemplatesById(this.templates.data, model.data));
        this.templates.loadMoreUrl = model.loadMoreUrl;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        console.error(error.errorMessages);
      }

      return false;
    },
    async createTemplate(templateData: CreateWikiTemplateModel): Promise<boolean> {
      const response = await $api.wiki.createTemplate(templateData);

      if (response.statusCode === 200) {
        const model = response as ResponseWikiTemplateModel;
        this.templates.data.push(model.data);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        console.error(error.errorMessages);
      }

      return false;
    },
    async updateTemplateById(templateData: SaveWikiTemplateModel): Promise<boolean> {
      const response = await $api.wiki.updateTemplateById(templateData);

      if (response.statusCode === 200) {
        const model = response as ResponseWikiTemplateModel;
        const index = this.templates.data.findIndex((t) => t.id === model.data?.id);
        if (~index) {
          this.templates.data[index] = model.data;
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        console.error(error.errorMessages);
      }

      return false;
    },
    async deleteTemplateById(id: number): Promise<boolean> {
      const response = await $api.wiki.deleteTemplateById(id);

      if (response.statusCode === 200) {
        remove(this.templates.data, { id });
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        console.error(error.errorMessages);
      }

      return false;
    },
    //#endregion

    //#region Tags
    async addTag(id: number, tags: TopicModel[]): Promise<boolean> {
      try {
        const response = await $api.wiki.addTag(
          id,
          tags.map(({ title }) => title)
        );

        return response.statusCode === 200;
      } catch (e) {
        console.error(e);

        return false;
      }
    },
    async removeTag(id: number, tagId: number): Promise<boolean> {
      try {
        const response = await $api.wiki.removeTag(id, tagId);

        return response.statusCode === 200;
      } catch (e) {
        console.error(e);

        return false;
      }
    },
    //#endregion

    //#region Lock
    async lockEdit(id: number): Promise<{ success: boolean; name: string } | null> {
      try {
        const response = await $api.wiki.lockEdit(id);
        if (response.statusCode === 200) {
          const model = response as ResponseLockModel;
          const successStatuses = [WikiLockStatusEnum.NoLock, WikiLockStatusEnum.Offline, WikiLockStatusEnum.Updated];
          return { success: successStatuses.includes(model.data.result), name: model.data.firstLastName };
        }
        return null;
      } catch (e) {
        console.error(e);
        return null;
      }
    },
    async unlockEdit(id: number): Promise<boolean> {
      try {
        const response = await $api.wiki.unlockEdit(id);
        if (response.statusCode === 200) return true;
        return false;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    //#endregion

    //#region Search
    async autocomplete(searchText: string): Promise<void> {
      if (!searchText) return;

      const response = await $api.wiki.autocomplete(searchText);
      if (response.statusCode === 200) {
        const model = response as ResponseWikisModel;
        this.data = mergeById(this.data, model.data);
        this.wikisIds.search.ids = model.data.map((n) => n.id);
        this.wikisIds.search.loadMoreUrl = model.loadMoreUrl;
        return;
      }
      return;
    },
    //#endregion
  },
  persist: true,
});

//#region Helpers
const mergeById = (a: WikiModel[], b: WikiModel[]) => {
  return unionBy(a, b, 'id').map((obj) => {
    const match = find(b, { id: obj.id });
    return match ? Object.assign({}, obj, match) : obj;
  });
};

const mergeHistoryById = (a: WikiHistoricalModel[], b: WikiHistoricalModel[]) => {
  return unionBy(a, b, 'id').map((obj) => {
    const match = find(b, { id: obj.id });
    return match ? Object.assign({}, obj, match) : obj;
  });
};

const mergeTemplatesById = (a: WikiTemplateModel[], b: WikiTemplateModel[]) => {
  return unionBy(a, b, 'id').map((obj) => {
    const match = find(b, { id: obj.id });
    return match ? Object.assign({}, obj, match) : obj;
  });
};

const mapFromObsoleteWikiHistoricalModelFields = (model: WikiHistoricalModel): WikiHistoricalModel => {
  return {
    ...model,
    wikiText: model.text || model.wikiText,
    wikiContent: model.content || model.wikiContent,
    editedAt: model.modifyDate || model.editedAt,
  };
};
//#endregion
