import type { PickedFile } from '@capawesome/capacitor-file-picker';
import { alertController } from '@ionic/core';
import openAI from 'openai';
import { useI18n } from '@/i18n';
import prompt from '../../ai_prompt.json';
import { AiModeEnum, UserRoleEnum, AiAssistantAccessLevelEnum } from '@/enums';
import { useFilesHybrid, useErrors } from '@/helpers';
import { useNetworkStore, useAppStore, useUserStore, useAiAssistantStore } from '@/store';
import type { AnswerFromAiPayload, ChatGPTMessage } from '@/types';
import { ImagesResponse, FileObject, FileObjectsPage, FileDeleted } from 'openai/resources';
import { Threads, Assistant, AssistantListParams, Assistants, Thread } from 'openai/src/resources/beta';

const openai = new openAI({
  // baseURL: 'https://api.openai.com/v2',
  // communex-app service account api key - import.meta.env.VITE_OPEN_AI_NEW_API_KEY
  apiKey: import.meta.env.VITE_OPEN_AI_API_KEY,
  // organization: import.meta.env.VITE_OPEN_AI_ORG_ID,
  // project: import.meta.env.VITE_OPEN_AI_PROJECT_ID,
  dangerouslyAllowBrowser: true,
});

type IUseAiAssistant = {
  // Assistant
  initChat: (threadId: string, t: any) => void;
  getAccess: () => boolean;
  getAnswer: (payload: AnswerFromAiPayload) => Promise<{
    userMessage: Threads.Message | null;
    assistantMessage: Threads.Message | null;
    error: Threads.Runs.Run.LastError | null;
  } | null>;
  getAssistants: (params: AssistantListParams) => Promise<Assistants.Assistant[] | null>;
  checkAssistant: (t: any) => Promise<void>;
  checkThread: (t: any) => Promise<void>;
  deleteAssistant: (assistant: Assistant) => Promise<boolean>;
  deleteMessage: (threadId: string, id: string) => Promise<boolean>;

  // Images
  handleImage: (mode: AiModeEnum, image: PickedFile, prompt: string) => Promise<ImagesResponse | null>;
  getSupportedImageTypes: (t: any) => Promise<boolean>;

  // Files
  getFiles: () => Promise<FileObjectsPage | null>;
  getFileContent: (fileId: string) => Promise<any | null>;
  getSupportedFileTypes: (t: any) => Promise<boolean>;
  uploadFile: (file: PickedFile) => Promise<FileObject | null>;
  retrieveFile: (fileId: string) => Promise<FileObject | null>;
  waitForProcessing: (fileId: string) => Promise<FileObject | null>;
  deleteFile: (fileId: string) => Promise<FileDeleted | null>;
};

export function useAiAssistant(): IUseAiAssistant {
  //#region Private methods
  async function _createAssistant(): Promise<Assistant | null> {
    try {
      // Preparing the instructions
      const featuresString = prompt.en.features.join('\n- ');
      const coreResponsibilitiesString = prompt.en.coreResponsibilities.join('\n- ');
      const instructions = `
        ${prompt.en.mission}

        **Key Features:**
        - ${featuresString}

        **Core Responsibilities:**
        - ${coreResponsibilitiesString}

        **Objective:**
        ${prompt.en.objective}

        **Writing Guidelines:**
        - ${prompt.en.writingGuidelines.join('\n- ')}

        **Closing Statement:**
        ${prompt.en.closingStatement}
      `;

      // Creating an assistant
      const assistant = await openai.beta.assistants.create({
        name: 'CommuneX Bot',
        instructions: instructions,
        model: 'gpt-4o', //TODO: o1
        tools: [{ type: 'code_interpreter' }, { type: 'file_search' }],
      });

      useAiAssistantStore().setAssistant(assistant);
      return assistant;
    } catch (error) {
      console.error('Error making API call:', error);
      return null;
    }
  }

  async function _createThread(): Promise<Thread | null> {
    try {
      const thread = await openai.beta.threads.create();
      useAiAssistantStore().setThread(thread);
      return thread;
    } catch (error) {
      console.error('Error making API call:', error);
      return null;
    }
  }

  async function _messageToThread(
    threadId: string,
    body: Threads.MessageCreateParams
  ): Promise<Threads.Message | null> {
    try {
      const message = await openai.beta.threads.messages.create(threadId, body);
      return message;
    } catch (error) {
      console.error('Error making API call:', error);
      return null;
    }
  }

  async function _createRun(threadId: string, assistantId: string): Promise<Threads.Run | null> {
    try {
      const run = await openai.beta.threads.runs.create(threadId, {
        assistant_id: assistantId,
      });
      return run;
    } catch (error) {
      console.error('Error making API call:', error);
      return null;
    }
  }

  async function _cancelRun(threadId: string, runId: string): Promise<Threads.Run | null> {
    try {
      const run = await openai.beta.threads.runs.cancel(threadId, runId);
      return run;
    } catch (error) {
      console.error('Error making API call:', error);
      return null;
    }
  }

  async function _checkingRunStatus(threadId: string, runId: string): Promise<Threads.Run | null> {
    let run: Threads.Run;
    const timeout = 30000;
    const start = Date.now();

    while (Date.now() - start < timeout) {
      run = await openai.beta.threads.runs.retrieve(threadId, runId);
      /*
      export type RunStatus =
        | 'queued'
        | 'in_progress'
        | 'requires_action'
        | 'cancelling'
        | 'cancelled'
        | 'failed'
        | 'completed'
        | 'incomplete'
        | 'expired';
      */
      switch (run.status) {
        case 'completed':
          return run;

        case 'queued':
        case 'in_progress':
          await new Promise((resolve) => setTimeout(resolve, 1000));
          break;

        case 'requires_action':
          await _cancelRun(threadId, runId);
          return run;

        case 'cancelling':
        case 'cancelled':
        case 'failed':
        case 'completed':
        case 'incomplete':
        case 'expired':
          return run;
      }
    }

    console.error('Run timed out');
    return null;
  }

  async function _createImageVariation(image: PickedFile, n = 1): Promise<ImagesResponse | null> {
    if (!image?.blob) {
      console.error('Image has no blob');
      return null;
    }

    try {
      const preparedImage = await openAI.toFile(image.blob, image.path, {
        type: image.mimeType,
      });

      const response = await openai.images.createVariation({
        image: preparedImage,
        model: 'dall-e-2',
        n,
        response_format: 'url',
        size: '512x512',
      });

      return response;
    } catch (error) {
      console.error('Error creating image variation:', error);
      return null;
    }
  }

  async function _editImage(image: PickedFile, prompt: string, n = 1): Promise<ImagesResponse | null> {
    if (!image?.blob) {
      console.error('Image has no blob');
      return null;
    }

    try {
      const preparedImage = await openAI.toFile(image.blob, image.path, {
        type: image.mimeType,
      });

      const response = await openai.images.edit({
        image: preparedImage,
        prompt,
        model: 'dall-e-2',
        n,
        response_format: 'url',
        size: '512x512',
      });

      return response;
    } catch (error) {
      console.error('Error editing image:', error);
      return null;
    }
  }

  async function _generateImage(prompt: string, n = 1): Promise<ImagesResponse | null> {
    try {
      const response = await openai.images.generate({
        prompt,
        model: 'dall-e-2',
        n,
        quality: 'hd',
        response_format: 'url',
        size: '512x512',
        style: null,
      });

      return response;
    } catch (error) {
      console.error('Error generating image:', error);
      return null;
    }
  }
  //#endregion

  //#region Assistant
  function initChat(threadId: string, t: any): void {
    const greeting: ChatGPTMessage = {
      id: Date.now().toString(),
      role: 'assistant',
      content: t('aiAssistant.greeting'),
      created: Date.now(),
      language: useAppStore().locale,
      isProcessing: false,
    };
    useAiAssistantStore().setMessage(threadId, greeting);
  }

  function getAccess(): boolean {
    const networkStore = useNetworkStore();
    const userStore = useUserStore();
    const currentUserRoleId: UserRoleEnum = userStore.current?.roleId ?? 0;

    const aiAssistantAccessLevel = networkStore.settings?.aiAssistantAccessLevel ?? AiAssistantAccessLevelEnum.None;

    switch (aiAssistantAccessLevel) {
      case AiAssistantAccessLevelEnum.None:
        return false;

      case AiAssistantAccessLevelEnum.User:
        return currentUserRoleId >= UserRoleEnum.User ? true : false;

      case AiAssistantAccessLevelEnum.Administrator:
        return currentUserRoleId >= UserRoleEnum.Administrator ? true : false;

      default:
        return false;
    }
  }

  async function getAnswer(payload: AnswerFromAiPayload): Promise<{
    userMessage: Threads.Message | null;
    assistantMessage: Threads.Message | null;
    error: Threads.Runs.Run.LastError | null;
  } | null> {
    const { assistantId, threadId, message, files } = payload;

    if (!assistantId || !threadId || !message) {
      console.error(`Missing required parameters: ${payload}`);
      return null;
    }

    const createObj: Threads.MessageCreateParams = {
      role: 'user',
      content: message,
    };

    if (files) {
      createObj.attachments = [];
      files.forEach((file) => {
        createObj.attachments?.push({
          file_id: file.id,
          tools: [{ type: 'code_interpreter' }, { type: 'file_search' }],
        });
      });
    }

    try {
      // Add the user message to the thread
      let userMessage = await _messageToThread(threadId, createObj);
      if (!userMessage) {
        console.error('Error creating message');
        return null;
      }

      // Use runs to wait for the assistant response and then retrieve it
      const run = await _createRun(threadId, assistantId);
      if (!run) {
        console.error('Error creating run');
        return null;
      }

      // Check the status of the run for up to 30 seconds
      const status = await _checkingRunStatus(threadId, run.id);
      if (!status) {
        console.error('Failed to check run status or timed out');
        return null;
      }

      if (status.status === 'failed') {
        console.error('Run failed', status);
        return {
          userMessage: null,
          assistantMessage: null,
          error: status.last_error,
        };
      }

      // Get the last assistant message from the messages array
      const messages = await openai.beta.threads.messages.list(threadId);

      // Find the last user message for the current run
      userMessage = messages.data.find((msg) => msg.run_id === run.id && msg.role === 'user') ?? userMessage;

      // Find the last assistant message for the current run
      const assistantMessage = messages.data.find((msg) => msg.run_id === run.id && msg.role === 'assistant');

      if (!assistantMessage) {
        console.error('Error retrieving last message for run');
        return null;
      }

      return { userMessage, assistantMessage, error: null };
    } catch (error) {
      console.error('Error making API call:', error);
      return null;
    }
  }

  async function getAssistants(params: AssistantListParams = {}): Promise<Assistants.Assistant[] | null> {
    try {
      const list = await openai.beta.assistants.list(params);
      return list.data;
    } catch (error) {
      console.error('Error listing assistants:', error);
      return null;
    }
  }

  async function checkAssistant(t: any): Promise<void> {
    const { handleError } = useErrors();

    if (!useAiAssistantStore().getAssistant?.id) {
      const result = await _createAssistant();
      if (!result) {
        handleError(true, undefined, t('aiAssistant.assistantCreate.error'));
        return;
      }
    }
  }

  async function checkThread(t: any): Promise<void> {
    const { handleError } = useErrors();
    const aiAssistantStore = useAiAssistantStore();

    const currentThread = aiAssistantStore.getThread;
    if (!currentThread) {
      const result = await _createThread();
      if (!result) {
        handleError(true, undefined, t('aiAssistant.threadCreate.error'));
        return;
      }
    }
  }

  async function deleteAssistant(assistant: Assistant): Promise<boolean> {
    try {
      await openai.beta.assistants.del(assistant.id);
      return true;
    } catch (error) {
      console.error('Error deleting assistant:', error);
      return false;
    }
  }

  async function deleteMessage(threadId: string, id: string): Promise<boolean> {
    try {
      await openai.beta.threads.messages.del(threadId, id);
      return true;
    } catch (error) {
      console.error('Error deleting message:', error);
      return false;
    }
  }
  //#endregion

  //#region Images
  async function handleImage(
    mode: AiModeEnum,
    image: PickedFile,
    prompt: string,
    n = 1
  ): Promise<ImagesResponse | null> {
    const { handleError } = useErrors();

    if (!image || mode === AiModeEnum.Default) {
      handleError(true, undefined, useI18n().t('aiAssistant.upload.images.error'));
      return null;
    }

    switch (mode) {
      case AiModeEnum.ImageVariation:
        return _createImageVariation(image, n);
      case AiModeEnum.ImageEditing:
        return _editImage(image, prompt, n);
      case AiModeEnum.ImageGeneration:
        return _generateImage(prompt, n);
      default:
        handleError(true, undefined, useI18n().t('aiAssistant.upload.images.error'));
        return null;
    }
  }

  async function getSupportedImageTypes(t: any): Promise<boolean> {
    const alert = await alertController.create({
      header: t('aiAssistant.supportedImages'),
      message: useFilesHybrid()
        .aiSupportedImages.map((file) => `- ${file}`)
        .join('\n'),
      buttons: ['Ok'],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role === 'ok';
  }
  //#endregion

  //#region Files
  async function getFiles(): Promise<FileObjectsPage | null> {
    try {
      return await openai.files.list();
    } catch (error) {
      console.error('Error listing file:', error);
      return null;
    }
  }

  async function getFileContent(fileId: string): Promise<any | null> {
    try {
      return await openai.files.content(fileId);
    } catch (error) {
      console.error('Error getting file content:', error);
      return null;
    }
  }

  async function getSupportedFileTypes(t: any): Promise<boolean> {
    const alert = await alertController.create({
      header: t('aiAssistant.supportedFiles'),
      message: useFilesHybrid()
        .aiSupportedFiles.map((file) => `- ${file}`)
        .join('\n'),
      buttons: ['Ok'],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role === 'ok';
  }

  async function uploadFile(file: PickedFile): Promise<FileObject | null> {
    const { handleError } = useErrors();

    if (!file?.blob) {
      console.error('File has no blob');
      return null;
    }

    try {
      const preparedFile = await openAI.toFile(file.blob, file.path, {
        type: file.mimeType,
      });

      const response = await openai.files.create({
        file: preparedFile,
        purpose: 'assistants', // 'assistants' | 'batch' | 'fine-tune'
      });

      return response;
    } catch (error) {
      handleError(true, error, useI18n().t('aiAssistant.upload.files.error'));
      return null;
    }
  }

  async function retrieveFile(fileId: string): Promise<FileObject | null> {
    try {
      return await openai.files.retrieve(fileId);
    } catch (error) {
      console.error('Error retrieving file:', error);
      return null;
    }
  }

  async function waitForProcessing(fileId: string): Promise<FileObject | null> {
    try {
      return await openai.files.retrieve(fileId);
    } catch (error) {
      console.error('Error waiting for processing:', error);
      return null;
    }
  }

  async function deleteFile(fileId: string): Promise<FileDeleted | null> {
    try {
      return await openai.files.del(fileId);
    } catch (error) {
      console.error('Error deleting file:', error);
      return null;
    }
  }
  //#endregion

  //#region Helpers
  /* //*Simulate the typing animation
  const _simulateTypingAnimation = async (message: string) => {
    const aiAssistantStore = useAiAssistantStore();

    const emptyObj: ChatGPTMessage = {
      id: Date.now().toString(),
      role: 'assistant',
      content: '...',
      created: Date.now(),
      language: 'en',
    }

    aiAssistantStore.addMessage(emptyObj);

    const typingDelay = 50;
    const delay = Math.min(
      typingDelay,
      typingDelay / message.split(' ').length
    );
    const arrayOfChars = message.split('');

    emptyObj.content = '';
    aiAssistantStore.changeMessage(emptyObj.id, '');

    for (const char of arrayOfChars) {
      await new Promise<void>((resolve) => {
        setTimeout(() => {
          aiAssistantStore.changeMessage(emptyObj.id, emptyObj.content + char);
          resolve();
        }, delay);
      });
    }
  };
*/
  //#endregion

  return {
    // Assistant
    initChat,
    getAccess,
    getAnswer,
    getAssistants,
    checkAssistant,
    checkThread,
    deleteAssistant,
    deleteMessage,

    // Images
    handleImage,
    getSupportedImageTypes,

    // Files
    getFiles,
    getFileContent,
    getSupportedFileTypes,
    uploadFile,
    retrieveFile,
    waitForProcessing,
    deleteFile,
  };
}
