import { cloneDeep, filter, find, uniq, includes, indexOf, orderBy, remove, unionBy } from 'lodash';
import { defineStore } from 'pinia';

import type { DocumentExtensionEnum } from '@/enums';
import {
  FeedFilterTypeEnum,
  FeedEventAnswerEnum,
  FeedTypeEnum,
  IdeaTypeEnum,
  PostsFilterEnum,
  PostsModeEnum,
} from '@/enums';
import { DateHelper } from '@/helpers';
import { defaultDraftPosts, defaultPost, defaultPostsIds } from '@/models';
import { $api } from '@/services';
import type { EntityState } from '@/store';
import { useAppStore, useEventStore, useUserStore } from '@/store';
import type {
  DraftPostsModel,
  ErrorMessageModel,
  EventChangeAnswerModel,
  FileModel,
  PollAddOptionModel,
  PollOptionsModel,
  PollVoteModel,
  PostModel,
  PostEventDataModel,
  PostIdeaModel,
  PostPollModel,
  PostsIdsModel,
  RequestAnnouncementCreateModel,
  RequestAnnouncementEditModel,
  RequestBadgeCreateModel,
  RequestCommentCreateModel,
  RequestEventCreateModel,
  RequestEventEditModel,
  RequestIdeaCreateModel,
  RequestIdeaEditModel,
  RequestIdeaUpdateStatusModel,
  RequestPollCreateModel,
  RequestPollEditModel,
  RequestPostComplain,
  RequestPostEditModel,
  RequestSendPostToExternalEmail,
  RequestStandardPostCreateModel,
  ResponseAnnouncementCreateModel,
  ResponseAnnouncementModel,
  ResponseCommentModel,
  ResponseCommentsLoadMoreModel,
  ResponseErrorModel,
  ResponseEventModel,
  ResponsePlannedPollModel,
  ResponsePlannedPostModel,
  ResponsePostModel,
  ResponsePostsIdeaModel,
  ResponsePostsModel,
  ResponseShortUsersModel,
  ShortPostsModel,
  UserShortModel,
  WikiModel,
  ResponsePollModel,
  RequestStandardPostShareModel,
} from '@/types';

interface FeedState extends EntityState<PostModel> {
  loadMoreUrl: string | null;
  postsIds: PostsIdsModel;
  postsMode: PostsModeEnum | null;
  postsGroupId: number | null;
  postsUserId: number | null;
  draftPosts: DraftPostsModel;

  //TODO: Delete when Wiki has its own Store
  latestWikiData: WikiModel | null;

  //NOTE: A list of posts that will be sent to mark them as "read"
  postsToMarkAsRead: number[];
}

export const usePostStore = defineStore({
  id: 'feed',
  state: (): FeedState => ({
    data: [],
    errors: [],
    isLoading: false,
    loadMoreUrl: null,
    postsIds: cloneDeep(defaultPostsIds),
    postsMode: null,
    postsGroupId: null,
    postsUserId: null,
    draftPosts: cloneDeep(defaultDraftPosts),

    //TODO: Delete when Wiki has its own Store
    latestWikiData: null,
    postsToMarkAsRead: [],
  }),
  getters: {
    getErrors:
      (state) =>
      (type: string): string[] => {
        let _errors: string[] = [];
        state.errors
          .filter((f: ErrorMessageModel) => f.key === type)
          .forEach(function (m: ErrorMessageModel) {
            _errors = [..._errors, ...m.errors];
          });
        return _errors;
      },
    getPosts(state): PostModel[] {
      const appStore = useAppStore();

      if (this.postsMode === PostsModeEnum.Feed) {
        switch (appStore.feedType) {
          case FeedFilterTypeEnum.Recommended: {
            return this.getPostsRecommended().data;
          }
          case FeedFilterTypeEnum.AllPublic: {
            return this.getPostsAll().data;
          }
          case FeedFilterTypeEnum.Planned: {
            return this.getPostsPlannedAll().data;
          }
          case FeedFilterTypeEnum.Following: {
            return this.getPostsFollowing().data;
          }
          case FeedFilterTypeEnum.MyBookmarks: {
            return this.getPostsBookmarks().data;
          }
          case FeedFilterTypeEnum.AllMy: {
            const userStore = useUserStore();
            const currentUserId = userStore.current?.id ?? 0;
            return this.getPostsFromUserId(currentUserId).data;
          }
          default: {
            return this.getPostsAll().data;
          }
        }
      }

      if (this.postsMode === PostsModeEnum.Idea) {
        switch (appStore.ideaType) {
          case IdeaTypeEnum.New: {
            return this.getNewIdeas().data;
          }
          case IdeaTypeEnum.InProgress: {
            return this.getInProgressIdeas().data;
          }
          case IdeaTypeEnum.Realized: {
            return this.getRealizedIdeas().data;
          }
          case IdeaTypeEnum.Archived: {
            return this.getArchivedIdeas().data;
          }
          case IdeaTypeEnum.MostLiked: {
            return this.getMostLikedIdeas().data;
          }
          case IdeaTypeEnum.My: {
            return this.getMyIdeas().data;
          }
          default: {
            return this.getNewIdeas().data;
          }
        }
      }

      if (this.postsMode === PostsModeEnum.ByGroup) {
        return this.getPostsFromGroupId(this.postsGroupId).data;
      }

      if (this.postsMode === PostsModeEnum.ByUser) {
        const userIndex = state.postsIds.users.findIndex((n) => n.userId === this.postsUserId);
        if (~userIndex) {
          const data = orderBy(state.data, (obj) => indexOf(state.postsIds.users[userIndex].ids, obj.id));
          return filter(data, (obj) => includes(state.postsIds.users[userIndex].ids, obj.id));
        } else {
          return [];
        }
      }

      return [];
    },
    getLoadMoreUrl:
      (state) =>
      (): {
        url: string | null;
        feedType: FeedFilterTypeEnum | PostsModeEnum;
        ideaType: IdeaTypeEnum;
      } => {
        const appStore = useAppStore();
        let url = null as null | string;
        let feedType = FeedFilterTypeEnum.AllPublic as FeedFilterTypeEnum | PostsModeEnum;
        let ideaType = IdeaTypeEnum.New;
        if (state.postsMode === PostsModeEnum.Feed) {
          feedType = appStore.feedType;
          switch (appStore.feedType) {
            case FeedFilterTypeEnum.Recommended:
              {
                url = state.postsIds.feed.recommended.loadMoreUrl;
              }
              break;
            case FeedFilterTypeEnum.AllPublic:
              {
                url = state.postsIds.feed.all.loadMoreUrl;
              }
              break;
            case FeedFilterTypeEnum.Following:
              {
                url = state.postsIds.feed.following.loadMoreUrl;
              }
              break;
            case FeedFilterTypeEnum.MyBookmarks:
              {
                url = state.postsIds.feed.bookmarks.loadMoreUrl;
              }
              break;
            case FeedFilterTypeEnum.AllMy:
              {
                const userStore = useUserStore();
                const currentUserId = userStore.current?.id ?? 0;
                const index = state.postsIds.users.findIndex((n) => n.userId === currentUserId);
                if (~index) {
                  url = state.postsIds.users[index].loadMoreUrl;
                }
              }
              break;
          }
        }

        if (state.postsMode === PostsModeEnum.Idea) {
          ideaType = appStore.ideaType;
          switch (appStore.ideaType) {
            case IdeaTypeEnum.New:
              {
                url = state.postsIds.ideas.new.loadMoreUrl;
              }
              break;
            case IdeaTypeEnum.InProgress:
              {
                url = state.postsIds.ideas.inProgress.loadMoreUrl;
              }
              break;
            case IdeaTypeEnum.Realized:
              {
                url = state.postsIds.ideas.realized.loadMoreUrl;
              }
              break;
            case IdeaTypeEnum.Archived:
              {
                url = state.postsIds.ideas.archived.loadMoreUrl;
              }
              break;
            case IdeaTypeEnum.MostLiked:
              {
                url = state.postsIds.ideas.mostLiked.loadMoreUrl;
              }
              break;
            case IdeaTypeEnum.My:
              {
                url = state.postsIds.ideas.my.loadMoreUrl;
              }
              break;
          }
        }

        if (state.postsMode === PostsModeEnum.ByGroup) {
          feedType = PostsModeEnum.ByGroup;
          const index = state.postsIds.groups.findIndex((n) => n.groupId === state.postsGroupId);
          if (~index) {
            url = state.postsIds.groups[index].loadMoreUrl;
          }
        }

        if (state.postsMode === PostsModeEnum.ByUser) {
          feedType = PostsModeEnum.ByUser;
          const index = state.postsIds.users.findIndex((n) => n.userId === state.postsUserId);
          if (~index) {
            url = state.postsIds.users[index].loadMoreUrl;
          }
        }

        return { url, feedType, ideaType };
      },
    getAnnouncements(state) {
      const data = orderBy(state.data, (obj) => indexOf(state.postsIds.announcements.ids, obj.id));
      return filter(
        data,
        (obj) => includes(state.postsIds.announcements.ids, obj.id) && obj.isAnnouncementRead === false
      );
    },
    getPostsOfSearch(state): ShortPostsModel {
      const result = orderBy(state.data, ({ id: postId }) => indexOf(state.postsIds.search.ids, postId));
      const data = filter(result, (post) => includes(state.postsIds.search.ids, post.id));

      return {
        data,
        loadMoreUrl: state.postsIds.search.loadMoreUrl,
      };
    },
    getPostsRecommended: (state) => (): ShortPostsModel => {
      const result = { data: [], loadMoreUrl: null } as ShortPostsModel;
      const data = orderBy(state.data, (obj) => indexOf(state.postsIds.feed.recommended.ids, obj.id));
      result.data = filter(data, (obj) => includes(state.postsIds.feed.recommended.ids, obj.id));
      result.loadMoreUrl = state.postsIds.feed.recommended.loadMoreUrl;
      return result;
    },
    getPostsAll: (state) => (): ShortPostsModel => {
      const result = { data: [], loadMoreUrl: null } as ShortPostsModel;
      const data = orderBy(state.data, (obj) => indexOf(state.postsIds.feed.all.ids, obj.id));
      result.data = filter(data, (obj) => includes(state.postsIds.feed.all.ids, obj.id));
      result.loadMoreUrl = state.postsIds.feed.all.loadMoreUrl;
      return result;
    },
    getPostsPlannedAll: (state) => (): ShortPostsModel => {
      const result = { data: [], loadMoreUrl: null } as ShortPostsModel;
      const data = orderBy(state.data, (obj) => indexOf(state.postsIds.feed.planned.ids, obj.id));
      result.data = filter(data, (obj) => includes(state.postsIds.feed.planned.ids, obj.id));
      result.loadMoreUrl = state.postsIds.feed.planned.loadMoreUrl;
      return result;
    },
    getPostsFollowing: (state) => (): ShortPostsModel => {
      const result = { data: [], loadMoreUrl: null } as ShortPostsModel;
      const data = orderBy(state.data, (obj) => indexOf(state.postsIds.feed.following.ids, obj.id));
      result.data = filter(data, (obj) => includes(state.postsIds.feed.following.ids, obj.id));
      result.loadMoreUrl = state.postsIds.feed.following.loadMoreUrl;
      return result;
    },
    getPostsBookmarks: (state) => (): ShortPostsModel => {
      const result = { data: [], loadMoreUrl: null } as ShortPostsModel;
      const data = orderBy(state.data, (obj) => indexOf(state.postsIds.feed.bookmarks.ids, obj.id));
      result.data = filter(data, (obj) => includes(state.postsIds.feed.bookmarks.ids, obj.id));
      result.loadMoreUrl = state.postsIds.feed.bookmarks.loadMoreUrl;
      return result;
    },
    getPostsPolls: (state) => (): ShortPostsModel => {
      const result = { data: [], loadMoreUrl: null } as ShortPostsModel;
      const data = orderBy(state.data, (obj) => indexOf(state.postsIds.polls.ids, obj.id));
      result.data = filter(data, (obj) => includes(state.postsIds.polls.ids, obj.id));
      result.loadMoreUrl = state.postsIds.polls.loadMoreUrl;
      return result;
    },
    getNewIdeas: (state) => (): ShortPostsModel => {
      const result = { data: [], loadMoreUrl: null } as ShortPostsModel;
      const data = orderBy(state.data, (obj) => indexOf(state.postsIds.ideas.new.ids, obj.id));
      result.data = filter(data, (obj) => includes(state.postsIds.ideas.new.ids, obj.id));
      result.loadMoreUrl = state.postsIds.ideas.new.loadMoreUrl;
      return result;
    },
    getInProgressIdeas: (state) => (): ShortPostsModel => {
      const result = { data: [], loadMoreUrl: null } as ShortPostsModel;
      const data = orderBy(state.data, (obj) => indexOf(state.postsIds.ideas.inProgress.ids, obj.id));
      result.data = filter(data, (obj) => includes(state.postsIds.ideas.inProgress.ids, obj.id));
      result.loadMoreUrl = state.postsIds.ideas.inProgress.loadMoreUrl;
      return result;
    },
    getRealizedIdeas: (state) => (): ShortPostsModel => {
      const result = { data: [], loadMoreUrl: null } as ShortPostsModel;
      const data = orderBy(state.data, (obj) => indexOf(state.postsIds.ideas.realized.ids, obj.id));
      result.data = filter(data, (obj) => includes(state.postsIds.ideas.realized.ids, obj.id));
      result.loadMoreUrl = state.postsIds.ideas.realized.loadMoreUrl;
      return result;
    },
    getArchivedIdeas: (state) => (): ShortPostsModel => {
      const result = { data: [], loadMoreUrl: null } as ShortPostsModel;
      const data = orderBy(state.data, (obj) => indexOf(state.postsIds.ideas.archived.ids, obj.id));
      result.data = filter(data, (obj) => includes(state.postsIds.ideas.archived.ids, obj.id));
      result.loadMoreUrl = state.postsIds.ideas.archived.loadMoreUrl;
      return result;
    },
    getMostLikedIdeas: (state) => (): ShortPostsModel => {
      const result = { data: [], loadMoreUrl: null } as ShortPostsModel;
      const data = orderBy(state.data, (obj) => indexOf(state.postsIds.ideas.mostLiked.ids, obj.id));
      result.data = filter(data, (obj) => includes(state.postsIds.ideas.mostLiked.ids, obj.id));
      result.loadMoreUrl = state.postsIds.ideas.mostLiked.loadMoreUrl;
      return result;
    },
    getMyIdeas: (state) => (): ShortPostsModel => {
      const result = { data: [], loadMoreUrl: null } as ShortPostsModel;
      const data = orderBy(state.data, (obj) => indexOf(state.postsIds.ideas.my.ids, obj.id));
      result.data = filter(data, (obj) => includes(state.postsIds.ideas.my.ids, obj.id));
      result.loadMoreUrl = state.postsIds.ideas.my.loadMoreUrl;
      return result;
    },
    getPostsFromGroupId:
      (state) =>
      (groupId: number | null): ShortPostsModel => {
        const index = state.postsIds.groups.findIndex((n) => n.groupId === groupId);
        const result = { data: [], loadMoreUrl: null } as ShortPostsModel;
        if (~index) {
          const data = orderBy(state.data, (obj) => indexOf(state.postsIds.groups[index].ids, obj.id));
          result.data = filter(data, (obj) => includes(state.postsIds.groups[index].ids, obj.id));
          result.loadMoreUrl = state.postsIds.groups[index].loadMoreUrl;
          return result;
        } else {
          return result;
        }
      },
    getPostsFromUserId:
      (state) =>
      (userId: number | null): ShortPostsModel => {
        const index = state.postsIds.users.findIndex((n) => n.userId === userId);
        const result = { data: [], loadMoreUrl: null } as ShortPostsModel;
        if (~index) {
          const data = orderBy(state.data, (obj) => indexOf(state.postsIds.users[index].ids, obj.id));
          result.data = filter(data, (obj) => includes(state.postsIds.users[index].ids, obj.id));
          result.loadMoreUrl = state.postsIds.users[index].loadMoreUrl;
          return result;
        } else {
          return result;
        }
      },
    getPostsFromWikiId:
      (state) =>
      (wikiId: number): ShortPostsModel => {
        const index = state.postsIds.wikis.findIndex((n) => n.wikiId === wikiId);
        const result = { data: [], loadMoreUrl: null } as ShortPostsModel;
        if (~index) {
          const data = orderBy(state.data, (obj) => indexOf(state.postsIds.wikis[index].ids, obj.id));
          result.data = filter(data, (obj) => includes(state.postsIds.wikis[index].ids, obj.id));
          result.loadMoreUrl = state.postsIds.wikis[index].loadMoreUrl;
          return result;
        } else {
          return result;
        }
      },
    getPostsFromFileId:
      (state) =>
      (fileId: number): ShortPostsModel => {
        const index = state.postsIds.files.findIndex((n) => n.fileId === fileId);
        const result = { data: [], loadMoreUrl: null } as ShortPostsModel;
        if (~index) {
          const data = orderBy(state.data, (obj) => indexOf(state.postsIds.files[index].ids, obj.id));
          result.data = filter(data, (obj) => includes(state.postsIds.files[index].ids, obj.id));
          result.loadMoreUrl = state.postsIds.files[index].loadMoreUrl;
          return result;
        } else {
          return result;
        }
      },
    getPostsFromTagId:
      (state) =>
      (tagId: number): ShortPostsModel => {
        const index = state.postsIds.tags.findIndex((n) => n.tagId === tagId);
        const result = { data: [], loadMoreUrl: null } as ShortPostsModel;
        if (~index) {
          const data = orderBy(state.data, (obj) => indexOf(state.postsIds.tags[index].ids, obj.id));
          result.data = filter(data, (obj) => includes(state.postsIds.tags[index].ids, obj.id));
          result.loadMoreUrl = state.postsIds.tags[index].loadMoreUrl;
          return result;
        } else {
          return result;
        }
      },
    getPostById:
      (state) =>
      (id: number): PostModel => {
        const index = state.data.findIndex((post: PostModel) => post.id === id);

        if (~index) {
          return state.data[index];
        }
        return cloneDeep(defaultPost);
      },
    getForceToReadPosts: (state) => (): ShortPostsModel => {
      const result = { data: [], loadMoreUrl: null } as ShortPostsModel;
      const data = orderBy(state.data, (obj) => indexOf(state.postsIds.forceToRead.ids, obj.id));
      result.data = filter(data, (obj) => includes(state.postsIds.forceToRead.ids, obj.id));
      result.loadMoreUrl = state.postsIds.forceToRead.loadMoreUrl;
      return result;
    },
  },
  actions: {
    async postsFromUserId(userId: number): Promise<void> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.post.getPostsFromUserId(userId);

      if (response.statusCode === 200) {
        const model = response as ResponsePostsModel;
        const index = this.postsIds.users.findIndex((n) => n.userId === userId);
        this.data = mergeById(this.data, model.data);

        if (~index) {
          this.postsIds.users[index].ids = model.data.map((n) => n.id);
          this.postsIds.users[index].loadMoreUrl = model.loadMoreUrl;
        } else {
          this.postsIds.users.push({
            userId: userId,
            ids: model.data.map((n) => n.id),
            loadMoreUrl: model.loadMoreUrl,
          });
        }

        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
    },
    async loadMore(): Promise<boolean> {
      const { url, feedType, ideaType } = this.getLoadMoreUrl();

      if (url !== null) {
        this.errors = [];
        const response = await $api.post.loadMore(url);

        if (response.statusCode === 200) {
          const model = response as ResponsePostsModel;
          this.data = mergeById(this.data, model.data);
          updatePostsAfterLoadedMore(feedType, ideaType, model, this.postsGroupId, null, null, this.postsUserId);
          this.errors = [];
          return model.loadMoreUrl === null;
        }

        if (response.statusCode !== 200) {
          const error = response as ResponseErrorModel;
          this.errors = cloneDeep(error.errorMessages);
        }

        return false;
      }

      return true;
    },
    async customLoadMore(
      url: string,
      feedType: FeedFilterTypeEnum | null,
      groupId: number | null,
      tagId: number | null,
      wikiId: number | null,
      userId: number | null
    ): Promise<string | null> {
      this.errors = [];
      const response = await $api.post.loadMore(url);

      if (response.statusCode === 200) {
        const model = response as ResponsePostsModel;
        this.data = mergeById(this.data, model.data);
        updatePostsAfterLoadedMore(feedType, null, model, groupId, tagId, wikiId, userId);
        return model.loadMoreUrl;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return null;
    },
    async pollAddOption(data: PollAddOptionModel, parentPostId?: number | null): Promise<PollOptionsModel | boolean> {
      this.errors = [];
      const response = await $api.post.pollAddOption(data);

      if (response.statusCode === 200) {
        const option = {
          isAnswered: false,
          text: data.option,
          votesCount: 0,
          voteIds: [],
        } as PollOptionsModel;

        // if poll in shared post
        if (parentPostId !== null) {
          const parentPostIndex = this.data.findIndex((n) => n.id === parentPostId);
          if (~parentPostIndex) {
            this.data[parentPostIndex].sharedMessages.data[0].pollData?.options.push(option);
          }
        } else {
          const index = this.data.findIndex((n) => n.id === data.id);
          if (~index) {
            this.data[index].pollData?.options.push(option);
          }
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async pollAddVote(data: PollVoteModel, parentPostId: number | null): Promise<boolean> {
      const userStore = useUserStore();
      const currentUserId = userStore.current?.id ?? 0;
      this.errors = [];
      const response = await $api.post.pollAddVote(data);

      if (response.statusCode === 200) {
        // if poll in shared post
        if (parentPostId !== null) {
          const parentPostIndex = this.data.findIndex((n) => n.id === parentPostId);
          if (~parentPostIndex) {
            const sharedMessagePollData = this.data[parentPostIndex].sharedMessages.data[0].pollData as PostPollModel;
            const userIsExist = sharedMessagePollData.options.findIndex((n) => n.voteIds.includes(currentUserId));
            if (!~userIsExist) sharedMessagePollData.voters++;
            sharedMessagePollData.votes++;
            sharedMessagePollData.options[data.optionIndex].isAnswered = true;
            sharedMessagePollData.options[data.optionIndex].votesCount++;
            sharedMessagePollData.options[data.optionIndex].voteIds.push(currentUserId);
          }
        }

        const index = this.data.findIndex((n) => n.id === data.messageId);
        if (~index) {
          const pollData = this.data[index].pollData as PostPollModel;
          const userIsExist = pollData.options.findIndex((n) => n.voteIds.includes(currentUserId));
          if (!~userIsExist) pollData.voters++;
          pollData.votes++;
          pollData.options[data.optionIndex].isAnswered = true;
          pollData.options[data.optionIndex].votesCount++;
          pollData.options[data.optionIndex].voteIds.push(currentUserId);
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async pollRemoveVote(data: PollVoteModel, parentPostId: number | null): Promise<boolean> {
      const userStore = useUserStore();
      const currentUserId = userStore.current?.id ?? 0;
      this.errors = [];
      const response = await $api.post.pollRemoveVote(data);

      if (response.statusCode === 200) {
        if (parentPostId !== null) {
          const parentPostIndex = this.data.findIndex((n) => n.id === parentPostId);
          if (~parentPostIndex) {
            const sharedMessagePollData = this.data[parentPostIndex].sharedMessages.data[0].pollData as PostPollModel;
            sharedMessagePollData.votes--;
            sharedMessagePollData.options[data.optionIndex].isAnswered = false;
            sharedMessagePollData.options[data.optionIndex].votesCount--;
            remove(sharedMessagePollData.options[data.optionIndex].voteIds, (n) => n === currentUserId);
            const userIsExist = sharedMessagePollData.options.findIndex((n) => n.voteIds.includes(currentUserId));
            if (!~userIsExist) sharedMessagePollData.voters--;
          }
        }

        const index = this.data.findIndex((n) => n.id === data.messageId);
        if (~index) {
          const pollData = this.data[index].pollData as PostPollModel;
          pollData.votes--;
          pollData.options[data.optionIndex].isAnswered = false;
          pollData.options[data.optionIndex].votesCount--;
          remove(pollData.options[data.optionIndex].voteIds, (n) => n === currentUserId);
          const userIsExist = pollData.options.findIndex((n) => n.voteIds.includes(currentUserId));
          if (!~userIsExist) pollData.voters--;
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async pollById(id: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.getPollById(id);

      if (response.statusCode === 200) {
        const model = response as ResponsePollModel;
        const index = this.data.findIndex((n) => n.id === id);
        if (~index) {
          this.data[index].pollData = model.data.pollData;
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async getPollVoters(id: number, index: number): Promise<UserShortModel[] | undefined> {
      this.errors = [];
      const response = await $api.post.getPollVoters(id, index);

      if (response.statusCode === 200) {
        const model = response as ResponseShortUsersModel;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return undefined;
    },
    async postAddLike(id: number): Promise<boolean> {
      this.errors = [];
      const index = this.data.findIndex((n) => n.id === id);
      if (~index) {
        this.data[index].likes.count++;
        this.data[index].likes.isLiked = true;
      }

      const response = await $api.post.postAddLike(id);

      if (response.statusCode === 200) {
        return true;
      }

      if (response.statusCode !== 200) {
        if (~index) {
          this.data[index].likes.count--;
          this.data[index].likes.isLiked = false;
        }
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async postRemoveLike(id: number): Promise<boolean> {
      this.errors = [];
      const index = this.data.findIndex((n) => n.id === id);
      if (~index) {
        this.data[index].likes.count--;
        this.data[index].likes.isLiked = false;
      }

      const response = await $api.post.postRemoveLike(id);

      if (response.statusCode === 200) {
        return true;
      }

      if (response.statusCode !== 200) {
        if (~index) {
          this.data[index].likes.count++;
          this.data[index].likes.isLiked = true;
        }
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async addBookmark(postId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.addBookmark(postId);

      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.id === postId);
        this.data[index].isBookmarked = true;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async removeBookmark(postId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.removeBookmark(postId);

      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.id === postId);
        this.data[index].isBookmarked = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async markAsRead(all: boolean, postsIds: number[]): Promise<boolean> {
      this.errors = [];
      const uniqIds = all
        ? uniq(this.data.filter((post) => post.id !== 0 && !post.isRead).map(({ id }) => id))
        : uniq(postsIds);
      const response = await $api.post.markAsRead(uniqIds);
      this.postsToMarkAsRead = [];
      if (response.statusCode === 200) {
        all
          ? (this.data = this.data.map((post) => {
              return {
                ...post,
                isRead: true,
                usersReadCount: post.usersReadCount + 1,
              };
            }))
          : this.markAsReadLocally(uniqIds);

        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async markAsUnread(postsIds: number[]): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.markAsUnread(postsIds);

      if (response.statusCode === 200) {
        postsIds.forEach((post) => {
          const index = this.data.findIndex((n) => n.id === post);
          if (~index) {
            this.data[index].isRead = false;
          }
        });

        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async pinPost(postId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.pinPost(postId);

      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.id === postId);
        this.data[index].isPinned = true;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async unPinPost(postId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.unPinPost(postId);

      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.id === postId);
        this.data[index].isPinned = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async followToPost(postId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.followToPost(postId);

      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.id === postId);
        this.data[index].isFollowed = true;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async unFollowFromPost(postId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.unFollowFromPost(postId);

      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.id === postId);
        this.data[index].isFollowed = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async complainPost(postId: number, complainData: RequestPostComplain): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.complainPost(postId, complainData);

      if (response.statusCode === 200) {
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async sendPostToEmail(postId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.sendPostToEmail(postId);

      if (response.statusCode === 200) {
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async sendPostToExternalEmail(postId: number, emailData: RequestSendPostToExternalEmail): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.sendPostToExternalEmail(postId, emailData);

      if (response.statusCode === 200) {
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async commentCreate(commentData: RequestCommentCreateModel): Promise<boolean> {
      this.errors = [];

      if (
        (commentData.fileExistIds?.length || commentData.fileTempIds?.length || commentData.wikiIds?.length) &&
        !commentData.text.length
      ) {
        commentData.text = ' ';
      }
      const response = await $api.post.commentCreate(commentData);

      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.id === commentData.messageId);
        const model = response as ResponseCommentModel;
        if (~index) {
          this.data[index].comments.data.push(model.data);
          this.data[index].comments.count++;
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async commentDelete(commentId: number, postId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.commentDelete(commentId);
      if (response.statusCode === 200) {
        const postIndex = this.data.findIndex((n) => n.id === postId);
        if (~postIndex) {
          const commentIndex = this.data[postIndex].comments.data.findIndex((c) => c.id === commentId);
          if (~commentIndex) {
            this.data[postIndex].comments.data.splice(commentIndex, 1);
            this.data[postIndex].comments.count--;
          }
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async commentAddLike(id: number, postId: number): Promise<boolean> {
      this.errors = [];
      const postIndex = this.data.findIndex((n) => n.id === postId);
      const commentIndex = this.data[postIndex].comments.data.findIndex((f) => f.id === id);
      if (~commentIndex) {
        this.data[postIndex].comments.data[commentIndex].likes.count++;
        this.data[postIndex].comments.data[commentIndex].likes.isLiked = true;
      }

      const response = await $api.post.commentAddLike(id);

      if (response.statusCode === 200) {
        return true;
      }

      if (response.statusCode !== 200) {
        if (~commentIndex) {
          this.data[postIndex].comments.data[commentIndex].likes.count--;
          this.data[postIndex].comments.data[commentIndex].likes.isLiked = false;
        }
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async commentRemoveLike(id: number, postId: number): Promise<boolean> {
      this.errors = [];
      const postIndex = this.data.findIndex((n) => n.id === postId);
      const commentIndex = this.data[postIndex].comments.data.findIndex((f) => f.id === id);
      if (~commentIndex) {
        this.data[postIndex].comments.data[commentIndex].likes.count--;
        this.data[postIndex].comments.data[commentIndex].likes.isLiked = false;
      }

      const response = await $api.post.commentRemoveLike(id);

      if (response.statusCode === 200) {
        return true;
      }

      if (response.statusCode !== 200) {
        if (~commentIndex) {
          this.data[postIndex].comments.data[commentIndex].likes.count++;
          this.data[postIndex].comments.data[commentIndex].likes.isLiked = true;
        }
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async commentsLoadMore(data: any): Promise<boolean> {
      const postIndex = this.data.findIndex((n) => n.id === data.postId);
      if (this.data[postIndex].comments.loadMoreUrl !== null) {
        this.errors = [];
        const response = await $api.post.commentsLoadMore(data.url);

        if (response.statusCode === 200) {
          const model = response as ResponseCommentsLoadMoreModel;
          this.data[postIndex].comments.data = [...model.data.data, ...this.data[postIndex].comments.data];
          this.data[postIndex].comments.loadMoreUrl = model.data.loadMoreUrl;
          this.data[postIndex].comments.countRemains = model.data.countRemains;
          this.errors = [];
          return true;
        }

        if (response.statusCode !== 200) {
          const error = response as ResponseErrorModel;
          this.errors = cloneDeep(error.errorMessages);
        }

        return false;
      }
      return true;
    },
    async postsFromGroupId(
      groupId: number,
      readStatus: PostsFilterEnum | undefined = PostsFilterEnum.All
    ): Promise<void> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.post.getPostsFromGroupId(groupId, readStatus);

      if (response.statusCode === 200) {
        const model = response as ResponsePostsModel;

        const index = this.postsIds.groups.findIndex((n) => n.groupId === groupId);

        this.data = mergeById(this.data, model.data);

        if (~index) {
          this.postsIds.groups[index].ids = model.data.map((n) => n.id);
          this.postsIds.groups[index].loadMoreUrl = model.loadMoreUrl;
        } else {
          this.postsIds.groups.push({
            groupId: groupId,
            ids: model.data.map((n) => n.id),
            loadMoreUrl: model.loadMoreUrl,
          });
        }

        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
    },
    postsFromSearch(posts: PostModel[], loadMoreUrl: string | null = null): void {
      if (posts.length) {
        this.postsIds.search.ids = posts.map(({ id }) => id);
        this.data = mergeById(this.data, posts);
        this.postsIds.search.loadMoreUrl = loadMoreUrl;
      } else {
        this.postsIds.search.ids = [];
        this.postsIds.search.loadMoreUrl = null;
      }
    },
    async postsAll(readStatus: PostsFilterEnum | undefined = PostsFilterEnum.All): Promise<void> {
      this.isLoading = true;
      const response = await $api.post.getPostsAll(readStatus);

      if (response.statusCode === 200) {
        const model = response as ResponsePostsModel;
        this.postsIds.feed.all.ids = model.data.map((n) => n.id);
        this.data = mergeById(this.data, model.data);
        this.postsIds.feed.all.loadMoreUrl = model.loadMoreUrl;
        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
    },
    async postsPlannedAll(): Promise<void> {
      //Bitoobit. Added boolean variable for checking loadMoreUrl before change data(for updating data in interval)
      /* if (!checkBeforeUpdate) this.loading = true; */
      this.isLoading = true;
      const response = await $api.post.getPlannedPosts();

      if (response.statusCode === 200) {
        const model = response as ResponsePostsModel;
        this.postsIds.feed.planned.ids = model.data.map((n) => n.id);
        this.data = mergeById(this.data, model.data);
        this.postsIds.feed.planned.loadMoreUrl = model.loadMoreUrl;
        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
    },
    async postsFollowing(readStatus: PostsFilterEnum | undefined = PostsFilterEnum.All): Promise<void> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.post.getPostsFollowing(readStatus);

      if (response.statusCode === 200) {
        const model = response as ResponsePostsModel;
        this.postsIds.feed.following.ids = model.data.map((n) => n.id);
        this.data = mergeById(this.data, model.data);
        this.postsIds.feed.following.loadMoreUrl = model.loadMoreUrl;
        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
    },
    async postsBookmarks(): Promise<void> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.post.getPostsBookmarks();

      if (response.statusCode === 200) {
        const model = response as ResponsePostsModel;
        this.postsIds.feed.bookmarks.ids = model.data.map((n) => n.id);
        this.data = mergeById(this.data, model.data);
        this.postsIds.feed.bookmarks.loadMoreUrl = model.loadMoreUrl;
        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
    },
    async postsRecommended(): Promise<void> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.post.getPostsRecommended();

      if (response.statusCode === 200) {
        const model = response as ResponsePostsModel;
        this.postsIds.feed.recommended.ids = model.data.map((n) => n.id);
        this.data = mergeById(this.data, model.data);
        this.postsIds.feed.recommended.loadMoreUrl = model.loadMoreUrl;
        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
    },
    async postFromId(postId: number): Promise<void> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.post.getPostFromId(postId);

      if (response.statusCode === 200) {
        const model = response as ResponsePostModel;
        this.isLoading = false;
        this.upsert(model.data);
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
    },
    async getPostFromId(postId: number): Promise<PostModel | undefined> {
      this.errors = [];
      const response = await $api.post.getPostFromId(postId);

      if (response.statusCode === 200) {
        const model = response as ResponsePostModel;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return undefined;
    },
    async postHide(postId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.postHide(postId);

      if (response.statusCode === 200) {
        remove(this.data, (n) => n.id === postId);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async postsPolls(): Promise<void> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.post.getPostsPolls();

      if (response.statusCode === 200) {
        const model = response as ResponsePostsModel;
        this.postsIds.polls.ids = model.data.map((n) => n.id);
        this.data = mergeById(this.data, model.data);
        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return;
    },
    async postsNewIdeas(): Promise<ResponsePostsIdeaModel | undefined> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.post.getIdeas(IdeaTypeEnum.New);

      if (response.statusCode === 200) {
        const model = response as ResponsePostsModel;
        this.postsIds.ideas.new.ids = model.data.map((n) => n.id);
        this.data = mergeById(this.data, model.data);
        this.postsIds.ideas.new.loadMoreUrl = model.loadMoreUrl;
        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return;
    },
    async postsInProgressIdeas(): Promise<ResponsePostsIdeaModel | undefined> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.post.getIdeas(IdeaTypeEnum.InProgress);

      if (response.statusCode === 200) {
        const model = response as ResponsePostsModel;
        this.postsIds.ideas.inProgress.ids = model.data.map((n) => n.id);
        this.data = mergeById(this.data, model.data);
        this.postsIds.ideas.inProgress.loadMoreUrl = model.loadMoreUrl;
        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return;
    },
    async postsRealizedIdeas(): Promise<ResponsePostsIdeaModel | undefined> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.post.getIdeas(IdeaTypeEnum.Realized);

      if (response.statusCode === 200) {
        const model = response as ResponsePostsModel;
        this.postsIds.ideas.realized.ids = model.data.map((n) => n.id);
        this.data = mergeById(this.data, model.data);
        this.postsIds.ideas.realized.loadMoreUrl = model.loadMoreUrl;
        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return;
    },
    async postsArchivedIdeas(): Promise<ResponsePostsIdeaModel | undefined> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.post.getIdeas(IdeaTypeEnum.Archived);

      if (response.statusCode === 200) {
        const model = response as ResponsePostsModel;
        this.postsIds.ideas.archived.ids = model.data.map((n) => n.id);
        this.data = mergeById(this.data, model.data);
        this.postsIds.ideas.archived.loadMoreUrl = model.loadMoreUrl;
        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return;
    },
    async postsMostLikedIdeas(): Promise<ResponsePostsIdeaModel | undefined> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.post.getIdeas(IdeaTypeEnum.MostLiked);

      if (response.statusCode === 200) {
        const model = response as ResponsePostsModel;
        this.postsIds.ideas.mostLiked.ids = model.data.map((n) => n.id);
        this.data = mergeById(this.data, model.data);
        this.postsIds.ideas.mostLiked.loadMoreUrl = model.loadMoreUrl;
        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return;
    },
    async postsMyIdeas(): Promise<ResponsePostsIdeaModel | undefined> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.post.getIdeas(IdeaTypeEnum.My);

      if (response.statusCode === 200) {
        const model = response as ResponsePostsModel;
        this.postsIds.ideas.my.ids = model.data.map((n) => n.id);
        this.data = mergeById(this.data, model.data);
        this.postsIds.ideas.my.loadMoreUrl = model.loadMoreUrl;
        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return;
    },
    async postsFromTagId(tagId: number): Promise<boolean> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.post.getPostsFromTagId(tagId);

      if (response.statusCode === 200) {
        const model = response as ResponsePostsModel;
        const index = this.postsIds.tags.findIndex((n) => n.tagId === tagId);

        this.data = mergeById(this.data, model.data);

        if (~index) {
          this.postsIds.tags[index].ids = model.data.map((n) => n.id);
          this.postsIds.tags[index].loadMoreUrl = model.loadMoreUrl;
        } else {
          this.postsIds.tags.push({
            tagId: tagId,
            ids: model.data.map((n) => n.id),
            loadMoreUrl: model.loadMoreUrl,
          });
        }

        this.isLoading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return false;
    },
    async postsFromWikiId(wikiId: number): Promise<boolean> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.post.getPostsFromWikiId(wikiId);

      if (response.statusCode === 200) {
        const model = response as ResponsePostsModel;

        const index = this.postsIds.wikis.findIndex((n) => n.wikiId === wikiId);

        this.data = mergeById(this.data, model.data);

        if (~index) {
          this.postsIds.wikis[index].ids = model.data.map((n) => n.id);
          this.postsIds.wikis[index].loadMoreUrl = model.loadMoreUrl;
        } else {
          this.postsIds.wikis.push({
            wikiId: wikiId,
            ids: model.data.map((n) => n.id),
            loadMoreUrl: model.loadMoreUrl,
          });
        }

        this.isLoading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return false;
    },
    async postsFromFileId(fileId: number): Promise<boolean> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.post.getPostsFromFileId(fileId);

      if (response.statusCode === 200) {
        const model = response as ResponsePostsModel;

        const index = this.postsIds.files.findIndex((n) => n.fileId === fileId);

        this.data = mergeById(this.data, model.data);

        if (~index) {
          this.postsIds.files[index].ids = model.data.map((n) => n.id);
          this.postsIds.files[index].loadMoreUrl = model.loadMoreUrl;
        } else {
          this.postsIds.files.push({
            fileId: fileId,
            ids: model.data.map((n) => n.id),
            loadMoreUrl: model.loadMoreUrl,
          });
        }

        this.isLoading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return false;
    },
    async postShareToGroup(shareData: RequestStandardPostShareModel): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.postShareToGroup(shareData);

      if (response.statusCode === 200) {
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async postShareAsMessage(shareData: RequestStandardPostShareModel): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.postShareAsMessage(shareData);

      if (response.statusCode === 200) {
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async postCreate(postData: RequestStandardPostCreateModel): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.postCreate(postData);

      if (response.statusCode === 200) {
        const model = response as ResponsePostModel;
        this.addNewPost(model.data);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async postPlannedCreate(postData: RequestStandardPostCreateModel): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.postPlannedCreate(postData);

      if (response.statusCode === 200) {
        const model = response as ResponsePlannedPostModel;
        this.addNewPost(model.data);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async postPlannedForce(postId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.postPlannedForce(postId);

      if (response.statusCode === 200) {
        const index = this.postsIds.feed.planned.ids.findIndex((id) => id === postId);
        if (~index) {
          this.postsIds.feed.planned.ids.splice(index, 1);
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async postEdit(postId: number, postData: RequestPostEditModel): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.postEdit(postId, postData);

      if (response.statusCode === 200) {
        const model = response as ResponsePostModel;
        const index = this.data.findIndex((n) => n.id === postId);
        this.data[index] = model.data;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async postPlannedUpdate(postId: number, postData: RequestPostEditModel): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.postPlannedUpdate(postId, postData);

      if (response.statusCode === 200) {
        const model = response as ResponsePostModel;
        const index = this.data.findIndex((n) => n.id === postId);
        this.data[index] = model.data;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async postDelete(postId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.postDelete(postId);
      if (response.statusCode === 200) {
        remove(this.data, (n) => n.id === postId);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async postPlannedDelete(postId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.postPlannedDelete(postId);
      if (response.statusCode === 200) {
        remove(this.data, (n) => n.id === postId);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async eventCreate(eventData: RequestEventCreateModel): Promise<boolean> {
      this.errors = [];
      const response = await $api.event.eventCreate(eventData);

      if (response.statusCode === 200) {
        const model = response as ResponseEventModel;
        this.addNewPost(model.data);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async eventPlannedCreate(eventData: RequestEventCreateModel): Promise<boolean> {
      this.errors = [];
      const response = await $api.event.eventPlannedCreate(eventData);

      if (response.statusCode === 200) {
        const model = response as ResponseEventModel;
        this.addNewPost(model.data);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async eventPlannedForce(eventId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.event.eventPlannedForce(eventId);

      if (response.statusCode === 200) {
        const index = this.postsIds.feed.planned.ids.findIndex((id) => id === eventId);
        if (~index) {
          this.postsIds.feed.planned.ids.splice(index, 1);
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async eventPlannedDelete(postId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.event.eventPlannedDelete(postId);
      if (response.statusCode === 200) {
        remove(this.data, (n) => n.id === postId);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async eventChangeAnswer(data: EventChangeAnswerModel, parentPostId: number | null): Promise<boolean> {
      const eventStore = useEventStore();
      this.errors = [];
      const response = await $api.event.eventChangeAnswer(data);

      if (response.statusCode === 200) {
        if (parentPostId !== null) {
          const parentPostIndex = this.data.findIndex((n) => n.id === parentPostId);
          if (~parentPostIndex) {
            const sharedMessageEventData = this.data[parentPostIndex].sharedMessages.data[0]
              .eventData as PostEventDataModel;
            if (sharedMessageEventData) {
              sharedMessageEventData.attendingsCount =
                sharedMessageEventData.answer === FeedEventAnswerEnum.None
                  ? sharedMessageEventData.attendingsCount + 1
                  : sharedMessageEventData.attendingsCount;
              sharedMessageEventData.answer = data.attending;
            }
          }
        }

        const index = this.data.findIndex((n) => n.id === data.id);
        if (~index) {
          const eventData = this.data[index].eventData;
          if (eventData) {
            eventData.attendingsCount =
              eventData.answer === FeedEventAnswerEnum.None ? eventData.attendingsCount + 1 : eventData.attendingsCount;
            eventData.answer = data.attending;
          }
        }

        const eventIndex = eventStore.data.findIndex((n) => n.id === data.id);

        if (~eventIndex) {
          const eventData = eventStore.data[eventIndex].eventData;
          if (eventData != null) {
            eventData.attendingsCount =
              eventData.answer === FeedEventAnswerEnum.None ? eventData.attendingsCount + 1 : eventData.attendingsCount;
            eventData.answer = data.attending;
          } else {
            return false;
          }
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async eventSubscriptionChange(eventId: number, answer: boolean, parentPostId: number | null): Promise<boolean> {
      const eventStore = useEventStore();
      this.errors = [];
      const response = answer ? await $api.event.eventSubscribe(eventId) : await $api.event.eventUnsubscribe(eventId);

      if (response.statusCode === 200) {
        if (parentPostId !== null) {
          const parentPostIndex = this.data.findIndex((n) => n.id === parentPostId);
          if (~parentPostIndex) {
            const sharedMessageEventData = this.data[parentPostIndex].sharedMessages.data[0]
              .eventData as PostEventDataModel;
            if (sharedMessageEventData) {
              sharedMessageEventData.blockerSubscribers = answer
                ? sharedMessageEventData.blockerSubscribers + 1
                : sharedMessageEventData.blockerSubscribers - 1;
              sharedMessageEventData.currentUserSubscribed = answer;
            }
          }
        }

        const index = this.data.findIndex((n) => n.id === eventId);
        if (~index) {
          const eventData = this.data[index].eventData;
          if (eventData) {
            eventData.blockerSubscribers = answer ? eventData.blockerSubscribers + 1 : eventData.blockerSubscribers - 1;
            eventData.currentUserSubscribed = answer;
          }
        }

        const eventIndex = eventStore.data.findIndex((n) => n.id === eventId);
        if (~eventIndex) {
          const eventData = eventStore.data[eventIndex].eventData;

          if (!eventData) {
            return false;
          }
          eventData.blockerSubscribers = answer ? eventData.blockerSubscribers + 1 : eventData.blockerSubscribers - 1;
          eventData.currentUserSubscribed = answer;
        }

        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async eventEdit(eventId: number, eventData: RequestEventEditModel): Promise<boolean> {
      const eventStore = useEventStore();
      this.errors = [];
      const response = await $api.event.eventEdit(eventId, eventData);

      if (response.statusCode === 200) {
        const model = response as ResponseEventModel;
        const index = this.data.findIndex((n) => n.id === eventId);
        if (~index) {
          this.data[index].eventData = model.data.eventData;
          this.data[index].bodyHtml = eventData.text;
        }

        const eventIndex = eventStore.data.findIndex((n) => n.id === eventId);

        if (~eventIndex) {
          eventStore.data[eventIndex].eventData = model.data.eventData;
        }

        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async eventPlannedUpdate(eventId: number, eventData: RequestEventCreateModel): Promise<boolean> {
      const eventStore = useEventStore();
      this.errors = [];
      const response = await $api.event.eventPlannedUpdate(eventId, eventData);

      if (response.statusCode === 200) {
        const model = response as ResponseEventModel;
        const index = this.data.findIndex((n) => n.id === eventId);
        if (~index) {
          this.data[index].eventData = model.data.eventData;
          this.data[index].bodyHtml = eventData.text;
        }

        const eventIndex = eventStore.data.findIndex((n) => n.id === eventId);

        if (~eventIndex) {
          eventStore.data[eventIndex].eventData = model.data.eventData;
        }

        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async ideaCreate(ideaData: RequestIdeaCreateModel): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.ideaCreate(ideaData);

      if (response.statusCode === 200) {
        const model = response as ResponsePostModel;
        this.addNewPost(model.data);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async ideaPlannedCreate(ideaData: RequestIdeaCreateModel): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.ideaPlannedCreate(ideaData);

      if (response.statusCode === 200) {
        const model = response as ResponsePostModel;
        this.addNewPost(model.data);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async ideaUpdateStatus(postId: number, statusData: RequestIdeaUpdateStatusModel): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.ideaUpdateStatus(postId, statusData);

      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.id === postId);
        if (~index) {
          (this.data[index].ideaData as PostIdeaModel).statusText = statusData.statusText;
          (this.data[index].ideaData as PostIdeaModel).status = statusData.statusId;
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async ideaPlannedForce(ideaId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.ideaPlannedForce(ideaId);

      if (response.statusCode === 200) {
        const index = this.postsIds.feed.planned.ids.findIndex((id) => id === ideaId);
        if (~index) {
          this.postsIds.feed.planned.ids.splice(index, 1);
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async ideaPlannedDelete(postId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.ideaPlannedDelete(postId);
      if (response.statusCode === 200) {
        remove(this.data, (n) => n.id === postId);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async ideaEdit(ideaId: number, ideaData: RequestIdeaEditModel): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.ideaEdit(ideaId, ideaData);

      if (response.statusCode === 200) {
        const model = response as ResponsePostModel;
        const index = this.data.findIndex((n) => n.id === ideaId);
        this.data[index].ideaData = model.data.ideaData;

        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async ideaPlannedUpdate(ideaId: number, ideaData: RequestIdeaCreateModel): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.ideaPlannedUpdate(ideaId, ideaData);

      if (response.statusCode === 200) {
        const model = response as ResponsePostModel;
        const index = this.data.findIndex((n) => n.id === ideaId);
        this.data[index].ideaData = model.data.ideaData;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async badgeCreate(badgeData: RequestBadgeCreateModel): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.badgeCreate(badgeData);

      if (response.statusCode === 200) {
        const model = response as ResponsePostModel;
        this.addNewPost(model.data);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async pollCreate(pollData: RequestPollCreateModel): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.pollCreate(pollData);

      if (response.statusCode === 200) {
        const model = response as ResponsePollModel;
        this.addNewPost(model.data);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async pollPlannedCreate(pollData: RequestPollCreateModel): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.pollPlannedCreate(pollData);

      if (response.statusCode === 200) {
        const model = response as ResponsePlannedPollModel;
        this.addNewPost(model.data);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async pollPlannedForce(pollId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.pollPlannedForce(pollId);

      if (response.statusCode === 200) {
        const index = this.postsIds.feed.planned.ids.findIndex((id) => id === pollId);
        if (~index) {
          this.postsIds.feed.planned.ids.splice(index, 1);
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async pollPlannedDelete(postId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.pollPlannedDelete(postId);
      if (response.statusCode === 200) {
        remove(this.data, (n) => n.id === postId);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async pollEdit(pollId: number, pollData: RequestPollEditModel): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.pollEdit(pollId, pollData);

      if (response.statusCode === 200) {
        const model = response as ResponsePollModel;
        const index = this.data.findIndex((n) => n.id === pollId);
        this.data[index].pollData = model.data.pollData;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async pollPlannedUpdate(pollId: number, pollData: RequestPollEditModel): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.pollPlannedUpdate(pollId, pollData);

      if (response.statusCode === 200) {
        const model = response as ResponsePlannedPollModel;
        const index = this.data.findIndex((n) => n.id === pollId);
        this.data[index].pollData = model.data.pollData;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async announcementsWithoutRead(): Promise<void> {
      this.errors = [];
      this.postsIds.announcements.ids = [];
      this.isLoading = true;
      const response = await $api.announcement.getAnnouncementsAll();

      if (response.statusCode === 200) {
        const model = response as ResponseAnnouncementModel;
        const data = model.data.filter((n) => n.isAnnouncementRead === false);
        this.postsIds.announcements.ids = data.map((n) => n.id);
        this.data = mergeById(this.data, model.data);
        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
    },
    async announcementCreate(announcementData: RequestAnnouncementCreateModel): Promise<boolean> {
      this.errors = [];
      const response = await $api.announcement.announcementCreate(announcementData);

      if (response.statusCode === 200) {
        const model = response as ResponseAnnouncementCreateModel;
        model.data.isAnnouncementRead = false;
        this.addNewPost(model.data);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return false;
    },
    async announcementPlannedCreate(announcementData: RequestAnnouncementCreateModel): Promise<boolean> {
      this.errors = [];
      const response = await $api.announcement.announcementPlannedCreate(announcementData);

      if (response.statusCode === 200) {
        const model = response as ResponseAnnouncementCreateModel;
        model.data.isAnnouncementRead = false;
        this.addNewPost(model.data);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return false;
    },
    async announcementPlannedForce(announcementId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.announcement.announcementPlannedForce(announcementId);

      if (response.statusCode === 200) {
        const index = this.postsIds.feed.planned.ids.findIndex((id) => id === announcementId);
        if (~index) {
          this.postsIds.feed.planned.ids.splice(index, 1);
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async announcementPlannedDelete(postId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.announcement.announcementPlannedDelete(postId);
      if (response.statusCode === 200) {
        remove(this.data, (n) => n.id === postId);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async announcementEdit(announcementId: number, announcementData: RequestAnnouncementEditModel): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.announcementEdit(announcementId, announcementData);

      if (response.statusCode === 200) {
        const model = response as ResponseAnnouncementCreateModel;
        const index = this.data.findIndex((n) => n.id === announcementId);
        this.data[index] = model.data;

        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async announcementPlannedUpdate(
      announcementId: number,
      announcementData: RequestAnnouncementCreateModel
    ): Promise<boolean> {
      this.errors = [];
      const response = await $api.announcement.announcementPlannedUpdate(announcementId, announcementData);

      if (response.statusCode === 200) {
        const model = response as ResponsePostModel;
        const index = this.data.findIndex((n) => n.id === announcementId);
        this.data[index] = model.data;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async announcementRead(postId: number): Promise<void> {
      this.errors = [];
      const response = await $api.announcement.announcementRead(postId);

      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.id === postId);
        if (~index) {
          this.data[index].isAnnouncementRead = true;
        }
        remove(this.postsIds.announcements.ids, (n) => postId === n);
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return;
    },
    async switchPlannedDelete(post: PostModel): Promise<boolean> {
      switch (post.messageType) {
        case FeedTypeEnum.Text:
          return await this.postPlannedDelete(post.id);
        case FeedTypeEnum.Announcement:
          return await this.announcementPlannedDelete(post.id);
        case FeedTypeEnum.Event:
          return await this.eventPlannedDelete(post.id);
        case FeedTypeEnum.Poll:
          return await this.pollPlannedDelete(post.id);
        case FeedTypeEnum.Idea:
          return await this.ideaPlannedDelete(post.id);
        default:
          return await this.postPlannedDelete(post.id);
      }
    },
    async switchPlannedForce(post: PostModel) {
      switch (post.messageType) {
        case FeedTypeEnum.Text:
          await this.postPlannedForce(post.id);
          break;
        case FeedTypeEnum.Announcement:
          await this.announcementPlannedForce(post.id);
          break;
        case FeedTypeEnum.Event:
          await this.eventPlannedForce(post.id);
          break;
        case FeedTypeEnum.Poll:
          await this.pollPlannedForce(post.id);
          break;
        case FeedTypeEnum.Idea:
          await this.ideaPlannedForce(post.id);
          break;
        default:
          await this.postPlannedForce(post.id);
          break;
      }
    },
    async switchPlannedUpdate(post: PostModel) {
      switch (post.messageType) {
        case FeedTypeEnum.Text:
          await this.postPlannedUpdate(post.id, {
            text: post.bodyHtml,
            plannedDate: post.plannedPostData?.plannedDate || null,
            fileTempIds: post.plannedPostData?.tempFiles.map((f: FileModel) => f.key),
            fileExistIds: post.attachedFiles.data.map((f: FileModel) => f.key),
            wikiIds: post.attachedWikis.data.map((f: WikiModel) => f.id),
            toGroupId: post.group.id,
          });
          break;
        case FeedTypeEnum.Announcement:
          await this.announcementPlannedUpdate(post.id, {
            text: post.bodyHtml,
            plannedDate: post.plannedPostData?.plannedDate || null,
            title: post.title,
            isRequiredToRead: post.isRequiredToRead,
            // imageId: data.postPhotoId,
            groupId: post.group.id,
            fileTempIds: post.plannedPostData?.tempFiles.map((f: FileModel) => f.key),
            fileExistIds: post.attachedFiles.data.map((f: FileModel) => f.key),
            wikiIds: post.attachedWikis.data.map((f: WikiModel) => f.id),
          });
          break;
        case FeedTypeEnum.Event:
          await this.eventPlannedUpdate(post.id, {
            plannedDate: post.plannedPostData?.plannedDate
              ? DateHelper.formatDateWithTime(post.plannedPostData?.plannedDate)
              : null,
            title: post.eventData?.title || '',
            text: post.bodyHtml,
            date: post.eventData?.datetime ? DateHelper.formatDateWithTime(post.eventData?.datetime) : '',
            duration: post.eventData?.durationMinutes || 0,
            place: post.eventData?.location || '',
            userIds: [],
            groupIds: [],
            userEmails: [],
            toGroupId: post.group.id,
            endDate:
              post.eventData?.datetime && post.eventData?.durationMinutes
                ? DateHelper.formatDateWithTime(
                    DateHelper.addMinutesToDateInISO(post.eventData?.datetime, post.eventData?.durationMinutes)
                  )
                : DateHelper.formatDateWithTime(
                    DateHelper.addMinutesToDate(
                      DateHelper.addMinutesToRoundedDateInISO(DateHelper.getIsoNow(), 15),
                      60
                    ).toISOString()
                  ),
            isBlocker: post.eventData?.isBlocker || false,
          });
          break;
        case FeedTypeEnum.Poll:
          await this.pollPlannedUpdate(post.id, {
            text: post.bodyHtml,
            limitDate: post.pollData?.timeLimit
              ? DateHelper.formatDateWithTime(new Date(DateHelper.getUtcZero(post.pollData.timeLimit)).toISOString())
              : '',
            questionOptions: post.pollData?.options.map((option) => option.text.trim()) || [''],
            plannedDate: post.plannedPostData?.plannedDate || null,
            fileTempIds: post.plannedPostData?.tempFiles.map((f: FileModel) => f.key),
            toGroupId: post.group.id,
            // fileExistIds: post.attachedFiles.data.map((f: FileModel) => f.key),
          });
          break;
        case FeedTypeEnum.Idea:
          await this.ideaPlannedUpdate(post.id, {
            title: post.ideaData?.title || '',
            plannedDate: post.plannedPostData?.plannedDate || null,
            problem: post.ideaData?.problem || '',
            solution: post.ideaData?.solution || '',
            results: post.ideaData?.results || '',
            // toGroupID: data.groupToPost,
            fileTempIds: post.plannedPostData?.tempFiles.map((f: FileModel) => f.key),
            fileExistIds: post.attachedFiles.data.map((f: FileModel) => f.key),
            wikiIds: post.attachedWikis.data.map((f: WikiModel) => f.id),
            toGroupID: post.group.id,
            // fileIds: [],
          });
          break;
        default:
          await this.postPlannedUpdate(post.id, {
            text: post.bodyHtml,
            plannedDate: post.plannedPostData?.plannedDate || null,
            // userIds: props.updateData.userIds,
            // groupIds: props.updateData.groupIds,
            // userEmails: props.updateData.userEmails,
            toGroupId: post.group.id,
            fileTempIds: post.plannedPostData?.tempFiles.map((f: FileModel) => f.key),
            fileExistIds: post.attachedFiles.data.map((f: FileModel) => f.key),
            wikiIds: post.attachedWikis.data.map((f: WikiModel) => f.id),
          });
          break;
      }
    },
    upsert(post: PostModel) {
      const index = this.data.findIndex((e: PostModel) => e.id === post.id);
      if (~index) {
        this.data[index] = cloneDeep(post);
      } else {
        this.data = [...this.data, post];
      }

      this.$patch({
        errors: [],
      });
    },
    addNewPost(post: PostModel) {
      const appStore = useAppStore();
      const userStore = useUserStore();
      const currentUserId = userStore.current?.id ?? 0;

      const addPlannedPost = (post: PostModel) => {
        this.postsIds.feed.planned.ids.unshift(post.id);
        this.data.unshift(post);
      };

      const addPostIdByFeedType = (id: number) => {
        switch (appStore.feedType) {
          case FeedFilterTypeEnum.Recommended: {
            this.postsIds.feed.recommended.ids.unshift(id);
            break;
          }
          case FeedFilterTypeEnum.AllPublic: {
            this.postsIds.feed.all.ids.unshift(id);
            break;
          }
          case FeedFilterTypeEnum.Following: {
            this.postsIds.feed.following.ids.unshift(id);
            break;
          }
          case FeedFilterTypeEnum.MyBookmarks: {
            this.postsIds.feed.bookmarks.ids.unshift(id);
            break;
          }
          case FeedFilterTypeEnum.AllMy: {
            const userStore = useUserStore();
            const currentUserId = userStore.current?.id ?? 0;
            const index = this.postsIds.users.findIndex((n) => n.userId === currentUserId);
            if (~index) {
              this.postsIds.users[index].ids.unshift(id);
            }
            break;
          }
          case FeedFilterTypeEnum.Planned: {
            //In case of creating common post from Planned posts feed
            if (post.plannedPostData === null) {
              this.postsIds.feed.all.ids.unshift(id);
            }
            break;
          }
        }
        this.data.unshift(post);
      };

      const addPostIdByPostsMode = (id: number) => {
        switch (this.postsMode) {
          case PostsModeEnum.ByUser: {
            const index = this.postsIds.users.findIndex((n) => n.userId === this.postsUserId);
            if (~index) {
              this.postsIds.users[index].ids.unshift(id);
            }
            break;
          }

          case PostsModeEnum.ByGroup: {
            const index = this.postsIds.groups.findIndex((n) => n.groupId === this.postsGroupId);
            if (~index) {
              this.postsIds.groups[index].ids.unshift(id);
            }
            break;
          }

          case PostsModeEnum.Idea: {
            switch (appStore.ideaType) {
              case IdeaTypeEnum.New: {
                this.postsIds.ideas.new.ids.unshift(id);
                break;
              }
              case IdeaTypeEnum.InProgress: {
                this.postsIds.ideas.inProgress.ids.unshift(id);
                break;
              }
              case IdeaTypeEnum.Archived: {
                this.postsIds.ideas.archived.ids.unshift(id);
                break;
              }
              case IdeaTypeEnum.Realized: {
                this.postsIds.ideas.realized.ids.unshift(id);
                break;
              }
              case IdeaTypeEnum.MostLiked: {
                this.postsIds.ideas.mostLiked.ids.unshift(id);
                break;
              }
              case IdeaTypeEnum.My: {
                this.postsIds.ideas.my.ids.unshift(id);
                break;
              }

              default:
                break;
            }
          }
        }
        this.data.unshift(post);
      };

      if (post.plannedPostData !== null) {
        addPlannedPost(post);
        return;
      }

      if (post.messageType && post.messageType === FeedTypeEnum.Announcement) {
        this.postsIds.announcements.ids.unshift(post.id);
      }

      if (this.postsMode === PostsModeEnum.Feed) {
        if (
          appStore.feedType === FeedFilterTypeEnum.AllPublic ||
          appStore.feedType === FeedFilterTypeEnum.Recommended ||
          (appStore.feedType === FeedFilterTypeEnum.AllMy && post.author.id === currentUserId) ||
          appStore.feedType === FeedFilterTypeEnum.Planned
        ) {
          addPostIdByFeedType(post.id);
        }
      }

      if (this.postsMode === PostsModeEnum.Idea) {
        if (appStore.ideaType === IdeaTypeEnum.New || appStore.ideaType === IdeaTypeEnum.My) {
          addPostIdByPostsMode(post.id);
        }
      }

      if (
        (this.postsMode === PostsModeEnum.ByGroup && post?.group?.id === this.postsGroupId) ||
        (this.postsMode === PostsModeEnum.ByUser && post.author.id === this.postsUserId)
      ) {
        addPostIdByPostsMode(post.id);
      }
    },
    markAsReadLocally(postsIds: number[]) {
      const uniqIds = uniq(postsIds);
      uniqIds.forEach((post) => {
        const index = this.data.findIndex((n) => n.id === post && n.isRead === false);
        if (~index) {
          this.data[index].isRead = true;
          this.data[index].usersReadCount++;
        }
      });
    },
    async postShareArchiveLink(type: FeedTypeEnum, postId: number, emails: string[]): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.shareArchiveLink(type, postId, emails);

      if (response.statusCode === 200) {
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async postDownload(
      type: FeedTypeEnum,
      documentExtension: DocumentExtensionEnum,
      postId: number
    ): Promise<Blob | ResponseErrorModel> {
      try {
        const response = await $api.file.downloadPost(type, documentExtension, postId);

        return response;
      } catch (e) {
        console.error('Failed to download post', e);
        return e as ResponseErrorModel;
      }
    },
    async postsRequiredForRead(): Promise<PostModel[]> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.post.getRequiredForRead();

      if (response.statusCode === 200) {
        const model = response as ResponsePostsModel;
        this.postsIds.forceToRead.ids = model.data.map((n) => n.id);
        this.data = mergeById(this.data, model.data);
        this.postsIds.forceToRead.loadMoreUrl = model.loadMoreUrl;
        this.isLoading = false;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return [];
    },
    async markRequiredPostAsRead(postId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.post.markAsRead([postId]);

      remove(this.postsIds.forceToRead.ids, (n) => n === postId);

      if (response.statusCode === 200) {
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
  },

  persist: true,
});

const mergeById = (a: PostModel[], b: PostModel[]) => {
  return unionBy(a, b, 'id').map((obj) => {
    const match = find(b, { id: obj.id });
    return match ? Object.assign({}, obj, match) : obj;
  });
};

const updatePostsAfterLoadedMore = (
  feedType: FeedFilterTypeEnum | PostsModeEnum | null,
  ideaType: IdeaTypeEnum | null,
  model: ResponsePostsModel,
  groupId?: number | null | undefined,
  tagId?: number | null | undefined,
  wikiId?: number | null | undefined,
  userId?: number | null | undefined
) => {
  const postStore = usePostStore();

  switch (feedType) {
    case FeedFilterTypeEnum.Recommended:
      {
        postStore.postsIds.feed.recommended.ids = [
          ...postStore.postsIds.feed.recommended.ids,
          ...model.data.map((n) => n.id),
        ];
        postStore.postsIds.feed.recommended.loadMoreUrl = model.loadMoreUrl;
      }
      break;

    case FeedFilterTypeEnum.AllPublic:
      {
        postStore.postsIds.feed.all.ids = [...postStore.postsIds.feed.all.ids, ...model.data.map((n) => n.id)];
        postStore.postsIds.feed.all.loadMoreUrl = model.loadMoreUrl;
      }
      break;

    case FeedFilterTypeEnum.Following:
      {
        postStore.postsIds.feed.following.ids = [
          ...postStore.postsIds.feed.following.ids,
          ...model.data.map((n) => n.id),
        ];
        postStore.postsIds.feed.following.loadMoreUrl = model.loadMoreUrl;
      }
      break;

    case FeedFilterTypeEnum.MyBookmarks:
      {
        postStore.postsIds.feed.bookmarks.ids = [
          ...postStore.postsIds.feed.bookmarks.ids,
          ...model.data.map((n) => n.id),
        ];
        postStore.postsIds.feed.bookmarks.loadMoreUrl = model.loadMoreUrl;
      }
      break;

    case FeedFilterTypeEnum.AllMy:
      {
        const userStore = useUserStore();
        const currentUserId = userStore.current?.id ?? 0;
        const index = postStore.postsIds.users.findIndex((n) => n.userId === currentUserId);
        if (~index) {
          postStore.postsIds.users[index].ids = [
            ...postStore.postsIds.users[index].ids,
            ...model.data.map((n) => n.id),
          ];
          postStore.postsIds.users[index].loadMoreUrl = model.loadMoreUrl;
        }
      }
      break;

    case FeedFilterTypeEnum.Polls:
      {
        postStore.postsIds.polls.ids = [...postStore.postsIds.polls.ids, ...model.data.map((n) => n.id)];
        postStore.postsIds.polls.loadMoreUrl = model.loadMoreUrl;
      }
      break;

    case FeedFilterTypeEnum.ByGroup:
      {
        const index = postStore.postsIds.groups.findIndex((n) => n.groupId === groupId);
        if (~index) {
          postStore.postsIds.groups[index].ids = [
            ...postStore.postsIds.groups[index].ids,
            ...model.data.map((n) => n.id),
          ];
          postStore.postsIds.groups[index].loadMoreUrl = model.loadMoreUrl;
        }
      }
      break;

    case FeedFilterTypeEnum.ByUser:
      {
        const index = postStore.postsIds.users.findIndex((n) => n.userId === userId);
        if (~index) {
          postStore.postsIds.users[index].ids = [
            ...postStore.postsIds.users[index].ids,
            ...model.data.map((n) => n.id),
          ];
          postStore.postsIds.users[index].loadMoreUrl = model.loadMoreUrl;
        }
      }
      break;

    case FeedFilterTypeEnum.ByTag:
      {
        const index = postStore.postsIds.tags.findIndex((n) => n.tagId === tagId);
        if (~index) {
          postStore.postsIds.tags[index].ids = [...postStore.postsIds.tags[index].ids, ...model.data.map((n) => n.id)];
          postStore.postsIds.tags[index].loadMoreUrl = model.loadMoreUrl;
        }
      }
      break;

    case FeedFilterTypeEnum.ByWiki:
      {
        const index = postStore.postsIds.wikis.findIndex((n) => n.wikiId === wikiId);
        if (~index) {
          postStore.postsIds.wikis[index].ids = [
            ...postStore.postsIds.wikis[index].ids,
            ...model.data.map((n) => n.id),
          ];
          postStore.postsIds.wikis[index].loadMoreUrl = model.loadMoreUrl;
        }
      }
      break;

    case FeedFilterTypeEnum.ByFile:
      {
        const index = postStore.postsIds.wikis.findIndex((n) => n.wikiId === wikiId);
        if (~index) {
          postStore.postsIds.wikis[index].ids = [
            ...postStore.postsIds.wikis[index].ids,
            ...model.data.map((n) => n.id),
          ];
          postStore.postsIds.wikis[index].loadMoreUrl = model.loadMoreUrl;
        }
      }
      break;
  }

  if (postStore.postsMode === PostsModeEnum.Idea) {
    switch (ideaType) {
      case IdeaTypeEnum.New:
        {
          postStore.postsIds.ideas.new.ids = [...postStore.postsIds.ideas.new.ids, ...model.data.map((n) => n.id)];
          postStore.postsIds.ideas.new.loadMoreUrl = model.loadMoreUrl;
        }
        break;

      case IdeaTypeEnum.InProgress:
        {
          postStore.postsIds.ideas.inProgress.ids = [
            ...postStore.postsIds.ideas.inProgress.ids,
            ...model.data.map((n) => n.id),
          ];
          postStore.postsIds.ideas.inProgress.loadMoreUrl = model.loadMoreUrl;
        }
        break;

      case IdeaTypeEnum.Realized:
        {
          postStore.postsIds.ideas.realized.ids = [
            ...postStore.postsIds.ideas.realized.ids,
            ...model.data.map((n) => n.id),
          ];
          postStore.postsIds.ideas.realized.loadMoreUrl = model.loadMoreUrl;
        }
        break;

      case IdeaTypeEnum.Archived:
        {
          postStore.postsIds.ideas.archived.ids = [
            ...postStore.postsIds.ideas.archived.ids,
            ...model.data.map((n) => n.id),
          ];
          postStore.postsIds.ideas.archived.loadMoreUrl = model.loadMoreUrl;
        }
        break;

      case IdeaTypeEnum.MostLiked:
        {
          postStore.postsIds.ideas.mostLiked.ids = [
            ...postStore.postsIds.ideas.mostLiked.ids,
            ...model.data.map((n) => n.id),
          ];
          postStore.postsIds.ideas.mostLiked.loadMoreUrl = model.loadMoreUrl;
        }
        break;

      case IdeaTypeEnum.My:
        {
          postStore.postsIds.ideas.my.ids = [...postStore.postsIds.ideas.my.ids, ...model.data.map((n) => n.id)];
          postStore.postsIds.ideas.my.loadMoreUrl = model.loadMoreUrl;
        }
        break;
    }
  }
};
