<template>
  <div class="link" @click.stop="goToLink(linkPreviewData.url)">
    <div class="link-image">
      <ion-img v-if="linkPreviewData.imageUrl" :src="linkPreviewData.imageUrl" />
      <icons-provider
        v-else
        slot="icon-only"
        :icon-props="{ width: '24', height: '24', fill: 'var(--ion-color-medium)' }"
        :name="AppIconsEnum.Link"
      />
    </div>
    <div class="link-text">
      <ion-text class="title">{{ linkPreviewData.title }}</ion-text>
      <ion-text class="url">{{
        linkPreviewData.url.length > urlLength
          ? linkPreviewData.url.slice(0, urlLength).trim() + '...'
          : linkPreviewData.url
      }}</ion-text>
      <ion-text v-if="linkPreviewData.description" class="description">{{ linkPreviewData.description }}</ion-text>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IonText, IonImg } from '@ionic/vue';
import type { ComputedRef, PropType } from 'vue';
import { computed } from 'vue';

import { IconsProvider } from '@/components';
import { AppIconsEnum } from '@/enums';
import type { LinkApiViewModel } from '@/types';

const props = defineProps({
  linkData: {
    type: Object as PropType<LinkApiViewModel>,
    required: true,
  },
});

const urlLength = 200;

const linkPreviewData: ComputedRef<LinkApiViewModel> = computed(() => props.linkData);
const goToLink = (link: string) => {
  window.open(link);
};
</script>
<style scoped lang="scss">
.link {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0.5rem;
  border: 1px solid var(--ion-color-light-custom);
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  .link-image {
    ion-img::part(image) {
      border-radius: 4px;
      object-fit: cover;
      max-height: 8rem;
    }
  }

  .link-text {
    .title {
      display: block;
      font-weight: bold;
    }

    .url {
      color: var(--ion-color-medium);
      word-break: break-word;
      overflow-wrap: anywhere;
      white-space: normal;
      overflow: hidden;
    }

    .description {
      color: var(--ion-color-medium);
      margin-top: 0.5rem;
      font-size: 0.8rem;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
</style>
