<template>
  <docs-item-view
    :id="wiki.id"
    :view-type="viewType"
    :document-type="DocumentTypeEnum.Wiki"
    :name="wikiName"
    :created-at="wiki.createdAt"
    :author="wikiAuthor"
    :edited-at="wiki.editedAt"
    :editor="wikiEditor"
    :group="wikiGroupTitle"
    :icon="documentTextOutline"
    :is-official="wiki.isOfficial"
    @onMenuOpen="openWikiMenu"
    @onItemClick="openWiki"
  />
</template>

<script lang="ts" setup>
import { documentTextOutline } from 'ionicons/icons';
import type { ComputedRef, PropType } from 'vue';
import { computed } from 'vue';

import { DocsItemView } from '@/components';
import { DocumentTypeEnum } from '@/enums';
import type { DocsViewTypeEnum } from '@/enums';
import { openWikiActionsMenu } from '@/helpers';
import { useI18n } from '@/i18n';
import { useDocStore } from '@/store';
import type { DocModel, WikiModel } from '@/types';

const props = defineProps({
  wiki: {
    type: Object as PropType<WikiModel>,
    required: true,
  },
  viewType: {
    type: String as PropType<DocsViewTypeEnum>,
    required: true,
  },
});

const emit = defineEmits(['onWikiOpen', 'onWikiPreview']);

//#region Variables
const docStore = useDocStore();

const { t } = useI18n();

const wikiName: ComputedRef<string> = computed(() => props.wiki.name);
const isAttachment: ComputedRef<boolean> = computed(() => docStore.isAttachmentModal);
const selectedDocs: ComputedRef<DocModel[]> = computed(() => docStore.selectedDocs);
const wikiAuthor: ComputedRef<string> = computed(() => (props.wiki.author ? props.wiki.author?.fullName : ''));
const wikiEditor: ComputedRef<string> = computed(() => (props.wiki.modifier ? props.wiki.modifier?.fullName : ''));
const wikiGroupTitle: ComputedRef<string> = computed(() =>
  props.wiki.group ? props.wiki.group.title : t('network.allNetwork')
);
//#endregion

//#region Methods
const openWiki = async (throughMenu?: boolean) => {
  if (isAttachment.value) {
    if (!selectedDocs.value.some((n) => n.data.id === props.wiki.id)) {
      docStore.$patch({
        selectedDocs: [...selectedDocs.value, { documentType: DocumentTypeEnum.Wiki, data: props.wiki }],
      });
    } else {
      docStore.$patch({
        selectedDocs: selectedDocs.value.filter((n) => n.data.id !== props.wiki.id),
      });
    }
  } else {
    if (throughMenu) {
      emit('onWikiPreview', props.wiki.id);
    } else {
      emit('onWikiOpen', props.wiki.id);
    }
  }
};

const openWikiMenu = async (ev: Event) => {
  await openWikiActionsMenu(ev, props.wiki);
  // await useWiki().openActionsMenu(ev, props.wiki);
};
//#endregion
</script>
