import type { IConfig } from '@onlyoffice/document-editor-vue/lib/model/config';
import { defineStore } from 'pinia';

import { $api } from '@/services';
import type {
  FileModel,
  UserShortModel,
  UserGroupShortModel,
  FolderModel,
  OfficeConfigRequest,
  OfficeDocumentCreateRequest,
  ResponseOfficeConfig,
  ResponseOfficeFileCreate,
} from '@/types';

export interface OfficeProps {
  title: string | null;
  publishedBy: UserShortModel | null;
  createdIn: string | null;
  lastEditedBy: UserShortModel | null;
  lastEditedAt: string | null;
  group: UserGroupShortModel | null;
  folder: FolderModel | null;
  type: string | null;
}

export interface OfficeStore {
  config: IConfig | null;
  isLoading: boolean;
  props: OfficeProps;
  onNewTab: boolean;
}

const OFFICE_PROPS_DEFAULT_STATE = () => ({
  title: null,
  publishedBy: null,
  createdIn: null,
  lastEditedBy: null,
  lastEditedAt: null,
  group: null,
  folder: null,
  type: null,
});

export const useOfficeStore = defineStore({
  id: 'office',

  state: (): OfficeStore => ({
    config: null,
    isLoading: false,
    onNewTab: false,
    props: OFFICE_PROPS_DEFAULT_STATE(),
  }),

  getters: {},

  actions: {
    async getConfig(data: OfficeConfigRequest): Promise<IConfig | null> {
      try {
        this.setLoading(true);

        const response = await $api.office.getConfig(data);

        if (response.statusCode === 200) {
          const {
            data: { config, title, publishedBy, createdIn, lastEditedBy, lastEditedAt, group, folder, type },
          } = response as ResponseOfficeConfig;

          if (config) {
            config.editorConfig = config.editorConfig || {};
            config.editorConfig.customization = config.editorConfig.customization || {};
            config.editorConfig.customization.hideRightMenu = true;

            this.config = config;
            this.setProps({
              title,
              publishedBy,
              createdIn,
              lastEditedBy,
              lastEditedAt,
              group,
              folder,
              type,
            });
            return this.config;
          }
        }

        this.config = null;
        return this.config;
      } catch (e) {
        console.error(e);

        this.config = null;
        return this.config;
      }
    },

    async create(payload: OfficeDocumentCreateRequest): Promise<FileModel | null> {
      try {
        const response = await $api.office.create(payload);

        if (response.statusCode === 200) {
          const { data } = response as ResponseOfficeFileCreate;

          return data;
        }

        return null;
      } catch (e) {
        console.error('Failed to perform create', e);
        return null;
      }
    },

    setLoading(state: boolean) {
      this.isLoading = state;
    },

    setProps(props: Partial<OfficeProps> | null) {
      if (!props) {
        this.props = OFFICE_PROPS_DEFAULT_STATE();
      } else {
        this.props = { ...this.props, ...props };
      }
    },
  },
  persist: true,
});
