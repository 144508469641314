import type { ActionSheetButton, OverlayEventDetail } from '@ionic/core';
import { actionSheetController } from '@ionic/vue';

import { isFileGuard, isWikiGuard } from './guards';
import { useTaskManagement } from './useTaskManagementHelper';

import {
  PostTypeActionEnum,
  AppImageChangeMenuEnum,
  TaskManagementTaskModalMenuCategoryEnum,
  TaskManagementTaskModalMenuItemActionEnum,
  FileActionEnum,
  FeedFlagEnum,
  FeedTypeEnum,
  PostMenuActionEnum,
  MessageActionEnum,
  WikiActionEnum,
  CommentActionEnum,
  PostUploadFileEnum,
  DocsMenuActionEnum,
  AppIconsEnum,
  WikiDownloadOptionsEnum,
  WikiCompareModeEnum,
} from '@/enums';
import {
  PostMenuOptionsFlagEnum,
  CalendarCellActionEnum,
  CalendarViewModeEnum,
  EventCalendarSourceEnum,
  PlannedPostActionEnum,
  PostTextActionEnum,
  DocumentTypeEnum,
} from '@/enums';
import {
  calendarShowBackBtn,
  calendarShowEvents,
  getUploadFileMenuItems,
  getCommentContextMenuItems,
  getMessengerContextMenuItems,
  getPostContextMenuItems,
  useFileActions,
  useIcons,
  useWiki,
} from '@/helpers';
import { useI18n } from '@/i18n';
import type {
  AppMenuButtonsModel,
  DocModel,
  PostModel,
  MessageModel,
  TabCategories,
  IconPropsModel,
  MenuItemModel,
} from '@/types';
type ActionSheetInput<T> = MenuItemModel<T> & {
  cssClass?: string;
};

//#region Private methods
/**
 * Unified function to create ActionSheet buttons from an array of objects
 *
 * @note `currentcolor` is set as default fill for icons since ionic will detect platform and set the correct fill color
 * @param items Array of objects conforming to ActionSheetInput
 * @param iconProps Common props to apply to all icons
 * @returns Array of ActionSheetButton objects
 */

const _createActionSheetButtons = async <T = any>(
  items: ActionSheetInput<T>[],
  iconProps: IconPropsModel = { width: '20', height: '20', fill: 'currentcolor' }
): Promise<ActionSheetButton[]> => {
  const buttonsPromises = items.map(async (item) => {
    try {
      let iconUrl: string | undefined = undefined;

      if (item.icon) {
        iconUrl = await useIcons().getIconBlobUrl(item.icon, iconProps);
        !iconUrl && console.warn(`[WARN] Failed to generate Blob URL for icon "${item.icon}"`);
      }

      const button: ActionSheetButton = {
        text: item.title,
        data: item.value,
        icon: iconUrl || undefined,
        disabled: item.disabled || false,
        handler: item.handler,
        cssClass: item.cssClass,
      };

      return button;
    } catch (error) {
      console.error(`[ERROR] Error processing ActionSheet button for "${item.title}":`, error);
      return {
        text: item.title,
        data: item.value,
        disabled: item.disabled || false,
      };
    }
  });

  const buttons = await Promise.all(buttonsPromises);

  return buttons;
};
//#endregion

//#region Common
export const openImageChangeSheet = async (
  actions: AppMenuButtonsModel[]
): Promise<AppImageChangeMenuEnum | undefined> => {
  const buttons = await _createActionSheetButtons(actions);

  const actionSheet = await actionSheetController.create({ buttons });

  await actionSheet.present();

  return actionSheet
    .onDidDismiss()
    .then(async (result: OverlayEventDetail<AppImageChangeMenuEnum | undefined>) => result.data);
};
//#endregion

//#region Feed
export const openPostCreateContextSheet = async (
  categories: TabCategories<PostTypeActionEnum>[]
): Promise<PostTypeActionEnum | undefined> => {
  const actionSheetInputs: ActionSheetInput<PostTypeActionEnum>[] = categories.map((i) => ({
    title: i.title,
    value: i.value,
    icon: i.icon,
  }));

  const buttons = await _createActionSheetButtons(actionSheetInputs);

  const actionSheet = await actionSheetController.create({ buttons });

  await actionSheet.present();

  return actionSheet.onDidDismiss().then((result: OverlayEventDetail<PostTypeActionEnum | undefined>) => result.data);
};

export const openFeedPostTextSheet = async (): Promise<PostTextActionEnum | undefined> => {
  const { t } = useI18n();

  const menuItems = [
    {
      icon: AppIconsEnum.CopyToClipboard,
      disabled: false,
      value: PostTextActionEnum.CopyText,
      title: t('appPopoverMenu.copy.title'),
    },
  ].filter(({ disabled }) => !disabled);

  const buttons = await _createActionSheetButtons(menuItems);

  const actionSheet = await actionSheetController.create({ buttons });

  await actionSheet.present();

  return actionSheet
    .onDidDismiss()
    .then(async (result: OverlayEventDetail<PostTextActionEnum | undefined>) => result.data);
};

export const openPostPlannedSheet = async (): Promise<PlannedPostActionEnum | undefined> => {
  const { t } = useI18n();

  const menuItems = [
    {
      title: t('feed.schedulePost'),
      icon: AppIconsEnum.Timer,
      value: PlannedPostActionEnum.SchedulePost,
      disabled: false,
    },
  ].filter(({ disabled }) => !disabled);

  const buttons = await _createActionSheetButtons(menuItems);

  const actionSheet = await actionSheetController.create({ buttons });

  await actionSheet.present();

  return actionSheet
    .onDidDismiss()
    .then(async (result: OverlayEventDetail<PlannedPostActionEnum | undefined>) => result.data);
};

export const openPostUploadFileSheet = async (groupId: number | null): Promise<PostUploadFileEnum | undefined> => {
  const menuItems = getUploadFileMenuItems(false, groupId);

  const buttons = await _createActionSheetButtons(menuItems);

  const actionSheet = await actionSheetController.create({ buttons });

  await actionSheet.present();

  return actionSheet
    .onDidDismiss()
    .then(async (result: OverlayEventDetail<PostUploadFileEnum | undefined>) => result.data);
};

export const openFeedCommentSheet = async (isEditable: boolean): Promise<CommentActionEnum | undefined> => {
  const menuItems = getCommentContextMenuItems(isEditable);

  const buttons = await _createActionSheetButtons(menuItems);

  const actionSheet = await actionSheetController.create({ buttons });

  await actionSheet.present();

  return actionSheet
    .onDidDismiss()
    .then(async (result: OverlayEventDetail<CommentActionEnum | undefined>) => result.data);
};

export const openPostContextSheet = async (
  postData: PostModel,
  feedFlag: FeedFlagEnum,
  conversationsType?: FeedTypeEnum | string,
  flag: PostMenuOptionsFlagEnum = PostMenuOptionsFlagEnum.All
): Promise<PostMenuActionEnum | undefined> => {
  const menuItems = getPostContextMenuItems(postData, feedFlag, conversationsType, flag);

  const buttons = await _createActionSheetButtons(menuItems);

  const actionSheet = await actionSheetController.create({ buttons });

  await actionSheet.present();

  return actionSheet
    .onDidDismiss()
    .then(async (result: OverlayEventDetail<PostMenuActionEnum | undefined>) => result.data);
};
//#endregion

//#region Calendar
export const openCalendarCellSheet = async (
  activeView: CalendarViewModeEnum,
  isSmall: boolean
): Promise<CalendarCellActionEnum | undefined> => {
  const { t } = useI18n();

  const menuItems = [
    {
      title: t('feed.event.menu.newEvent'),
      value: CalendarCellActionEnum.CreateEvent,
      icon: AppIconsEnum.Pencil,
      disabled: false,
    },
    {
      title: t('feed.event.menu.showEvents'),
      value: CalendarCellActionEnum.ShowEvents,
      icon: AppIconsEnum.Eye,
      disabled: !calendarShowEvents(isSmall, activeView),
    },
    {
      title: t('feed.event.menu.toDate'),
      value: CalendarCellActionEnum.GoInside,
      icon: AppIconsEnum.Calendar,
      disabled: activeView === CalendarViewModeEnum.Day,
    },
    {
      title: t('back'),
      value: CalendarCellActionEnum.GoOutside,
      icon: AppIconsEnum.ArrowLeft,
      disabled: !calendarShowBackBtn(isSmall, activeView),
    },
  ].filter(({ disabled }) => !disabled);

  const buttons = await _createActionSheetButtons(menuItems);

  const actionSheet = await actionSheetController.create({ buttons });

  await actionSheet.present();

  return actionSheet
    .onDidDismiss()
    .then(async (result: OverlayEventDetail<CalendarCellActionEnum | undefined>) => result.data);
};

export const openCalendarSourceSheet = async (): Promise<EventCalendarSourceEnum | undefined> => {
  const { t } = useI18n();

  const menuItems = [
    {
      title: t('feed.event.allEvents'),
      value: EventCalendarSourceEnum.All,
      icon: AppIconsEnum.None,
    },
    {
      title: t('feed.event.myEvents'),
      value: EventCalendarSourceEnum.My,
      icon: AppIconsEnum.None,
    },
    {
      title: t('feed.event.groupEvents'),
      value: EventCalendarSourceEnum.Groups,
      icon: AppIconsEnum.None,
    },
  ];

  const buttons = await _createActionSheetButtons(menuItems);

  const actionSheet = await actionSheetController.create({ buttons });

  await actionSheet.present();

  return actionSheet
    .onDidDismiss()
    .then(async (result: OverlayEventDetail<EventCalendarSourceEnum | undefined>) => result.data);
};
//#endregion

//#region Messenger
export const openMessengerContextSheet = async (
  ev: Event,
  message: MessageModel,
  currentId: number | undefined,
  deleteFlag = false
): Promise<MessageActionEnum | undefined> => {
  const menuItems = getMessengerContextMenuItems(message, currentId, deleteFlag);

  const buttons = await _createActionSheetButtons(menuItems);

  const actionSheet = await actionSheetController.create({ buttons });

  await actionSheet.present();

  return actionSheet
    .onDidDismiss()
    .then(async (result: OverlayEventDetail<MessageActionEnum | undefined>) => result.data);
};
//#endregion

//#region Task Management
export const openTaskManagementTaskMainSheet = async (): Promise<
  TaskManagementTaskModalMenuCategoryEnum | undefined
> => {
  const buttons = await _createActionSheetButtons(useTaskManagement().getTaskMainMenu());

  const actionSheet = await actionSheetController.create({ buttons });

  await actionSheet.present();

  return actionSheet
    .onDidDismiss()
    .then(async (result: OverlayEventDetail<TaskManagementTaskModalMenuCategoryEnum | undefined>) => result.data);
};

export const openTaskManagementTaskActionsSheet = async (
  taskIsClosed: boolean,
  taskIsArchived: boolean,
  taskNotify: boolean | null,
  userIsAssigned: boolean,
  projectId: number
): Promise<TaskManagementTaskModalMenuItemActionEnum | undefined> => {
  const buttons = await _createActionSheetButtons(
    useTaskManagement().getTaskMenuActions(true, taskIsClosed, taskIsArchived, taskNotify, userIsAssigned, projectId)
      .data
  );

  const actionSheet = await actionSheetController.create({ buttons });

  await actionSheet.present();

  return actionSheet
    .onDidDismiss()
    .then(async (result: OverlayEventDetail<TaskManagementTaskModalMenuItemActionEnum | undefined>) => result.data);
};

export const openTaskManagementTaskFeaturesSheet = async (): Promise<
  TaskManagementTaskModalMenuItemActionEnum | undefined
> => {
  const buttons = await _createActionSheetButtons(useTaskManagement().getTaskMenuFeatures().data);

  const actionSheet = await actionSheetController.create({ buttons });

  await actionSheet.present();

  return actionSheet
    .onDidDismiss()
    .then(async (result: OverlayEventDetail<TaskManagementTaskModalMenuItemActionEnum | undefined>) => result.data);
};
//#endregion

//#region Docs
export const openDocBrowserContextSheet = async (
  doc: DocModel
): Promise<FileActionEnum | WikiActionEnum | WikiDownloadOptionsEnum | WikiCompareModeEnum | undefined> => {
  const isWiki: boolean = doc.documentType === DocumentTypeEnum.Wiki;
  const isFile: boolean = doc.documentType === DocumentTypeEnum.File;

  if (isWiki && isWikiGuard(doc.data)) {
    const buttons = await _createActionSheetButtons<WikiActionEnum | WikiDownloadOptionsEnum | WikiCompareModeEnum>(
      useWiki().getActionsMenuItems(doc.data)
    );

    const actionSheet = await actionSheetController.create({ buttons });

    await actionSheet.present();

    return actionSheet
      .onDidDismiss()
      .then(
        async (
          result: OverlayEventDetail<
            FileActionEnum | WikiActionEnum | WikiDownloadOptionsEnum | WikiCompareModeEnum | undefined
          >
        ) => result.data
      );
  }

  if (isFile && isFileGuard(doc.data)) {
    const buttons = await _createActionSheetButtons<FileActionEnum>(useFileActions().getActionsMenuItems(doc));

    const actionSheet = await actionSheetController.create({ buttons });

    await actionSheet.present();

    return actionSheet
      .onDidDismiss()
      .then(
        async (
          result: OverlayEventDetail<
            FileActionEnum | WikiActionEnum | WikiDownloadOptionsEnum | WikiCompareModeEnum | undefined
          >
        ) => result.data
      );
  }

  return undefined;
};

export const openDocBrowserMainSheet = async (groupId: number | null): Promise<DocsMenuActionEnum | undefined> => {
  const menuItems = getUploadFileMenuItems(true, groupId);

  const actionSheetInputs: ActionSheetInput<DocsMenuActionEnum | PostUploadFileEnum | undefined>[] = menuItems.map(
    (i) => ({
      title: i.title,
      value: i.value,
      icon: i.icon,
      cssClass: 'doc-browser-menu-sheet-buttons',
    })
  );

  const buttons = await _createActionSheetButtons(actionSheetInputs);

  const actionSheet = await actionSheetController.create({ buttons });

  await actionSheet.present();

  return actionSheet
    .onDidDismiss()
    .then(async (result: OverlayEventDetail<DocsMenuActionEnum | undefined>) => result.data);
};
//#endregion
