import { format, addMinutes, parse, parseISO } from 'date-fns';

import type { Date16, Date20ISO, Date23UTC } from '@/types';
import { isDate16, isDate20ISO, isDate23UTC } from './guards';
import { toZonedTime } from 'date-fns-tz';

/**
 * @link https://github.com/date-fns/date-fns/blob/6c70ac6d073ebe869e42795f5e71dfecf5abbea0/src/format/index.ts#L37
 *
 * For parsing
 * @use {@link parseDate16}, {@link parseDate23}, {@link parseDate24}.
 *
 * For formatting
 * @use {@link formatDate16}, {@link formatDate23}, {@link formatDate20}.
 *
 * For converting
 * @use
 * - {@link convertStringToIso}
 * - {@link convertDate16ToDate23}
 * - {@link convertDate16ToIso}
 * - {@link convertDate23ToDate16}
 * - {@link convertDate23ToIso}
 * - {@link convertIsoToDate16}
 * - {@link convertIsoToDate23}.
 *
 * For adding @use {@link addMinutesToDate}.
 */

/**
 * @private
 * @readonly
 */
const _DATE16 = 'yyyy-MM-dd HH:mm';

/**
 * @private
 * @readonly
 */
const _DATE23 = 'yyyy-MM-dd HH:mm:ss zzz';

/**
 * @private
 * @readonly
 */
const _DATE24 = "yyyy-MM-dd'T'HH:mm:ss'Z'";

//#region Parsers - String => Date
export function parseDate16(value: Date16): Date {
  if (!isDate16(value)) {
    throw new Error('String is not in the format yyyy-MM-dd HH:mm');
  }
  return parse(value, _DATE16, new Date());
}

export function parseDate23(value: Date23UTC): Date {
  if (!isDate23UTC(value)) {
    throw new Error('String is not in the format yyyy-MM-dd HH:mm:ss zzz');
  }
  return parse(value, _DATE23, new Date());
}

export function parseDate24(value: Date20ISO): Date {
  if (!isDate20ISO(value)) {
    throw new Error('String is not in the format yyyy-MM-ddTHH:mm:ssZ');
  }
  return parse(value, _DATE24, new Date());
}
//#endregion

//#region Formatters - Date => String
export function formatDate16(value: Date): Date16 {
  const formatted = format(value, _DATE16);
  if (!isDate16(formatted)) {
    throw new Error('String is not in the format yyyy-MM-dd HH:mm');
  }
  return formatted as Date16;
}

export function formatDate20(value: Date): Date20ISO {
  const formatted = format(value, _DATE24);
  if (!isDate20ISO(formatted)) {
    throw new Error('String is not in the format yyyy-MM-ddTHH:mm:ssZ');
  }
  return formatted as Date20ISO;
}

export function formatDate23(value: Date): Date23UTC {
  const formatted = format(value, _DATE23);
  if (!isDate23UTC(formatted)) {
    throw new Error('String is not in the format yyyy-MM-dd HH:mm:ss zzz');
  }
  return formatted as Date23UTC;
}
//#endregion

//#region Converters - String => String
/**
 * Takes any string and returns a Date24ISO (ISO 8601) string.
 * 1) Checks if it's already Date24ISO => returns as-is.
 * 2) If Date16 or Date23 => parse => reformat as ISO.
 * 3) Otherwise, tries parseISO(...). If invalid => throw Error.
 */
export function convertStringToIso(value: string): Date20ISO {
  // 1) Already ISO?
  if (isDate20ISO(value)) {
    return value; // no conversion needed
  }

  // 2) Known 16-char or 23-char format?
  if (isDate16(value)) {
    return formatDate20(parseDate16(value));
  }

  // 3) Known 23-char format?
  if (isDate23UTC(value)) {
    return formatDate20(parseDate23(value));
  }

  // 4) Fall back to parseISO(...) for unknown cases
  const parsed = parseISO(value);
  if (isNaN(parsed.getTime())) {
    throw new Error(`Unsupported date format: "${value}"`);
  }

  return formatDate20(parsed);
}

export function convertIsoToLocalTimezone(value: Date20ISO): Date20ISO {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const zonedDate = toZonedTime(value, userTimeZone);
  return formatDate20(zonedDate);
}

// 16 => 20
export function convertDate16ToIso(value: Date16): Date20ISO {
  if (!isDate16(value)) {
    throw new Error('String is not in the format yyyy-MM-dd HH:mm');
  }
  const formatted = format(parseDate16(value), _DATE24);
  if (!isDate20ISO(formatted)) {
    throw new Error('String is not in the format yyyy-MM-ddTHH:mm:ssZ');
  }
  return formatted as Date20ISO;
}

// 16 => 23
export function convertDate16ToDate23(value: Date16): Date23UTC {
  if (!isDate16(value)) {
    throw new Error('String is not in the format yyyy-MM-dd HH:mm');
  }
  const formatted = format(parseDate16(value), _DATE23);
  if (!isDate23UTC(formatted)) {
    throw new Error('String is not in the format yyyy-MM-dd HH:mm:ss zzz');
  }
  return formatted as Date23UTC;
}

// 23 => 16
export function convertDate23ToDate16(value: Date23UTC): Date16 {
  if (!isDate23UTC(value)) {
    throw new Error('String is not in the format yyyy-MM-dd HH:mm:ss zzz');
  }
  const formatted = format(parseDate23(value), _DATE16);
  if (!isDate16(formatted)) {
    throw new Error('String is not in the format yyyy-MM-dd HH:mm');
  }
  return formatted as Date16;
}

// 23 => 20
export function convertDate23ToIso(value: Date23UTC): Date20ISO {
  if (!isDate23UTC(value)) {
    throw new Error('String is not in the format yyyy-MM-dd HH:mm:ss zzz');
  }
  const formatted = format(parseDate23(value), _DATE24);
  if (!isDate20ISO(formatted)) {
    throw new Error('String is not in the format yyyy-MM-ddTHH:mm:ssZ');
  }
  return formatted as Date20ISO;
}

// 20 => 16
export function convertIsoToDate16(value: Date20ISO): Date16 {
  if (!isDate20ISO(value)) {
    throw new Error('String is not in the format yyyy-MM-ddTHH:mm:ssZ');
  }
  const formatted = format(parseDate24(value), _DATE16);
  if (!isDate16(formatted)) {
    throw new Error('String is not in the format yyyy-MM-dd HH:mm');
  }
  return formatted as Date16;
}

// 20 => 23
export function convertIsoToDate23(value: Date20ISO): Date23UTC {
  if (!isDate20ISO(value)) {
    throw new Error('String is not in the format yyyy-MM-ddTHH:mm:ssZ');
  }
  const formatted = format(parseDate24(value), _DATE23);
  if (!isDate23UTC(formatted)) {
    throw new Error('String is not in the format yyyy-MM-dd HH:mm:ss zzz');
  }
  return formatted as Date23UTC;
}
//#endregion

/**
 * Accepts one of three date-string formats, adds minutes,
 * then returns the same date type and format.
 *
 * @param value   The date in one of three formats
 * @param minutes How many minutes to add
 * @returns       The same date format with minutes added
 */
export function addMinutesToDate<T extends Date16 | Date23UTC | Date20ISO>(value: T, minutes: number): T {
  if (isDate16(value)) {
    const date = parseDate16(value);
    const newDate = addMinutes(date, minutes);
    return formatDate16(newDate) as T;
  }

  if (isDate20ISO(value)) {
    const date = parseDate24(value);
    const newDate = addMinutes(date, minutes);
    return formatDate20(newDate) as T;
  }

  if (isDate23UTC(value)) {
    const date = parseDate23(value);
    const newDate = addMinutes(date, minutes);
    return formatDate23(newDate) as T;
  }

  throw new Error('Invalid date format');
}
