import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import type { ErrorEvent } from '@sentry/types';
import { toast } from 'vue-sonner';

import { isAnyMobile } from './helper';
import { ThemeAppEnum } from '@/enums';
import { isNativeMobile, useErrors } from '@/helpers';
import { useAppStore } from '@/store';
import type { ResponseErrorModel } from '@/types';

type IUseToasts = {
  /** @readonly*/
  currentToastId: string | undefined;
  /**
   * Use it to display a toast with / without download error event action.
   *
   * @param message - The message to display
   * @param isSuccess - Whether the toast is successful or not
   * @param identifier - The unique identifier for the toast
   * @param sentryDetails - The Sentry details
   * @param serverDetails - The server details
   * @param retryText - The retry button text
   * @param retryAction - The function to call when the retry button is clicked
   * @param cancelText - The cancel button text
   * @param noAction - Whether to show any action button or not
   */
  showSonnerToast: (
    message: string,
    isSuccess: boolean,
    identifier?: string,
    sentryDetails?: ErrorEvent | null,
    serverDetails?: ResponseErrorModel | null,
    retryText?: string,
    retryAction?: () => Promise<any>,
    cancelText?: string,
    noAction?: boolean
  ) => void;
  /**
   * Use it to display same toast as showSonnerToast but with retry and with / without cancel actions.
   *
   * @param message - Message to display
   * @param retryText - Retry button text
   * @param retryAction - Function to call when retry button is clicked
   * @param cancelText - Cancel button text
   */
  showRetrySonnerToast: (
    message: string,
    retryText: string,
    retryAction: () => Promise<any>,
    cancelText?: string
  ) => void;
};

let instance: IUseToasts | null = null;

export function useToasts(): IUseToasts {
  if (instance) return instance;

  //#region Stores
  const appStore = useAppStore();
  //#endregion

  //#region Variables
  let currentToastId: string | undefined;
  //#endregion

  //#region Private methods
  const _checkDir = async (path: string): Promise<boolean> => {
    try {
      await Filesystem.stat({
        path: path,
        directory: Directory.Data,
      });
      return true;
    } catch (e) {
      console.log('Failed to get stat', e);
      try {
        await Filesystem.mkdir({
          path: path,
          directory: Directory.Data,
          recursive: true,
        });
        return true;
      } catch (e) {
        console.error(`[ERROR] Failed to create directory: ${path}`, e);
        return false;
      }
    }
  };

  const _downloadJson = async (id: string, data: string): Promise<void> => {
    try {
      if (!isNativeMobile) {
        const blob = new Blob([data], { type: 'text/json' });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${id}.json`;
        link.click();
        URL.revokeObjectURL(link.href);
      } else {
        const directoryPath = `${import.meta.env.VITE_APP_NAME}`;
        const filePath = `${directoryPath}/${id}.json`;

        if (!(await _checkDir(directoryPath))) {
          console.warn(`[WARN] Failed to save file: ${filePath}`);
          return;
        }

        await Filesystem.writeFile({
          path: filePath,
          data,
          directory: Directory.Data,
          encoding: Encoding.UTF8,
        });
      }
    } catch (e) {
      console.error(`[ERROR] Failed to download file: ${id}.json`, e);
    }
  };
  //#endregion

  //#region Public methods
  const showSonnerToast = (
    message: string,
    isSuccess: boolean,
    identifier?: string,
    sentryDetails?: ErrorEvent | null,
    serverDetails?: ResponseErrorModel | null,
    retryText?: string,
    retryAction?: () => Promise<any>,
    cancelText?: string,
    noAction?: boolean
  ): void => {
    const id = identifier || new Date().toISOString();

    try {
      const { resetCurrentError } = useErrors();

      if (message.length > 80) {
        message = message.slice(0, 77) + '...';
      }

      const themeMap: Record<ThemeAppEnum, 'light' | 'dark' | 'system'> = {
        [ThemeAppEnum.Light]: 'light',
        [ThemeAppEnum.Dark]: 'dark',
        [ThemeAppEnum.System]: 'system',
      };

      const successStyle = {
        borderColor: 'var(--ion-color-success)',
        borderStyle: 'solid',
        borderWidth: '1px',
      };

      const errorStyle = {
        borderColor: 'var(--ion-color-warning-shade)',
        borderStyle: 'solid',
        borderWidth: '1px',
      };

      const download = {
        label: 'Download',
        onClick: async () => {
          const data = `[${JSON.stringify(sentryDetails, null, 2)},\n${JSON.stringify(serverDetails, null, 2)}]`;
          await _downloadJson(id, data);
          toast.dismiss(id);
          resetCurrentError();
        },
      };

      const retry = {
        label: retryText,
        onClick: async () => {
          toast.dismiss(id);
          resetCurrentError();
          if (retryAction) {
            await retryAction();
          }
        },
      };

      const cancel = {
        label: cancelText,
        onClick: async () => {
          toast.dismiss(id);
          resetCurrentError();
        },
      };

      const action = isSuccess ? undefined : retryText ? retry : download;

      const config = {
        id,
        theme: themeMap[appStore.getLocalTheme],
        expand: false,
        position: 'top-center' as
          | 'top-left'
          | 'top-right'
          | 'bottom-left'
          | 'bottom-right'
          | 'top-center'
          | 'bottom-center',
        visibleToasts: 5,
        duration: isSuccess ? 3000 : 10000, //Infinity,
        closeButton: isSuccess ? false : true,
        richColors: true,
        style: {
          fontSize: '1rem',
          color: 'var(--ion-color-dark)',
          borderRadius: 'var(--app-md-radius)',
          background: 'var(--ion-color-light-background-contrast)',
          marginTop: isAnyMobile ? 'var(--ion-safe-area-top)' : '',
          maxWidth: isAnyMobile ? '90%' : '750px',
          minWidth: isAnyMobile ? '90%' : '350px',
          maxHeight: '100px',
          whiteSpace: 'wrap',
          ...(isSuccess ? successStyle : errorStyle),
        },
        action: !noAction ? action : undefined,
        cancel: cancelText ? cancel : undefined,
        onDismiss: () => {
          console.log(`[INFO] Dismissed sonner toast with id: ${id}...`);
          resetCurrentError();
        },
      };

      isSuccess ? toast.success(message, config) : toast.error(message, config);
      currentToastId = id;
    } catch (e) {
      console.error('[ERROR] Error showing sonner toast:', e);
    }
  };

  const showRetrySonnerToast = (
    message: string,
    retryText: string,
    retryAction: () => Promise<any>,
    cancelText?: string
  ): void => {
    showSonnerToast(message, false, undefined, undefined, undefined, retryText, retryAction, cancelText, undefined);
  };
  //#endregion

  instance = {
    get currentToastId(): string | undefined {
      return currentToastId;
    },
    showSonnerToast,
    showRetrySonnerToast,
  };

  return instance;
}
