<template>
  <div class="flex ai-center">
    <ion-button ref="cell" color="medium" size="small" @click.stop="handleClick">
      <icons-provider
        slot="icon-only"
        :icon-props="{
          width: '14',
          height: '14',
        }"
        :name="icon"
      />
    </ion-button>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';

import { IonButton } from '@ionic/vue';
import { IconsProvider } from '@/components';
import { AppIconsEnum } from '@/enums';

//#region Props
const props = defineProps({
  model: {
    type: Object as PropType<unknown>,
    required: true,
  },
  onAction: {
    type: Function as PropType<(data: unknown) => void>,
    required: true,
  },
  icon: {
    type: String as PropType<AppIconsEnum>,
    required: true,
  },
});
//#endregion

//#region Methods
const handleClick = () => {
  props.onAction(props.model);
};
//#endregion
</script>

<style scoped lang="scss">
ion-button {
  @include resetStyleFromIonicButton;
}
</style>
