<template>
  <div :class="['contributions', size]">
    <template v-if="isAnyMobile">
      <ion-chip v-for="(item, index) in contributions" :key="index" color="medium" @click.stop="fullView = !fullView">
        <icons-provider
          v-if="fullView"
          :icon-props="{
            width: '14',
            height: '14',
            fill: 'var(--ion-color-medium)',
          }"
          :name="item.icon"
        />
        <ion-label>{{ !fullView ? item.count : item.description }}</ion-label>
      </ion-chip>
    </template>

    <template v-else>
      <ion-chip
        v-for="(item, index) in contributions"
        :key="index"
        v-tooltip.bottom="{
          content: item.description,
          theme: 'info-tooltip',
          disabled: isAnyMobile,
        }"
        color="medium"
        @click.stop
      >
        <icons-provider
          :icon-props="{
            width: '14',
            height: '14',
            fill: 'var(--ion-color-medium)',
          }"
          :name="item.icon"
        />
        <ion-label>{{ item.count }}</ion-label>
      </ion-chip>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { IonChip, IonLabel } from '@ionic/vue';
import type { ComputedRef, PropType } from 'vue';
import { computed, ref } from 'vue';

import { isAnyMobile } from '@/helpers';
import { useI18n } from '@/i18n';
import type { UserContributionsModel } from '@/types';
import { AppIconsEnum } from '@/enums';

const props = defineProps({
  userId: {
    type: Number,
    required: true,
  },
  contributions: {
    type: Object as PropType<UserContributionsModel>,
    required: true,
  },
  size: {
    type: String as PropType<'small' | 'default'>,
    default: 'default',
  },
});

const { t } = useI18n();
const fullView = ref<boolean>(false);

const contributions: ComputedRef<{ icon: AppIconsEnum; count: string | number; description: string }[]> = computed(
  () => [
    {
      icon: AppIconsEnum.Comment,
      count: `${props.contributions.postsCount} / ${props.contributions.commentsCount}`,
      description: `${t('search.searchView.types.posts')}: ${
        props.contributions.postsCount
      }, ${t('feed.comments.title')}: ${props.contributions.commentsCount}`,
    },
    {
      icon: AppIconsEnum.Document,
      count: `${props.contributions.filesCount} / ${props.contributions.wikiesCount}`,
      description: `${t('search.searchView.types.files')}: ${
        props.contributions.filesCount
      }, ${t('search.searchView.types.wikis')}: ${props.contributions.wikiesCount}`,
    },
    {
      icon: AppIconsEnum.IssueTypeIssue,
      count: props.contributions.tasksCount,
      description: `${t('taskManagement.tasks.all')}: ${props.contributions.tasksCount}`,
    },
  ]
);
</script>

<style scoped lang="scss">
.contributions {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  ion-chip {
    font-size: 0.7rem;
    margin-left: 0;
    margin-right: app-padding(md);
  }
  &.small {
    ion-chip {
      font-size: 0.6rem;
      margin-right: app-padding(sm);
      padding-inline: app-padding(md);
      padding-top: app-padding(sm);
      padding-bottom: app-padding(sm);
      min-height: unset;
      align-items: bottom;
      border-radius: app-radius(md);
      margin-top: 0;
      ion-label {
        line-height: 1;
      }
    }
  }
}
</style>
