import { AccountApiService } from './account.service';
import { AdminApiService } from './admin.service';
import { AnnouncementApiService } from './announcement.service';
import { AuthApiService } from './auth.service';
import { BadgesApiService } from './badge.service';
import { DocApiService } from './doc.service';
import { EventApiService } from './event.service';
import { FileApiService } from './file.service';
import { GroupsApiService } from './group.service';
import { MeetApiService } from './meet.service';
import { MessengerApiService } from './messenger.service';
import { NetworksApiService } from './network.service';
import { NotificationApiService } from './notifications.service';
import { OfficeApiService } from './office.service';
import { CustomPageApiService } from './page.service';
import { PostApiService } from './posts.service';
import { ProjectsApiService } from './projects.service';
import { SearchApiService } from './search.service';
import { ToolApiService } from './tool.service';
import { TopicApiService } from './topic.service';
import { UserApiService } from './user.service';
import { WikiApiService } from './wiki.service';

import { CustomMenuApiService } from '@/services/menu.service';
import type { ApiListItem } from '@/types';

export const $api = {
  auth: new AuthApiService(),
  account: new AccountApiService(),
  user: new UserApiService(),
  meet: new MeetApiService(),
  messenger: new MessengerApiService(),
  file: new FileApiService(),
  network: new NetworksApiService(),
  admin: new AdminApiService(),
  tool: new ToolApiService(),
  post: new PostApiService(),
  group: new GroupsApiService(),
  notification: new NotificationApiService(),
  doc: new DocApiService(),
  announcement: new AnnouncementApiService(),
  page: new CustomPageApiService(),
  event: new EventApiService(),
  topic: new TopicApiService(),
  search: new SearchApiService(),
  badge: new BadgesApiService(),
  projects: new ProjectsApiService(),
  wiki: new WikiApiService(),
  office: new OfficeApiService(),
  menu: new CustomMenuApiService(),
};

export { default as axios, getAppVersion } from './axios';

export const API_LIST: ApiListItem[] = [
  {
    name: 'core-qa',
    url: 'https://api.core-qa.united-grid.com',
  },
  {
    name: 'core-dev-2',
    url: 'https://api.core-dev-2.united-grid.com',
  },
  {
    name: 'core-dev-3',
    url: 'https://api.core-dev-3.united-grid.com',
  },
  {
    name: 'core-dev-4',
    url: 'https://api.core-dev-4.united-grid.com',
  },
  {
    name: 'core-dev-5',
    url: 'https://api.core-dev-5.united-grid.com',
  },
  {
    name: 'core-dev-6',
    url: 'https://api.core-dev-6.united-grid.com',
  },
  {
    name: 'core-beta',
    url: 'https://api.core-beta2.intrakommuna.de',
  },
  {
    name: 'PRODUCTION',
    url: 'https://api.core.intrakommuna.de',
  },
  {
    name: 'core-qa-box',
    url: 'https://api.core-qa-box.united-grid.com',
  },
  {
    name: 'core-test-box',
    url: 'https://api.core-test-box.united-grid.com',
  },
  {
    name: 'core-test-cloud',
    url: 'https://api.core-test-cloud.united-grid.com',
  },
];
