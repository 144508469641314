import type { OverlayEventDetail, PositionSide } from '@ionic/core';
import { popoverController } from '@ionic/vue';

import { defineAsyncComponent } from 'vue';

import { isFileGuard, isFolderGuard, isWikiGuard } from './guards';

import type {
  MessengerListActionEnum,
  FileActionEnum,
  WikiActionEnum,
  DocsFilterTypeEnum,
  PostUploadFileEnum,
  AppImageChangeMenuEnum,
  PostTextActionEnum,
  CalendarCellActionEnum,
  CalendarViewModeEnum,
  CommentActionEnum,
  EventCalendarSourceEnum,
  IdeaStatusEnum,
  TaskManagementColumnActionEnum,
  CustomPageShowTypeEnum,
  WidgetCalendarPeriodEnum,
  CountriesEnum,
  PostsFilterEnum,
  AppMenuEnum,
  GroupsFilterEnum,
  UsersFilterEnum,
  AppBarEnum,
  TaskManagementTasksSortByEnum,
  TaskManagementTasksPageTypeEnum,
  TaskManagementProjectsSortByEnum,
  TopicsFilterEnum,
  AdminUserManagementMenuEnum,
  WikiDeleteOptionsEnum,
  FeedFilterTypeEnum,
  WikiEditControlsEnum,
  TopicsSortTypeEnum,
  CountryCodesEnum,
  SortingTypeEnum,
  WikiSaveModeEnum,
} from '@/enums';
import { DocumentTypeEnum } from '@/enums';
import { useFileActions } from '@/helpers/useFileActionsHelper';
import { useWiki } from '@/helpers/useWikiHelper';
import type {
  AppMenuItem,
  AppMenuButtonsModel,
  DocModel,
  EventTimeModel,
  MessageChainModel,
  TaskManagementTaskModel,
  TaskManagementMilestoneModel,
  TopicModel,
  UserShortModel,
  TaskManagementColumnModel,
  TaskManagementProjectModel,
  TabCategories,
  PageModel,
  DocsMenuItemModel,
  WikiActionsMenuModel,
  MenuItemModel,
  CalendarEventModel,
} from '@/types';

//#region Common
export const openAppSortingSelectPopover = async (
  ev: Event,
  sortingTypes: SortingTypeEnum[],
  currentSorting: SortingTypeEnum
): Promise<SortingTypeEnum | undefined> => {
  const AppSortingSelect = defineAsyncComponent(() => import('@/components/Common/AppSortingSelectPopover.vue'));
  const popover = await popoverController.create({
    component: AppSortingSelect,
    mode: 'md',
    event: ev,
    translucent: true,
    dismissOnSelect: true,
    componentProps: {
      sortingTypes,
      currentSorting,
    },
  });
  await popover.present();

  return popover.onDidDismiss().then(async (result: OverlayEventDetail<SortingTypeEnum | undefined>) => {
    return result.data;
  });
};

export const openImageChangePopover = async (
  ev: Event,
  actions: AppMenuButtonsModel[]
): Promise<AppImageChangeMenuEnum | undefined> => {
  const component = defineAsyncComponent(() => import('@/components/Common/AppImageChangeMenuPopover.vue'));
  const popover = await popoverController.create({
    component: component,
    mode: 'md',
    event: ev,
    reference: 'event',
    translucent: true,
    dismissOnSelect: true,
    cssClass: 'custom-popover',
    componentProps: { actions },
  });
  await popover.present();

  return popover.onDidDismiss().then(async (result: OverlayEventDetail<AppImageChangeMenuEnum | undefined>) => {
    return result.data;
  });
};

export const openAppDatePickerPopover = async (
  ev: Event,
  date: string | null,
  yearsEnabled: boolean,
  timesEnabled = false,
  minutesIncrement = 15,
  minDate = ''
): Promise<string | undefined> => {
  const component = defineAsyncComponent(() => import('@/components/Common/AppDatePickerPopover.vue'));
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    dismissOnSelect: false,
    componentProps: {
      date,
      yearsEnabled,
      timesEnabled,
      minutesIncrement,
      minDate,
    },
    cssClass: ['custom-popover', 'centered-popover'],
  });
  await popover.present();
  return popover.onDidDismiss().then(async (result: OverlayEventDetail<string | undefined>) => {
    return result.data;
  });
};

export const openAppRightPopover = async (ev: Event): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/components/Common/AppRightMenuPopover.vue'));
  const target = ev.currentTarget || ev.target;
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: Object.assign({}, ev, { target }),
    side: 'bottom',
    dismissOnSelect: false,
    alignment: 'end',
    showBackdrop: false,
    cssClass: ['custom-popover', 'custom-menu-popover'],
  });
  await popover.present();
};

export const openAppCountrySelectPopover = async (
  ev: Event
): Promise<{ country: CountriesEnum; code: CountryCodesEnum } | undefined> => {
  const component = defineAsyncComponent(() => import('@/components/Common/AppCountrySelectPopover.vue'));
  const target = ev.currentTarget || ev.target;
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: Object.assign({}, ev, { target }),
    reference: 'trigger',
    dismissOnSelect: false,
    alignment: 'start',
    cssClass: ['custom-popover'],
  });
  await popover.present();

  return popover
    .onDidDismiss()
    .then(async (result: OverlayEventDetail<{ country: CountriesEnum; code: CountryCodesEnum } | undefined>) => {
      return result.data;
    });
};

export const openAppFeedTypeSelectPopover = async (
  ev: Event,
  selectedFeedType: FeedFilterTypeEnum
): Promise<FeedFilterTypeEnum | undefined | null> => {
  const component = defineAsyncComponent(() => import('@/components/Common/AppFeedTypeSelectPopover.vue'));
  const target = ev.currentTarget || ev.target;
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: Object.assign({}, ev, { target }),
    componentProps: { ev: Object.assign({}, ev, { target }), selectedFeedType },
    reference: 'trigger',
    dismissOnSelect: false,
    side: 'bottom',
    alignment: 'center',
    showBackdrop: false,
    cssClass: ['custom-popover'],
  });
  await popover.present();

  return popover.onDidDismiss().then(async (result: OverlayEventDetail<FeedFilterTypeEnum | undefined | null>) => {
    return result.data;
  });
};

export const openAppFeedFilterSelectPopover = async (ev: any): Promise<PostsFilterEnum | undefined> => {
  const component = defineAsyncComponent(() => import('@/components/Common/AppFeedFilterSelectPopover.vue'));
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    reference: 'trigger',
    dismissOnSelect: true,
    side: 'bottom',
    alignment: 'center',
    showBackdrop: false,
    cssClass: ['custom-popover'],
  });
  await popover.present();

  return popover.onDidDismiss().then(async (result: OverlayEventDetail<PostsFilterEnum | undefined>) => {
    return result.data;
  });
};

export const openAppSubPopover = async (ev: Event, subMenuItems: AppMenuItem[], side: PositionSide): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/components/Common/AppSubMenuPopover.vue'));
  const target = ev.currentTarget || ev.target;
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: Object.assign({}, ev, { target }),
    side: side,
    dismissOnSelect: false,
    alignment: 'center',
    showBackdrop: false,
    componentProps: { subMenuItems },
    id: 'app-submenu-popover',
    cssClass: side === 'bottom' ? ['custom-menu-popover'] : ['custom-menu-popover', 'centered-popover'],
  });

  //TODO: This code moves the popover by calculating its height
  /* if (side === 'top') {
    const offset = ref<number>(0);
    const { unload, css } = useStyleTag(`.custom-menu-popover {opacity:0;}`);
    await popover.present().then(async () => {
      const popoverElement = document.querySelector(
        '.custom-menu-popover'
      ) as HTMLElement;
      if (popoverElement) {
        const popoverViewport =
          popoverElement.querySelector('.popover-viewport');
        if (popoverViewport) {
          offset.value = isNativeIOS ? 8 : popoverViewport.clientHeight + 8;
          css.value = `.custom-menu-popover {--offset-y: -${offset.value}px !important;}`;
        }
      }
    });

    popover.onDidDismiss().then(() => {
      unload();
    });
  } else {
    await popover.present();
  } */

  await popover.present();
};

export const openAppHoursIntervalsPopover = async (
  ev: Event,
  activeTime: EventTimeModel
): Promise<EventTimeModel | undefined> => {
  const component = defineAsyncComponent(() => import('@/components/Common/AppHoursIntervalsPopover.vue'));
  const popover = await popoverController.create({
    component: component,
    mode: 'md',
    event: ev,
    dismissOnSelect: true,
    cssClass: 'custom-popover',
    reference: 'event',
    componentProps: { activeTime },
  });
  await popover.present();
  return popover.onDidDismiss().then(async (result: OverlayEventDetail<EventTimeModel | undefined>) => {
    return result.data;
  });
};

export const openAppBarFilterPopover = async (
  ev: any,
  pageFlag: AppMenuEnum
): Promise<
  OverlayEventDetail<
    GroupsFilterEnum | UsersFilterEnum | TaskManagementTasksPageTypeEnum | TopicsFilterEnum | undefined
  >
> => {
  const component = defineAsyncComponent(() => import('@/components/Common/AppBar/AppBarFilterPopover.vue'));
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    componentProps: { pageFlag },
    reference: 'trigger',
    dismissOnSelect: true,
    side: 'bottom',
    alignment: 'end',
    showBackdrop: false,
    cssClass: ['custom-popover'],
  });
  await popover.present();

  return popover
    .onDidDismiss()
    .then(
      async (
        result: OverlayEventDetail<
          GroupsFilterEnum | UsersFilterEnum | TaskManagementTasksPageTypeEnum | TopicsFilterEnum | undefined
        >
      ) => {
        return result;
      }
    );
};

export const openAppBarSortingPopover = async (
  ev: any,
  pageFlag: AppMenuEnum
): Promise<TaskManagementTasksSortByEnum | TaskManagementProjectsSortByEnum | TopicsSortTypeEnum | undefined> => {
  const component = defineAsyncComponent(() => import('@/components/Common/AppBar/AppBarSortingPopover.vue'));
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    componentProps: { pageFlag },
    reference: 'trigger',
    dismissOnSelect: true,
    side: 'bottom',
    alignment: 'end',
    showBackdrop: false,
    cssClass: ['custom-popover'],
  });
  await popover.present();

  return popover
    .onDidDismiss()
    .then(
      async (
        result: OverlayEventDetail<
          TaskManagementTasksSortByEnum | TaskManagementProjectsSortByEnum | TopicsSortTypeEnum | undefined
        >
      ) => {
        return result.data;
      }
    );
};

export const openAppBarMobilePopover = async (ev: any, pageFlag: AppMenuEnum): Promise<AppBarEnum | undefined> => {
  const component = defineAsyncComponent(() => import('@/components/Common/AppBar/AppBarMobilePopover.vue'));
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    componentProps: { pageFlag },
    reference: 'trigger',
    dismissOnSelect: true,
    side: 'bottom',
    alignment: 'end',
    showBackdrop: false,
    cssClass: ['custom-popover'],
  });
  await popover.present();

  return popover.onDidDismiss().then(async (result: OverlayEventDetail<AppBarEnum | undefined>) => {
    return result.data;
  });
};

export const openAppDomainSelectPopover = async (ev: Event, domains: string[]): Promise<string | undefined> => {
  const component = defineAsyncComponent(() => import('@/components/Common/AppDomainSelectPopover.vue'));
  const target = ev.currentTarget || ev.target;
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: Object.assign({}, ev, { target }),
    reference: 'trigger',
    componentProps: { domains },
    dismissOnSelect: false,
    alignment: 'start',
    cssClass: ['custom-popover'],
  });
  await popover.present();

  return popover.onDidDismiss().then(async (result: OverlayEventDetail<string | undefined>) => {
    return result.data;
  });
};
//#endregion

//#region Users
export const openUserListPopover = async (
  ev: Event,
  users: UserShortModel[],
  withSearch?: boolean,
  groupId?: number | undefined,
  taskData?: TaskManagementTaskModel | undefined
): Promise<UserShortModel | undefined> => {
  const userList = defineAsyncComponent(() => import('@/components/Users/UserListPopover.vue'));
  const popover = await popoverController.create({
    component: userList,
    event: ev,
    mode: 'md',
    componentProps: { usersData: users, withSearch, groupId, taskData },
    dismissOnSelect: false,
    reference: 'trigger',
    cssClass: ['custom-popover', 'centered-popover', 'stretched-height'],
  });
  await popover.present();
  return popover.onDidDismiss().then(async (result: OverlayEventDetail<UserShortModel | undefined>) => {
    return result.data;
  });
};
//#endregion

//#region Feed
export const openFeedFilePopover = async (ev: Event): Promise<FileActionEnum | undefined> => {
  const fileMenu = defineAsyncComponent(() => import('@/components/Feed/FeedFileMenuPopover.vue'));
  const popover = await popoverController.create({
    component: fileMenu,
    mode: 'md',
    event: ev,
    translucent: true,
  });
  await popover.present();

  return popover.onDidDismiss().then(async (result: OverlayEventDetail<FileActionEnum | undefined>) => {
    return result.data;
  });
};

export const openFeedAttachmentFilePopover = async (
  ev: Event,
  groupId: number | null
): Promise<PostUploadFileEnum | undefined> => {
  const fileMenu = defineAsyncComponent(() => import('@/components/Feed/FeedAttachmentFilePopover.vue'));
  const popover = await popoverController.create({
    component: fileMenu,
    mode: 'md',
    event: ev,
    translucent: true,
    dismissOnSelect: true,
    cssClass: ['custom-popover', 'centered-popover'],
    componentProps: {
      groupId,
    },
  });
  await popover.present();

  return popover.onDidDismiss().then(async (result: OverlayEventDetail<PostUploadFileEnum | undefined>) => result.data);
};

export const openFeedPostTextPopover = async (ev: Event | undefined): Promise<PostTextActionEnum | undefined> => {
  const component = defineAsyncComponent(() => import('@/components/Feed/FeedPostTextMenuPopover.vue'));
  const popover = await popoverController.create({
    component: component,
    mode: 'md',
    event: ev,
    translucent: true,
    dismissOnSelect: true,
    cssClass: 'custom-popover',
    reference: 'event',
  });
  await popover.present();
  return popover.onDidDismiss().then(async (result: OverlayEventDetail<PostTextActionEnum | undefined>) => {
    return result.data;
  });
};

export const openFeedCommentPopover = async (
  authorId: number,
  currentUserId: number,
  isEditable: boolean,
  ev: Event
): Promise<CommentActionEnum | undefined> => {
  const component = defineAsyncComponent(() => import('@/components/Feed/FeedCommentMenuPopover.vue'));

  const popover = await popoverController.create({
    component: component,
    mode: 'md',
    event: ev,
    translucent: true,
    cssClass: 'custom-popover',
    reference: 'event',
    componentProps: {
      authorId,
      currentUserId,
      isEditable,
    },
  });

  await popover.present();

  return popover.onDidDismiss().then(async (result: OverlayEventDetail<CommentActionEnum | undefined>) => result.data);
};

export const openFeedIdeaStatusPopover = async (
  ev: Event,
  activeStatus: IdeaStatusEnum
): Promise<IdeaStatusEnum | undefined> => {
  const component = defineAsyncComponent(() => import('@/components/Feed/FeedIdeaStatusPopover.vue'));
  const popover = await popoverController.create({
    component: component,
    mode: 'md',
    event: ev,
    dismissOnSelect: true,
    cssClass: 'custom-popover',
    reference: 'event',
    componentProps: { activeStatus },
  });
  await popover.present();
  return popover.onDidDismiss().then(async (result: OverlayEventDetail<IdeaStatusEnum | undefined>) => {
    return result.data;
  });
};

export const openFeedViewersPopover = async (postId: number): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/components/Feed/FeedViewersPopover.vue'));
  const popover = await popoverController.create({
    component,
    mode: 'md',
    dismissOnSelect: true,
    componentProps: { postId },
    cssClass: ['custom-popover', 'centered-popover'],
  });
  await popover.present();
};

export const openFeedEventSubscribersPopover = async (postId: number): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/components/Feed/FeedEventSubscribersPopover.vue'));
  const popover = await popoverController.create({
    component,
    mode: 'md',
    dismissOnSelect: true,
    componentProps: { postId },
    cssClass: ['custom-popover', 'centered-popover'],
  });
  await popover.present();
};
//#endregion

//#region Messenger
export const openMessengerListPopover = async (
  ev: Event,
  chain: MessageChainModel,
  isHeader: boolean
): Promise<MessengerListActionEnum | undefined> => {
  const messengerListMenu = defineAsyncComponent(() => import('@/components/Messenger/MessengerListMenuPopover.vue'));
  const popover = await popoverController.create({
    component: messengerListMenu,
    componentProps: {
      chain,
      isHeader,
    },
    event: ev,
    translucent: true,
    dismissOnSelect: true,
  });
  await popover.present();

  return popover.onDidDismiss().then(async (result: OverlayEventDetail<MessengerListActionEnum | undefined>) => {
    return result.data;
  });
};

/** @note Not in use */
// export const openMessengerContextPopover = async (
//   ev: Event,
//   id: number,
//   editable: boolean,
//   authorId: number,
//   currentId: number | undefined
// ): Promise<MessageActionEnum | undefined> => {
//   const messengerMenu = defineAsyncComponent(
//     () => import('@/components/Messenger/MessengerContextMenuPopover.vue')
//   );
//   const popover = await popoverController.create({
//     component: messengerMenu,
//     componentProps: {
//       id: id,
//       isEditable: editable,
//       isAuthor: authorId === currentId,
//     },
//     mode: 'md',
//     translucent: true,
//   });
//   await popover.present();

//   return popover
//     .onDidDismiss()
//     .then(async (result: OverlayEventDetail<MessageActionEnum | undefined>) => {
//       return result.data;
//     });
// };
//#endregion

//#region Docs
export const openDocsActionsPopover = async (
  ev: Event,
  doc: DocModel
): Promise<FileActionEnum | WikiActionEnum | undefined> => {
  const isWiki: boolean = doc.documentType === DocumentTypeEnum.Wiki;
  const isFile: boolean = doc.documentType === DocumentTypeEnum.File;
  const isFolder: boolean = doc.documentType === DocumentTypeEnum.Folder;

  let menuItems: WikiActionsMenuModel[] | DocsMenuItemModel[] = [];
  if (isWiki && isWikiGuard(doc.data)) {
    menuItems = useWiki().getActionsMenuItems(doc.data);
  } else if (isFile && isFileGuard(doc.data)) {
    menuItems = useFileActions().getActionsMenuItems(doc);
  } else if (isFolder && isFolderGuard(doc.data)) {
    menuItems = useFileActions().getActionsMenuItems(doc);
  }

  if (menuItems.length === 0) return undefined;

  const docsMenu = defineAsyncComponent(() => import('@/components/Docs/DocsActionsMenuPopover.vue'));
  const popover = await popoverController.create({
    component: docsMenu,
    mode: 'md',
    event: ev,
    translucent: true,
    cssClass: ['custom-popover', 'centered-popover'],
    componentProps: {
      menuItems,
    },
  });

  await popover.present();

  return popover
    .onDidDismiss()
    .then(async (result: OverlayEventDetail<FileActionEnum | WikiActionEnum | undefined>) => result.data);
};

export const openDocsFilterItemPopover = async (ev: Event, filterType: DocsFilterTypeEnum): Promise<void> => {
  const docsFilterItem = defineAsyncComponent(() => import('@/components/Docs/DocsFilterItemPopover.vue'));
  const popover = await popoverController.create({
    component: docsFilterItem,
    mode: 'md',
    event: ev,
    translucent: true,
    dismissOnSelect: true,
    componentProps: {
      filterType,
    },
  });
  await popover.present();
};

export const openDocsFilterSelectPopover = async (ev: Event): Promise<void> => {
  const DocsFilterSelect = defineAsyncComponent(() => import('@/components/Docs/DocsFilterSelectPopover.vue'));
  const popover = await popoverController.create({
    component: DocsFilterSelect,
    mode: 'md',
    event: ev,
    translucent: true,
    dismissOnSelect: true,
  });
  await popover.present();
};

/**
 * @obsolete - Use appSortingSelect instead
 * @deprecated - will be removed in the future
 * @see src/helpers/popoverComponents.ts - appSortingSelect
 * @see src/components/Common/AppSortingSelectPopover.vue
 */
export const openDocsSortingSelectPopover = async (ev: Event): Promise<void> => {
  const DocsSortingSelect = defineAsyncComponent(() => import('@/components/Docs/DocsSortingSelectPopover.vue'));
  const popover = await popoverController.create({
    component: DocsSortingSelect,
    mode: 'md',
    event: ev,
    translucent: true,
    dismissOnSelect: true,
  });
  await popover.present();
};
//#endregion

//#region Task Management
export const openTaskManagementColumnPopover = async (
  ev: Event,
  column: TaskManagementColumnModel
): Promise<TaskManagementColumnActionEnum | undefined> => {
  const component = defineAsyncComponent(
    () => import('@/components/TaskManagement/Board/TaskManagementColumnPopover.vue')
  );
  const popover = await popoverController.create({
    component: component,
    mode: 'md',
    event: ev,
    reference: 'event',
    componentProps: { column },
    dismissOnSelect: true,
  });
  await popover.present();
  return popover.onDidDismiss().then(async (result: OverlayEventDetail<TaskManagementColumnActionEnum | undefined>) => {
    return result.data;
  });
};

export const openTaskManagementMilestonesPopover = async (
  ev: Event,
  cardData: TaskManagementTaskModel | null,
  milestoneData: TaskManagementMilestoneModel | null,
  projectId: number,
  onlyCreate: boolean
): Promise<TaskManagementMilestoneModel | undefined> => {
  const component = defineAsyncComponent(
    () => import('@/components/TaskManagement/Milestones/TaskManagementMilestonesPopover.vue')
  );
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    dismissOnSelect: false,
    componentProps: { cardData, milestoneData, projectId, onlyCreate },
    cssClass: ['custom-popover', 'centered-popover', 'stretched-height'],
  });
  await popover.present();
  return popover.onDidDismiss().then(async (result: OverlayEventDetail<TaskManagementMilestoneModel | undefined>) => {
    return result.data;
  });
};

export const openTaskManagementProjectSwitchPopover = async (
  ev: Event,
  isPostCreation: boolean,
  isOnlyProjectCreation: boolean,
  withRouting?: boolean,
  groupId?: number
): Promise<void> => {
  const component = defineAsyncComponent(
    () => import('@/components/TaskManagement/Projects/TaskManagementProjectSwitchPopover.vue')
  );
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    dismissOnSelect: false,
    componentProps: {
      withRouting,
      groupId,
      isPostCreation,
      isOnlyProjectCreation,
    },
    cssClass: ['custom-popover', 'centered-popover'],
  });
  await popover.present();
};

export const openTaskManagementViewModePopover = async (ev: Event): Promise<boolean> => {
  const component = defineAsyncComponent(() => import('@/components/TaskManagement/TaskManagementViewModePopover.vue'));
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    reference: 'trigger',
    side: 'left',
    dismissOnSelect: true,
  });
  await popover.present();
  return popover.onDidDismiss().then(async () => {
    return true;
  });
};

export const openTaskManagementMainHeaderMobilePopover = async (
  ev: Event,
  showProjectControls: boolean,
  showBoardControls: boolean,
  showMilestonesControls: boolean,
  projectId: number
): Promise<void> => {
  const component = defineAsyncComponent(
    () => import('@/components/TaskManagement/MainHeader/TaskManagementMainHeaderMobilePopover.vue')
  );
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    reference: 'trigger',
    componentProps: {
      showProjectControls,
      showBoardControls,
      showMilestonesControls,
      projectId,
    },
    dismissOnSelect: true,
  });
  await popover.present();
};

export const openTaskManagementMainHeaderSearchModePopover = async (ev: Event): Promise<void> => {
  const component = defineAsyncComponent(
    () => import('@/components/TaskManagement/MainHeader/TaskManagementMainHeaderSearchModePopover.vue')
  );
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    reference: 'trigger',
    side: 'bottom',
    dismissOnSelect: true,
    showBackdrop: false,
    keyboardClose: false,
  });
  await popover.present();
};

export const openTaskManagementMainHeaderSortingPopover = async (ev: Event): Promise<void> => {
  const component = defineAsyncComponent(
    () => import('@/components/TaskManagement/MainHeader/TaskManagementMainHeaderSortingPopover.vue')
  );
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    reference: 'trigger',
    side: 'bottom',
    dismissOnSelect: true,
    showBackdrop: false,
    keyboardClose: false,
  });
  await popover.present();
};

export const openTaskManagementAttachmentsPopover = async (ev: Event, taskId: number): Promise<void> => {
  const component = defineAsyncComponent(
    () => import('@/components/TaskManagement/Tasks/TaskManagementAttachmentsPopover.vue')
  );
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    dismissOnSelect: false,
    componentProps: { taskId },
    cssClass: ['custom-popover', 'centered-popover', 'stretched-height'],
  });
  await popover.present();
};

export const openTaskManagementNewColumnPopover = async (
  ev: Event,
  project: TaskManagementProjectModel
): Promise<void> => {
  const component = defineAsyncComponent(
    () => import('@/components/TaskManagement/Board/TaskManagementNewColumnPopover.vue')
  );
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    dismissOnSelect: false,
    componentProps: { project },
    cssClass: ['custom-popover', 'centered-popover'],
  });
  await popover.present();
};

export const openTaskManagementAssignPopover = async (
  ev: Event,
  groupId: number,
  taskData: TaskManagementTaskModel
): Promise<number | undefined | null> => {
  const component = defineAsyncComponent(
    () => import('@/components/TaskManagement/Tasks/TaskManagementAssignPopover.vue')
  );
  const popover = await popoverController.create({
    component,
    event: ev,
    mode: 'md',
    componentProps: { groupId, taskData },
    dismissOnSelect: false,
    reference: 'trigger',
    cssClass: ['custom-popover', 'centered-popover', 'stretched-height'],
  });
  await popover.present();
  return popover.onDidDismiss().then(async (result: OverlayEventDetail<number | undefined | null>) => {
    return result.data;
  });
};

export const openTaskManagementStatusFilterPopover = async (ev: Event): Promise<void> => {
  const component = defineAsyncComponent(
    () => import('@/components/TaskManagement/MainHeader/TaskManagementStatusFilterPopover.vue')
  );
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    reference: 'trigger',
    side: 'left',
    dismissOnSelect: true,
    showBackdrop: false,
    keyboardClose: false,
  });
  await popover.present();
};
//#endregion

//#region Topics
export const openTagsPickerPopover = async (
  ev: Event,
  selectedTags: TopicModel[],
  multiple: boolean,
  editable: boolean
): Promise<TopicModel[] | undefined> => {
  const component = defineAsyncComponent(() => import('@/components/Topics/TagsPickerPopover.vue'));
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    dismissOnSelect: false,
    componentProps: { selectedTags, multiple, editable },
    cssClass: ['custom-popover', 'centered-popover', 'stretched-height'],
  });
  await popover.present();

  return popover.onDidDismiss().then(async (result: OverlayEventDetail<TopicModel[] | undefined>) => {
    return result.data;
  });
};
//#endregion

//#region Custom Pages
export const openCustomPageAccessEditPopover = async (ev: Event): Promise<CustomPageShowTypeEnum | undefined> => {
  const component = defineAsyncComponent(
    () => import('@/components/CustomPages/CustomPagesEdit/CustomPageAccessEditPopover.vue')
  );
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    reference: 'trigger',
    dismissOnSelect: true,
  });
  await popover.present();
  return popover.onDidDismiss().then(async (result: OverlayEventDetail<CustomPageShowTypeEnum | undefined>) => {
    return result.data;
  });
};

export const openCustomPagePublishPopover = async (
  ev: Event,
  editablePage: PageModel | null,
  isGroupDashboard: boolean,
  groupId: number | undefined
): Promise<void> => {
  const component = defineAsyncComponent(
    () => import('@/components/CustomPages/CustomPagesEdit/CustomPagePublishPopover.vue')
  );
  const popover = await popoverController.create({
    component,
    event: ev,
    mode: 'md',
    componentProps: { editablePage, isGroupDashboard, groupId },
    dismissOnSelect: true,
    showBackdrop: false,
    reference: 'trigger',
    side: 'bottom',
    alignment: 'end',
    cssClass: [],
  });
  await popover.present();
};
//#endregion

//#region Admin
export const openAdminUserManagementPopover = async (
  ev: any,
  userId: number
): Promise<AdminUserManagementMenuEnum | undefined> => {
  const component = defineAsyncComponent(() => import('@/components/Admin/AdminUserManagementMenuPopover.vue'));
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    reference: 'event',
    dismissOnSelect: true,
    componentProps: { userId },
    cssClass: ['custom-popover'],
  });
  await popover.present();

  return popover.onDidDismiss().then(async (result: OverlayEventDetail<AdminUserManagementMenuEnum | undefined>) => {
    return result.data;
  });
};
//#endregion

//#region Wiki
export const openWikiReplaceActionsPopover = async (): Promise<WikiDeleteOptionsEnum | undefined> => {
  const fileMenu = defineAsyncComponent(() => import('@/components/Wikis/WikiReplaceActionsPopover.vue'));
  const popover = await popoverController.create({
    component: fileMenu,
    mode: 'md',
    translucent: true,
    dismissOnSelect: true,
    cssClass: 'custom-popover',
  });
  await popover.present();

  return popover.onDidDismiss().then(async (result: OverlayEventDetail<WikiDeleteOptionsEnum | undefined>) => {
    return result.data;
  });
};

export const openWikiTemplatesPopover = async (
  ev: Event | undefined,
  items: TabCategories<WikiEditControlsEnum>[]
): Promise<WikiEditControlsEnum | undefined> => {
  const component = defineAsyncComponent(() => import('@/components/Wikis/WikiTemplatesMenuPopover.vue'));
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    reference: 'trigger',
    componentProps: { items },
    side: 'bottom',
    alignment: 'start',
    dismissOnSelect: true,
    cssClass: ['custom-popover'],
  });
  await popover.present();

  return popover
    .onDidDismiss()
    .then(async (result: OverlayEventDetail<WikiEditControlsEnum | undefined>) => result.data);
};

export const openWikiSavePopover = async (
  ev: Event | undefined,
  items: MenuItemModel<WikiSaveModeEnum>[]
): Promise<WikiSaveModeEnum | undefined> => {
  const component = defineAsyncComponent(() => import('@/components/Wikis/WikiSaveMenuPopover.vue'));
  const popover = await popoverController.create({
    component,
    mode: 'md',
    event: ev,
    reference: 'trigger',
    componentProps: { items },
    side: 'bottom',
    alignment: 'end',
    dismissOnSelect: true,
    cssClass: ['custom-popover'],
  });
  await popover.present();

  return popover.onDidDismiss().then(async (result: OverlayEventDetail<WikiSaveModeEnum | undefined>) => result.data);
};
//#endregion

//#region Calendar
export const openCalendarCellPopover = async (
  ev: Event | undefined,
  activeView: CalendarViewModeEnum,
  isSmall: boolean
): Promise<CalendarCellActionEnum | undefined> => {
  const component = defineAsyncComponent(() => import('@/components/Calendar/CalendarCellMenuPopover.vue'));
  const popover = await popoverController.create({
    component: component,
    mode: 'md',
    event: ev,
    dismissOnSelect: true,
    cssClass: 'custom-popover',
    reference: 'event',
    componentProps: { activeView, isSmall },
  });
  await popover.present();
  return popover.onDidDismiss().then(async (result: OverlayEventDetail<CalendarCellActionEnum | undefined>) => {
    return result.data;
  });
};

export const openCalendarEventListPopover = async (
  ev: Event | undefined,
  events: CalendarEventModel[]
): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/components/Calendar/CalendarEventListPopover.vue'));
  const popover = await popoverController.create({
    component: component,
    mode: 'md',
    event: ev,
    reference: 'event',
    componentProps: { eventsData: events },
    dismissOnSelect: true,
  });
  await popover.present();
};

export const openCalendarSourcePopover = async (ev: Event): Promise<EventCalendarSourceEnum | undefined> => {
  const component = defineAsyncComponent(() => import('@/components/Calendar/CalendarSourcePopover.vue'));
  const popover = await popoverController.create({
    component: component,
    mode: 'md',
    event: ev,
    reference: 'event',
    dismissOnSelect: true,
  });

  await popover.present();

  return popover
    .onDidDismiss()
    .then(async (result: OverlayEventDetail<EventCalendarSourceEnum | undefined>) => result.data);
};

export const openCalendarPeriodPopover = async (
  ev: Event
): Promise<{ title: string; value: WidgetCalendarPeriodEnum } | undefined> => {
  const component = defineAsyncComponent(() => import('@/components/Calendar/CalendarPeriodPopover.vue'));
  const popover = await popoverController.create({
    component: component,
    mode: 'md',
    event: ev,
    reference: 'event',
    dismissOnSelect: true,
  });
  await popover.present();
  return popover
    .onDidDismiss()
    .then(async (result: OverlayEventDetail<{ title: string; value: WidgetCalendarPeriodEnum } | undefined>) => {
      return result.data;
    });
};

export const openCalendarHeightPopover = async (ev: Event): Promise<number | undefined> => {
  const component = defineAsyncComponent(() => import('@/components/Calendar/CalendarHeightPopover.vue'));
  const popover = await popoverController.create({
    component: component,
    mode: 'md',
    event: ev,
    reference: 'event',
    dismissOnSelect: true,
  });
  await popover.present();
  return popover.onDidDismiss().then(async (result: OverlayEventDetail<number | undefined>) => {
    return result.data;
  });
};
//#endregion
