import { openDB, IDBPDatabase } from 'idb';
import { IndexedDBStoresEnum } from '@/enums';

/**
 * Name of the database
 *
 * @constant
 */
const DB_NAME = 'intra-db';

/**
 * Version of the database
 *
 * @constant
 */
const DB_VERSION = 3;

/**
 * Cache the openDB promise so multiple calls don’t re-init
 *
 * @readonly
 */
let dbPromise: Promise<IDBPDatabase<unknown>> | null = null;

/**
 * Creates or opens the database. If oldVersion < newVersion,
 * it removes all object stores (i.e., a complete reset) and re-creates them.
 *
 * @param stores Names of the stores to create/upgrade
 */
export async function useIDB(stores: IndexedDBStoresEnum[]): Promise<IDBPDatabase<unknown>> {
  if (!dbPromise) {
    dbPromise = openDB(DB_NAME, DB_VERSION, {
      upgrade(db, oldVersion, newVersion) {
        // If the DB already exists and is an older version, delete all existing stores
        if (oldVersion > 0 && newVersion && oldVersion < newVersion) {
          for (const oldStore of db.objectStoreNames) {
            db.deleteObjectStore(oldStore);
          }
        }

        // Recreate stores
        for (const newStore of stores) {
          if (!db.objectStoreNames.contains(newStore)) {
            const store = db.createObjectStore(newStore);
            store.createIndex('timestamp', 'timestamp');
          } else {
            console.warn(`[WARN] Store ${newStore} already exists. Skipping...`);
          }
        }
      },
    });
  }
  return dbPromise;
}

/**
 * Old DB name that should be removed
 *
 * @constant
 */
const OLD_DB_NAME = 'my-app-db';

/**
 * Removes the old database
 *
 * @private
 */
async function _deleteOldDB() {
  try {
    // Close any open connections
    console.log('[INFO] Connecting to old DB...'); //! DEBUG
    const oldReq = indexedDB.open(OLD_DB_NAME);
    console.log('[INFO] Successfully connected to old DB'); //! DEBUG

    oldReq.onsuccess = (event) => {
      const oldDB = (event.target as IDBOpenDBRequest).result;
      console.log('[INFO] Closing old DB...'); //! DEBUG
      oldDB.close();
      console.log('[INFO] Successfully closed old DB'); //! DEBUG
    };

    // Delete the DB
    await new Promise<void>((resolve, reject) => {
      console.log('[INFO] Deleting old DB...'); //! DEBUG
      const delReq = indexedDB.deleteDatabase(OLD_DB_NAME);
      delReq.onsuccess = () => resolve();
      delReq.onerror = () => reject(delReq.error);
      delReq.onblocked = () => reject(new Error('Delete blocked'));
      console.log('[INFO] Successfully deleted old DB'); //! DEBUG
    });
  } catch (e) {
    console.error('[ERROR] Failed to delete old DB:', e);
  }
}

/**
 * Call immediately so it runs when the module is imported
 */
_deleteOldDB();
