import { $api } from '@/services';
import {
  GroupModel,
  ResponseErrorModel,
  ResponseGroupsModel,
  ResponseTopicsModel,
  ResponseUsersModel,
  TopicModel,
  UserModel,
} from '@/types';
import { isResponseErrorGuard } from './guards';

type IUseAutocomplete = {
  users: (searchText: string) => Promise<UserModel[] | null>;
  groups: (searchText: string) => Promise<GroupModel[] | null>;
  topics: (searchText: string) => Promise<TopicModel[] | null>;
};

export const useAutocomplete = (): IUseAutocomplete => {
  const users = async (searchText: string): Promise<UserModel[] | null> => {
    if (!searchText) return null;

    const response = (await $api.user.autocomplete(searchText)) as ResponseUsersModel | ResponseErrorModel;

    return response.statusCode === 200 && !isResponseErrorGuard(response) ? response.data : null;
  };

  const groups = async (searchText: string): Promise<GroupModel[] | null> => {
    if (!searchText) return null;

    const response = (await $api.group.autocomplete(searchText, false)) as ResponseGroupsModel | ResponseErrorModel;

    return response.statusCode === 200 && !isResponseErrorGuard(response) ? response.data : null;
  };

  const topics = async (searchText: string): Promise<TopicModel[] | null> => {
    if (!searchText) return null;

    const response = (await $api.topic.autocomplete(searchText)) as ResponseTopicsModel | ResponseErrorModel;

    return response.statusCode === 200 && !isResponseErrorGuard(response) ? response.data : null;
  };

  return {
    users,
    groups,
    topics,
  };
};
