import { HubConnectionState, type HubConnection } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';
import type { Ref } from 'vue';
import { ref } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import {
  ChainTypeEnum,
  MeetStatusEnum,
  MessageDeliveryStatusEnum,
  MessageDirectionTypeEnum,
  MessageTypeEnum,
  MessengerChatTypeEnum,
  WikiActionEnum,
} from '@/enums';
import { feedTypeHelper, isNativeMobile, useToasts, toLastModel, useMeet, useNotifications, useWiki } from '@/helpers';
import { useI18n } from '@/i18n';
import router, { ROUTES_NAME } from '@/router';
import {
  useAppStore,
  useChatStore,
  useMeetStore,
  useMessengerStore,
  useNotificationsStore,
  useUserStore,
  useWikiStore,
} from '@/store';
import type {
  EditMessageModel,
  EventPrivateMessage,
  EventUserTyping,
  MessageChainModel,
  MessageModel,
  MessagesReadModel,
  WebSocketModel,
  WebSocketConnectModel,
} from '@/types';

type IUseWebSockets = {
  typingEvents: Ref<EventUserTyping[]>;
  initWebSockets: (model: WebSocketModel | null) => Promise<void>;
  startWebSockets: () => Promise<boolean>;
  stopWebSockets: () => Promise<boolean>;
};

let instance: IUseWebSockets | null = null;

export function useWebSockets(): IUseWebSockets {
  if (instance) return instance;

  //* Variables
  let connection: HubConnection | undefined;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let isActive = false;
  const typingEvents = ref<EventUserTyping[]>([]);

  //* Stores
  const appStore = useAppStore();
  const chatStore = useChatStore();
  const meetStore = useMeetStore();
  const messengerStore = useMessengerStore();
  const userStore = useUserStore();
  const notificationsStore = useNotificationsStore();

  //* Helpers
  const { t } = useI18n();

  //* Private methods
  const _changeTyping = (data: EventUserTyping): void => {
    const index = typingEvents.value.findIndex(
      (f: EventUserTyping) => f.chainId === data.chainId && f.userId === data.userId
    );
    if (~index) {
      typingEvents.value.splice(index, 1, data);
    } else {
      typingEvents.value.push(data);
    }
  };

  const _onClosed = async (error?: Error): Promise<void> => {
    console.error('[ERROR] Connection closed', error);

    try {
      appStore.$patch((state) => {
        state.signalRConnectionStatus = HubConnectionState.Disconnected;
      });

      /** @note If user is on wiki page, then we need to unlock it */
      if (router.currentRoute.value.name === ROUTES_NAME.WIKI_EDIT) {
        const { id } = router.currentRoute.value.params;
        await useWikiStore().unlockEdit(Number(id));
      }
    } catch (e) {
      console.error('Failed to unlock wiki', e);
    }
  };

  const _onReconnected = async (connectionId?: string | undefined): Promise<void> => {
    try {
      if (!connectionId) return;

      const model = appStore.getWebSocketModel;
      if (!model) return;

      if (connection) {
        const invokeModel: WebSocketConnectModel = {
          coreId: model.coreId,
          companyRowId: model.companyRowId,
          userId: model.userId,
          userRowId: model.userRowId,
          version: model.version,
        };
        await connection.invoke('connect', invokeModel);

        appStore.$patch((state) => {
          state.signalRConnectionId = connectionId;
          state.signalRConnectionStatus = connection?.state ?? HubConnectionState.Connected;
        });

        meetStore.$patch((state) => {
          state.connectionId = connectionId;
        });

        /** @note If user is on wiki edit page while reconnecting, then we try to lock it and redirect to wiki preview on failure */
        if (router.currentRoute.value.name === ROUTES_NAME.WIKI_EDIT) {
          const { id } = router.currentRoute.value.params;
          const lockStatus = await useWikiStore().lockEdit(Number(id));
          if (!lockStatus || lockStatus.success === false) {
            await useWiki().handleAction({ type: WikiActionEnum.ToCurrent, id: Number(id) });
          }
        }
      }

      return;
    } catch (e) {
      console.error('Reconnection failed', e);
      /** @note If the connection is not established, show a toast with the ability to reconnect */
      await _retryConnection();
    }
  };

  const _onTyping = (data: EventUserTyping): void => {
    _changeTyping({
      userId: +data.userId,
      chainId: +data.chainId,
      typing: data.typing,
    });
  };

  const _onUserConnected = (userId: number): void => {
    const index = appStore.onlineUsers.findIndex((u: number) => u === userId);
    if (index < 0) {
      appStore.setUserConnected(userId);
    }
  };

  const _onUserDisconnected = (userId: number): void => {
    appStore.setUserDisconnected(userId);
  };

  const _onPushMessage = async (message: MessageModel): Promise<void> => {
    const currentChainId = chatStore.getId;
    const currentUserId = userStore.getId;
    const model = appStore.getWebSocketModel;

    if (!model) {
      console.error('Pushing message failed - model is null', model);
      return;
    }

    const newMess = message.originalMessage as MessageModel;
    if (model.userId === newMess.authorId) {
      console.warn('Message from the current user, no need to update anything');
      return;
    }

    newMess.direction = MessageDirectionTypeEnum.Incoming;

    _changeTyping({
      userId: newMess.authorId,
      chainId: newMess.chainId,
      typing: false,
    });

    let existedChain = messengerStore.getChainById(newMess.chainId);
    //NOTE: If chain is not currently in the local storage - trying to get it
    if (!existedChain) {
      await messengerStore.chainById(newMess.chainId);
    }
    //NOTE: Trying to get chain again
    existedChain = messengerStore.getChainById(newMess.chainId);
    if (!existedChain) {
      console.error('Pushing message failed - chain is null', existedChain, newMess.chainId);
      return;
    }
    //NOTE: If message is in archive chain, change chain type to active
    if (existedChain && existedChain.chainType === ChainTypeEnum.Archive) {
      existedChain.chainType = ChainTypeEnum.Active;
    }

    const chainIsMuted = existedChain?.muted;
    const notificationsIsActive = appStore.localNotifications;

    //NOTE: If message is incoming, chain is not current, chain is not muted and notifications are active - playing sound
    const shouldPlaySound =
      newMess.direction === MessageDirectionTypeEnum.Incoming &&
      (newMess.chainId !== currentChainId || newMess.chainId === null) &&
      !chainIsMuted &&
      notificationsIsActive;

    if (shouldPlaySound) {
      if (!isNativeMobile) {
        const audioFile = new Audio(appStore.getAppNotificationSoundPath);
        await audioFile.play();
      } else {
        await useNotifications().scheduleLocalNotification(newMess);
      }
    }

    await chatStore.updateChain(existedChain, false);

    messengerStore.updateLastMessage(toLastModel(newMess), model.userId !== newMess.authorId);

    //NOTE: If the message is in the current chain and the author is not the current user - reading the message
    if (newMess.chainId === currentChainId && newMess.authorId !== currentUserId) {
      chatStore.read(
        {
          authorId: newMess.authorId,
          chainId: newMess.chainId,
          messageId: newMess.id,
          status: MessageDeliveryStatusEnum.ReadAll,
          uniqueId: newMess.uniqueId,
        } as MessagesReadModel,
        false
      );
      await chatStore.markAsRead([newMess.id]);
    }

    //NOTE: If participant leaves chat
    if (
      newMess.messageType === MessageTypeEnum.RemoveParticipant &&
      newMess.chainId === currentChainId &&
      newMess.authorId !== currentUserId
    ) {
      const meetRoomId = meetStore.getRoomIdByChainId(newMess.chainId);
      if (meetRoomId) {
        meetStore.deleteUser(newMess.authorId, meetRoomId);
      }
    }

    //NOTE: if chat admin deletes current user from chat
    if (newMess.messageType === MessageTypeEnum.RemoveYou) {
      existedChain.isInChain = false;
      const meetHelper = useMeet();
      const meetRoomId = meetStore.getRoomIdByChainId(newMess.chainId);
      if (meetRoomId) {
        await meetHelper.disconnectFromRoom(meetRoomId);
        return;
      }
      const activeCalls = await meetStore.getActiveCalls();
      const currentCallSession = activeCalls?.[newMess.chainId];
      if (currentCallSession && !meetRoomId) {
        meetStore.setRoom(currentCallSession.roomName, existedChain, currentCallSession.participantJwtToken);
        await meetHelper.disconnectFromRoom(currentCallSession.roomName);
      }
    }

    //NOTE: check if call is in progress when user is added to a group chat
    if (newMess.messageType === MessageTypeEnum.AddParticipant) {
      const meetRoomId = meetStore.getRoomIdByChainId(newMess.chainId);
      const activeCalls = await meetStore.getActiveCalls();
      const currentCallSession = activeCalls?.[newMess.chainId];
      const activeParticipants = await meetStore.getActiveParticipants(newMess.chainId);
      //NOTE: if author of message (admin of chant) added new user from active call
      if (
        currentCallSession &&
        !meetRoomId &&
        activeParticipants.find((participant) => participant.id === newMess.authorId)
      ) {
        const location: RouteLocationRaw = {
          name: ROUTES_NAME.MESSENGER_CHAT_BY_CHAIN,
          params: { id: newMess.chainId, type: MessengerChatTypeEnum.Chain },
        };
        const callModalResult = await useMeet().showCallModal(existedChain);
        if (callModalResult === MeetStatusEnum.Accept) {
          await chatStore.getChainById(newMess.chainId);
          meetStore.$patch((state) => {
            state.withVideo = false;
            state.isCallFromPage = true;
          });
          await router.push(location);
        } else if (callModalResult === MeetStatusEnum.Reject) {
          await meetStore.callUser(existedChain);
        }
      }
    }
  };

  const _onEditMessage = (message: MessageModel): void => {
    const mess = message.originalMessage;
    if (mess !== null) {
      chatStore.redact({
        chainId: mess.chainId,
        message: toLastModel(mess),
        fileInfos: mess.attachedFiles.data,
        uniqueId: mess.uniqueId,
      } as EditMessageModel);

      messengerStore.updateLastMessage(toLastModel(mess), false);
    }
  };

  const _onReadMessage = (message: MessageModel): void => {
    chatStore.read(
      {
        authorId: message.authorId,
        chainId: message.chainId,
        messageId: message.id,
        status: message.status,
        uniqueId: message.uniqueId,
      } as MessagesReadModel,
      false
    );
  };

  const _onDeleteMessage = (messageId: number, chainId: number): void => {
    const currentChainId = chatStore.chain?.chainId;
    const lastMessage = chatStore.delete(messageId, currentChainId as number);
    const index = messengerStore.data.findIndex((f: MessageChainModel) => f.chainId === chainId);

    if (~index) {
      if (currentChainId === chainId) {
        messengerStore.updateLastMessage(toLastModel(lastMessage), false);
      } else if (messengerStore.data[index].lastMessage.id === messageId) {
        messengerStore.data[index].lastMessage.text = t('messenger.chatPage.deleted');
        messengerStore.data[index].lastMessage.status = MessageDeliveryStatusEnum.Deleted;
      }
    }
  };

  /** @todo Handle private messages WS event */
  const _onSendPrivateMessage = (data: EventPrivateMessage): void => {
    console.log(`Received private message - `, data);
  };

  const _onSendSiteNotification = async (): Promise<void> => {
    /**
     * @note Now model - EventSiteNotification is very limited, so we can't get the full information about the notification.
     * Thus, we can only re-fetch the notifications from the server using `$api.notification.getNotifications()`
     * to update notificationsStore.notificationsIds, notificationsStore.data and notificationsStore.totalUnreadCount
     * @see src/store/notification.pinia.ts
     * @see src/services/notifications.service.ts
     * @link Back - https://gitlab.united-grid.com/intra/core/-/issues/846
     * @link Front - https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1672
     */
    await notificationsStore.notifications();
    // await notificationsStore.unreadNotifications();
  };

  const _onSendUserItem = async (): Promise<void> => {
    /**
     * @note Now upon this event, we only get the userItemId, so we can't get the full information about the user item.
     * We need to re-fetch the feed using the `feedTypeHelper` method to update postStore.data and postStore.postsIds.
     * Later, we will need to implement the ability to get the full information about the userItem and just update mentioned store properties with this new userItem
     * Commented out as it will be implemented in the future as part of https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1755
     * @link https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1755
     */
    await feedTypeHelper(ROUTES_NAME.FEED);
  };

  /** @todo Handle user item been commented WS event */
  const _onSendUserItemComment = (userItemId: number, commentId: number): void => {
    console.log(`User item ${userItemId} has a new comment ${commentId}`);
  };

  const _registerConnectionEvents = (): void => {
    if (!connection) {
      console.error('Registration of events failed - connection is null', connection);
      return;
    }

    connection.onclose(_onClosed);

    connection.onreconnected(_onReconnected);

    connection.on('typing', _onTyping);

    connection.on('usersOnline', (data: number[]) => {
      appStore.setOnlineUsers(data);
    });

    connection.on('userConnected', _onUserConnected);

    connection.on('userDisconnected', _onUserDisconnected);

    connection.on('pushMessage', _onPushMessage);

    connection.on('editMessage', _onEditMessage);

    connection.on('readMessage', _onReadMessage);

    connection.on('deleteMessage', _onDeleteMessage);

    connection.on('sendPrivateMessage', _onSendPrivateMessage);

    connection.on('sendSiteNotification', _onSendSiteNotification);

    connection.on('sendUserItem', _onSendUserItem);

    connection.on('sendUserItemComment', _onSendUserItemComment);
  };

  const _retryConnection = async (): Promise<void> => {
    try {
      const { showRetrySonnerToast } = useToasts();

      // User tries to reconnect
      const retryAction = async () => {
        await stopWebSockets();
        await initWebSockets(appStore.getWebSocketModel);
      };
      showRetrySonnerToast(t('messenger.errorConnect'), t('retry'), retryAction);
    } catch (err) {
      console.error('Reconnection failed', err);
    }
  };

  const _invokeConnection = async (connection: HubConnection, model: WebSocketModel): Promise<void> => {
    try {
      const invokeModel: WebSocketConnectModel = {
        coreId: model.coreId,
        companyRowId: model.companyRowId,
        userId: model.userId,
        userRowId: model.userRowId,
        version: model.version,
      };
      await connection.invoke('connect', invokeModel);

      appStore.$patch((state) => {
        state.isLoading = false;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.signalRConnectionId = connection.connectionId!;
        state.signalRConnectionStatus = connection.state;
      });

      meetStore.$patch({
        connectionId: connection.connectionId,
      });
    } catch (e) {
      console.error('Failed to invoke method on the server: ' + e);
      appStore.$patch((state) => {
        state.isLoading = false;
      });
      // If the connection is not established, show a toast with the ability to reconnect
      await _retryConnection();
    }
  };

  const _startConnection = async (connection: HubConnection): Promise<boolean> => {
    try {
      appStore.$patch((state) => {
        state.isLoading = true;
        state.isWaitingForCompleteLogin = false;
      });

      await connection.start();
      console.log(`[INFO] SignalR connection started. Id: ${connection.connectionId}`);
      return true;
    } catch (e) {
      console.error('[ERROR] SignalR connection error', e);
      appStore.$patch((state) => {
        state.isLoading = false;
      });
      return false;
    }
  };

  const _initSignal = async (model: WebSocketModel): Promise<void> => {
    connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(`${model.webSocket}/events`)
      .build();

    console.log('[INFO] SignalR connection has been initialized:', connection);

    _registerConnectionEvents();

    useMeet().registerEventsVideo(connection);

    await startWebSockets();
  };

  //* Public methods
  async function initWebSockets(model: WebSocketModel | null): Promise<void> {
    if (!model) {
      console.error('Initialization of WebSockets failed - model is null', model);
      return;
    }
    // Stop the current connection if it exists
    if (connection) {
      await connection.stop();
    }

    console.log('[INFO] Initializing WebSockets: ', model);

    appStore.$patch((state) => {
      state.signalRConnectionStatus = HubConnectionState.Connecting;
    });

    await _initSignal(model);
  }

  async function startWebSockets(): Promise<boolean> {
    let isConnectSignalR = false;

    try {
      const model = appStore.getWebSocketModel;
      if (!model) {
        console.error('Initialization of WebSockets failed - model is null', model);
        return false;
      }

      //NOTE: If the connection is not initialized, initialize it
      if (!connection) {
        _registerConnectionEvents();
        if (!connection) {
          console.error('Initialization of WebSockets failed - connection is null', connection);
          return false;
        }
      }

      //NOTE: Setting WebSocket as active
      isActive = true;

      appStore.$patch((state) => {
        state.isLoading = false;
      });

      //NOTE: Trying to start the connection
      isConnectSignalR = await _startConnection(connection);

      //NOTE: Trying to connect to the server
      if (isConnectSignalR) {
        await _invokeConnection(connection, model);
      }
      return isConnectSignalR;
    } catch (err) {
      console.error('Starting WebSockets failed', err);
      return false;
    }
  }

  async function stopWebSockets(): Promise<boolean> {
    isActive = false;
    try {
      if (!connection) {
        console.warn('Connection is already undefined, nothing to stop');
        return false;
      }

      console.log(`[INFO] Trying to stop WebSocket connection: ${connection.connectionId}...`);
      await connection.stop();
      return true;
    } catch (err) {
      console.error('Stopping WebSockets failed', err);
      return false;
    }
  }

  instance = {
    typingEvents,
    initWebSockets,
    startWebSockets,
    stopWebSockets,
  };

  return instance;
}
