import type { OverlayEventDetail } from '@ionic/core';
import { modalController, createAnimation } from '@ionic/vue';
import type { ComponentPublicInstance } from 'vue';
import { defineAsyncComponent } from 'vue';
import type { ComposerTranslation } from 'vue-i18n';
import {
  CoverImageTypeEnum,
  MessageActionEnum,
  AvatarTypeEnum,
  PostMenuActionEnum,
  FeedFlagEnum,
  FeedTypeEnum,
  PostTypeActionEnum,
  DocsMenuActionEnum,
  GroupsFilterEnum,
  UploadFileTypes,
  ShareArchiveLinkType,
  AiModeEnum,
  UserRoleEnum,
  ChatModalEnum,
  MessengerChatTypeEnum,
  ShareEntityType,
  PostShareToEnum,
} from '@/enums';
import { isAnyMobile, useToasts, useCustomPages, openLink } from '@/helpers';
import { useI18n } from '@/i18n';
import { useAppStore, useWikiStore, type OfficeProps } from '@/store';
import type {
  PollOptionsModel,
  GroupModel,
  PostModel,
  MessageChainModel,
  DocModel,
  FileModel,
  NetworkModel,
  MessageModel,
  FolderModel,
  BadgeModel,
  TopicModel,
  TaskManagementTaskModel,
  TaskManagementMilestoneModel,
  WikiRelationsModel,
  WidgetPathModel,
  CustomPagesWidgetModel,
  ImageViewerItemModel,
  MediaModel,
  DomainModel,
  HomePageModel,
  WikiTemplateModel,
  CreateWikiTemplateModel,
  UserModel,
  WikiModel,
  UserGroupShortModel,
} from '@/types';

//#region Private Methods
const _enterAnimation = (baseEl: HTMLElement): any | undefined => {
  const root = baseEl.shadowRoot as any;

  if (root !== null) {
    const backdropAnimation = createAnimation()
      .addElement(root.querySelector('ion-backdrop'))
      .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

    const wrapperAnimation = createAnimation()
      .addElement(root.querySelector('.modal-wrapper'))
      .keyframes([
        { offset: 0, opacity: '0', transform: 'scale(0)' },
        { offset: 1, opacity: '1', transform: 'scale(1)' },
      ]);

    return createAnimation()
      .addElement(baseEl)
      .easing('ease-out')
      .duration(250)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  } else {
    return undefined;
  }
};
//#endregion

//#region Common
export const openSupportModal = async (
  pageRef: ComponentPublicInstance | null,
  t: ComposerTranslation
): Promise<void> => {
  const { showSonnerToast } = useToasts();

  const component = defineAsyncComponent(() => import('@/modals/Common/SupportModal.vue'));
  const modal = await modalController.create({
    component,
    presentingElement: pageRef?.$el,
  });
  await modal.present();

  modal.onDidDismiss().then(async (event: OverlayEventDetail<boolean>) => {
    if (event.data) {
      showSonnerToast(t('support.success'), true);
    }
  });
};

export const openPasswordRestoreModal = async (
  pageRef: ComponentPublicInstance | null,
  t: ComposerTranslation
): Promise<void> => {
  const { showSonnerToast } = useToasts();

  const component = defineAsyncComponent(() => import('@/modals/Common/PasswordRestoreModal.vue'));
  const modal = await modalController.create({
    component,
    backdropDismiss: false,
    presentingElement: pageRef?.$el,
  });
  await modal.present();

  modal.onDidDismiss().then(async (event: OverlayEventDetail<boolean>) => {
    if (event.data) {
      showSonnerToast(t('passwordRestore.success'), true);
    }
  });
};

export const pagePrivacyPolicy = async (link: string): Promise<void> => {
  await openLink(link);
};

export const openPasswordChangeModal = async (
  withClosable: boolean,
  userRowId?: string //NOTE: if not - setting password for current user
): Promise<boolean | undefined> => {
  const component = defineAsyncComponent(() => import('@/modals/Common/PasswordChangeModal.vue'));
  const modal = await modalController.create({
    component,
    cssClass: 'custom-small-modal',
    id: 'password-change-modal',
    backdropDismiss: withClosable,
    keyboardClose: withClosable,
    componentProps: { withClosable, userRowId },
  });
  await modal.present();

  return modal.onDidDismiss().then(async (result: OverlayEventDetail<boolean | undefined>) => {
    return result.data;
  });
};

export const openTitleChangeModal = async (
  pageRef: ComponentPublicInstance | null,
  title: string,
  withText?: boolean,
  text?: string,
  isUrl?: boolean
): Promise<{ title: string; text: string } | undefined> => {
  const component = defineAsyncComponent(() => import('@/modals/Common/TitleChangeModal.vue'));
  const modal = await modalController.create({
    component,
    presentingElement: pageRef?.$el,
    cssClass: 'auto-height',
    componentProps: {
      title,
      withText,
      text,
      isUrl,
    },
  });
  await modal.present();
  return modal.onDidDismiss().then(async (result: OverlayEventDetail<{ title: string; text: string } | undefined>) => {
    return result.data;
  });
};

export const openAvatarChangeModal = async (
  pageRef: ComponentPublicInstance | null,
  image: Blob,
  imageFlag: AvatarTypeEnum,
  groupId?: number,
  chainId?: number
): Promise<Blob | undefined> => {
  const component = defineAsyncComponent(() => import('@/modals/Common/AvatarChangeModal.vue'));
  const appStore = useAppStore();
  const modal = await modalController.create({
    component,
    componentProps: {
      image,
      imageFlag,
      groupId,
      chainId,
    },
    presentingElement: pageRef?.$el,
    cssClass: appStore.isMDWidth ? ['custom-medium-modal', 'auto-height'] : ['auto-full-height'],
  });
  await modal.present();
  return modal.onDidDismiss().then(async (result: OverlayEventDetail<Blob | undefined>) => {
    return result.data;
  });
};

export const openCoverImageChangeModal = async (
  pageRef: ComponentPublicInstance | null,
  image: Blob,
  imageFlag: CoverImageTypeEnum,
  groupId?: number
): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/modals/Common/CoverImageChangeModal.vue'));
  const appStore = useAppStore();
  const modal = await modalController.create({
    component,
    componentProps: {
      image,
      imageFlag,
      groupId,
    },
    presentingElement: pageRef?.$el,
    cssClass: appStore.isMDWidth ? ['custom-medium-modal', 'auto-height'] : ['auto-full-height'],
  });
  await modal.present();
};

export const openAccountDeleteModal = async (): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/modals/Common/AccountDeleteModal.vue'));
  const modal = await modalController.create({
    component,
    mode: 'md',
  });
  await modal.present();
};

export const openDocsCreateWikiModal = async (
  groupId: number | null = null,
  folderId: number | null = null
): Promise<boolean> => {
  const callBack = (name: string, groupId: number | null, folderId: number | null): void => {
    try {
      useWikiStore().setOnCreate(name, groupId, folderId);
    } catch (error) {
      console.error(error);
    }
  };

  const modal = await modalController.create({
    component: defineAsyncComponent(() => import('@/modals/Common/AppCreateModal.vue')),
    componentProps: {
      groupId,
      folderId,
      title: useI18n().t('wiki.editOptions.enterName'),
      callBack,
    },
    cssClass: 'custom-medium-modal',
    mode: 'md',
  });

  await modal.present();

  const { data } = await modal.onDidDismiss();

  if (!data) {
    console.error('Failed to create wiki');
    return false;
  }

  return true;
};

export const openImagesViewerModal = async (
  /**
   * @todo All images have to be either FileModel or MediaModel.
   * Now we use this custom ugly type since since user of openImagesViewerModal passes mixed array of FileModel and MediaModel and some other stuff
   */
  index: number,
  items: ImageViewerItemModel[],
  id?: number
): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/modals/Common/ImageViewerModal.vue'));
  const modal = await modalController.create({
    component,
    componentProps: {
      index,
      items,
      id,
    },
    cssClass: ['fullscreen', 'image-viewer'],
  });
  await modal.present();
};

export const openRichTextEditorFullscreenModal = async (
  text: string,
  groupId: number | null
): Promise<string | undefined> => {
  const component = defineAsyncComponent(() => import('@/modals/Common/RichTextEditorFullscreenModal.vue'));
  const modal = await modalController.create({
    component,
    componentProps: { text, groupId },
    cssClass: ['fullscreen', 'ion-disable-focus-trap'],
    // https://ionicframework.com/docs/api/modal#focustrap
    // https://github.com/ionic-team/ionic-framework/issues/24646#issuecomment-2053585886
    // focusTrap: false,
  });
  await modal.present();
  return modal.onDidDismiss().then(async (result: OverlayEventDetail<string | undefined>) => {
    return result.data;
  });
};

export const openCreateLinkModal = async (): Promise<{ title: string; url: string } | undefined> => {
  const component = defineAsyncComponent(() => import('@/modals/Common/CreateLinkModal.vue'));
  const modal = await modalController.create({
    component,
    cssClass: ['auto-height'],
  });
  await modal.present();
  return modal.onDidDismiss().then(async (result: OverlayEventDetail<{ title: string; url: string } | undefined>) => {
    return result.data;
  });
};

export const openAppHeaderSearchModal = async (): Promise<void> => {
  const groupSelect = defineAsyncComponent(() => import('@/modals/Common/AppHeaderSearchModal.vue'));
  const modal = await modalController.create({
    component: groupSelect,
    mode: 'md',
    cssClass: ['custom-medium-modal'],
  });
  await modal.present();
};

export const openShareArchiveLinkModal = async (id: number, shareType: ShareArchiveLinkType): Promise<undefined> => {
  const sendPost = defineAsyncComponent(() => import('@/modals/Common/ShareArchiveLink.vue'));
  const modal = await modalController.create({
    component: sendPost,
    mode: 'md',
    componentProps: {
      id,
      shareType,
    },
    cssClass: ['custom-medium-modal', 'auto-height'],
  });
  await modal.present();
};

export const openCampusModal = async (): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/modals/Common/CampusModal.vue'));
  const modal = await modalController.create({
    component,
    cssClass: 'custom-modal-widest',
  });
  await modal.present();
};

export const openChooseUserModal = async (
  filterIds: number[],
  title?: string,
  withoutCurrent?: boolean
): Promise<UserModel[] | undefined> => {
  const component = defineAsyncComponent(() => import('@/modals/Common/AppChooseUserModal.vue'));

  const modal = await modalController.create({
    component,
    componentProps: {
      filterIds,
      title,
      withoutCurrent,
    },
  });
  await modal.present();
  return modal.onDidDismiss().then(async (result: OverlayEventDetail<UserModel[] | undefined>) => {
    return result.data;
  });
};

//#endregion

//#region Users
export const openPhoneMobileChangeModal = async (
  pageRef: ComponentPublicInstance | null,
  phone: string
): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/modals/Users/PhoneMobileChangeModal.vue'));
  const modal = await modalController.create({
    component,
    presentingElement: pageRef?.$el,
    componentProps: { phone },
    cssClass: 'custom-small-modal',
  });
  await modal.present();
};

export const openPhoneDeleteModal = async (pageRef: ComponentPublicInstance | null): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/modals/Users/PhoneDeleteModal.vue'));
  const modal = await modalController.create({
    component,
    presentingElement: pageRef?.$el,
    cssClass: 'custom-small-modal',
  });
  await modal.present();
};

export const openEmailChangeModal = async (pageRef: ComponentPublicInstance | null): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/modals/Users/EmailChangeModal.vue'));
  const modal = await modalController.create({
    component,
    presentingElement: pageRef?.$el,
    cssClass: 'custom-small-modal',
  });
  await modal.present();
};
//#endregion

//#region Feed
export const openVotesModal = async (postId: number, votesData: PollOptionsModel[], count: number): Promise<void> => {
  const votesShow = defineAsyncComponent(() => import('@/modals/Feed/VotesModal.vue'));
  const modal = await modalController.create({
    component: votesShow,
    cssClass: 'custom-votes-modal',
    componentProps: {
      postId,
      votesData,
      count,
    },
  });
  await modal.present();
};

export const openAnswersModal = async (eventId: number, answer: number): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/modals/Feed/AnswersModal.vue'));
  const modal = await modalController.create({
    component: component,
    cssClass: 'custom-votes-modal',
    componentProps: {
      eventId,
      userAnswer: answer,
    },
  });
  await modal.present();
};

export const openPostContextMenuModal = async (
  postData: PostModel,
  feedFlag: FeedFlagEnum,
  conversationsType?: string | FeedTypeEnum
): Promise<PostMenuActionEnum | undefined> => {
  const postMenu = defineAsyncComponent(() => import('@/components/Feed/FeedPostMenu.vue'));

  const modal = await modalController.create({
    component: postMenu,
    cssClass: 'custom-modal',
    componentProps: {
      postData,
      feedFlag,
      conversationsType,
    },
  });

  await modal.present();

  return modal.onDidDismiss().then(async (result: OverlayEventDetail<PostMenuActionEnum | undefined>) => result.data);
};

export const openPostCreateContextMenuModal = async (): Promise<PostTypeActionEnum | undefined> => {
  const postCreateMenu = defineAsyncComponent(() => import('@/components/Feed/FeedPostCreate/FeedPostCreateMenu.vue'));
  const modal = await modalController.create({
    component: postCreateMenu,
    cssClass: 'custom-modal',
    mode: 'md',
  });

  await modal.present();

  return modal.onDidDismiss().then(async (result: OverlayEventDetail<PostTypeActionEnum | undefined>) => result.data);
};

export const openPostCreateMobileModal = async (postType: PostTypeActionEnum, eventDate?: string): Promise<void> => {
  const postCreateMobile = defineAsyncComponent(() => import('@/modals/Feed/PostCreateMobileModal.vue'));
  const appStore = useAppStore();
  const modal = await modalController.create({
    component: postCreateMobile,
    mode: 'md',
    componentProps: {
      postType,
      eventDate,
    },
    cssClass: appStore.isMDWidth ? ['custom-medium-modal', 'auto-height'] : ['auto-full-height'],
    enterAnimation: _enterAnimation,
  });
  await modal.present();
};

export const openPostPreviewModal = async (post: PostModel): Promise<boolean | undefined> => {
  const postPreview = defineAsyncComponent(() => import('@/modals/Feed/PostPreviewModal.vue'));
  const modal = await modalController.create({
    component: postPreview,
    cssClass: 'custom-medium-modal-height',
    mode: 'md',
    componentProps: {
      post,
    },
  });
  await modal.present();
  return modal.onDidDismiss().then(async (result: OverlayEventDetail<boolean | undefined>) => {
    return result.data;
  });
};

export const openPostEditModal = async (postData: PostModel): Promise<boolean | undefined> => {
  const postEdit = defineAsyncComponent(() => import('@/modals/Feed/PostEditModal.vue'));
  const modal = await modalController.create({
    component: postEdit,
    mode: 'md',
    componentProps: {
      postData,
    },
  });
  await modal.present();
  return modal.onDidDismiss().then(async (result: OverlayEventDetail<boolean | undefined>) => {
    return result.data;
  });
};

export const openPostSendToExternalEmailModal = async (postId: number): Promise<void> => {
  const sendPost = defineAsyncComponent(() => import('@/modals/Feed/PostSendToExternalEmailModal.vue'));
  const modal = await modalController.create({
    component: sendPost,
    mode: 'md',
    componentProps: {
      postId,
    },
  });
  await modal.present();
};

export const openPostComplainModal = async (postId: number): Promise<void> => {
  const postComplain = defineAsyncComponent(() => import('@/modals/Feed/PostComplainModal.vue'));
  const modal = await modalController.create({
    component: postComplain,
    mode: 'md',
    componentProps: {
      postId,
    },
  });
  await modal.present();
};

export const openBadgePickerModal = async (): Promise<BadgeModel | undefined> => {
  const component = defineAsyncComponent(() => import('@/modals/Feed/BadgesModal.vue'));
  const modal = await modalController.create({
    component,
  });
  await modal.present();

  return modal.onDidDismiss().then(async (result: OverlayEventDetail<BadgeModel | undefined>) => {
    return result.data;
  });
};

export const openForceReadModal = async (): Promise<boolean | undefined> => {
  const appStore = useAppStore();
  const component = defineAsyncComponent(() => import('@/modals/Feed/FeedForceReadModal.vue'));
  const modal = await modalController.create({
    component,
    backdropDismiss: false,
    keyboardClose: false,
    id: 'force-to-read-modal',
    cssClass: appStore.isSMWidth ? ['custom-medium-modal', 'auto-height'] : ['auto-full-height'],
  });
  await modal.present();
  return modal.onDidDismiss().then(async (result: OverlayEventDetail<boolean | undefined>) => {
    return result.data;
  });
};
//#endregion

//#region Messenger
export const openMessengerContextMenuModal = async (
  ev: Event,
  message: MessageModel,
  currentId: number | undefined
): Promise<MessageActionEnum | undefined> => {
  const messengerMenu = defineAsyncComponent(() => import('@/modals/Messenger/MessengerContextMenuModal.vue'));

  const modal = await modalController.create({
    component: messengerMenu,
    cssClass: 'custom-modal',
    mode: 'md',
    componentProps: {
      message: message,
      isAuthor: message.authorId === currentId,
    },
  });

  await modal.present();

  return modal.onDidDismiss().then(async (result: OverlayEventDetail<MessageActionEnum | undefined>) => result.data);
};

export const openChatInfoModal = async (
  chain: MessageChainModel,
  initialActiveSection?: ChatModalEnum,
  customCallModal = false
): Promise<void> => {
  const chatInfo = defineAsyncComponent(() => import('@/modals/Messenger/ChatInfoModal.vue'));
  const modal = await modalController.create({
    component: chatInfo,
    mode: 'md',
    componentProps: {
      chain,
      initialActiveSection,
      customCallModal,
    },
  });
  await modal.present();
};

export const openChatModal = async (
  group: GroupModel
): Promise<{ id: number; type: MessengerChatTypeEnum } | undefined> => {
  const component = defineAsyncComponent(() => import('@/modals/Messenger/ChatModal.vue'));
  const modal = await modalController.create({
    component,
    componentProps: {
      isGroupCreate: true,
      group: group,
      title: useI18n().t('messenger.chatModal.callTitle'),
    },
  });
  await modal.present();

  return modal
    .onDidDismiss()
    .then(async (result: OverlayEventDetail<{ id: number; type: MessengerChatTypeEnum } | undefined>) => result.data);
};
//#endregion

//#region Groups
export const openGroupManageModal = async (groupData?: GroupModel): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/modals/Groups/GroupManageModal.vue'));
  const modal = await modalController.create({
    component,
    componentProps: {
      groupData,
    },
    id: 'groupManage',
  });
  await modal.present();
};

export const openGroupUserRequestsModal = async (groupData: GroupModel): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/modals/Groups/GroupUserRequestsModal.vue'));
  const modal = await modalController.create({
    component,
    componentProps: {
      groupData,
    },
  });
  await modal.present();
};

export const openGroupSelectModal = async (
  mode: GroupsFilterEnum,
  allowSelectAllNetwork: boolean,
  note?: string,
  userId?: number
): Promise<GroupModel | undefined | null> => {
  const component = defineAsyncComponent(() => import('@/modals/Groups/GroupSelectModal.vue'));
  const modal = await modalController.create({
    component,
    mode: 'md',
    componentProps: {
      mode,
      allowSelectAllNetwork,
      note,
      userId,
    },
  });
  await modal.present();
  return modal.onDidDismiss().then(async (result: OverlayEventDetail<GroupModel | undefined | null>) => {
    return result.data;
  });
};

//#endregion

//#region Files
export const openFileRelationsModal = async (): Promise<void> => {
  const fileRelations = defineAsyncComponent(() => import('@/components/File/FileRelations.vue'));
  const modal = await modalController.create({
    component: fileRelations,
    id: 'file_relations',
    mode: 'md',
  });
  await modal.present();
};

export const openFileFollowersModal = async (): Promise<void> => {
  const fileFollowers = defineAsyncComponent(() => import('@/modals/File/FileFollowersModal.vue'));
  const modal = await modalController.create({
    component: fileFollowers,
    id: 'file_followers',
    cssClass: 'custom-medium-modal',
    mode: 'md',
  });
  await modal.present();
};

export const openFileHistoryModal = async (): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/components/File/FileHistory.vue'));
  const modal = await modalController.create({
    component,
    id: 'file_history',
    cssClass: 'custom-medium-modal',
  });
  await modal.present();
};
//#endregion

//#region Docs
export const openDocsCreateFolderModal = async (group?: GroupModel): Promise<string | undefined> => {
  const component = defineAsyncComponent(() => import('@/modals/Docs/DocsCreateFolderModal.vue'));
  const modal = await modalController.create({
    component,
    componentProps: {
      group,
    },
  });
  await modal.present();
  return modal.onDidDismiss().then(async (result: OverlayEventDetail<string | undefined>) => {
    return result.data;
  });
};

export const openDocsUploadFileModal = async (
  // singleFile: boolean
  filesTypes: UploadFileTypes,
  isNewVersion?: boolean,
  file?: FileModel,
  isSmall?: boolean,
  group?: GroupModel
): Promise<DocModel[] | boolean | undefined> => {
  const component = defineAsyncComponent(() => import('@/modals/Docs/DocsUploadFileModal.vue'));
  const modal = await modalController.create({
    component,
    componentProps: {
      // singleFile
      filesTypes,
      isNewVersion,
      file,
      group,
    },
    cssClass: isSmall ? 'custom-extra-small-modal' : '',
  });
  await modal.present();

  return modal.onDidDismiss().then(async (result: OverlayEventDetail<DocModel[] | boolean | undefined>) => {
    return result.data;
  });
};

export const openDocsUploadFolderModal = async (): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/modals/Docs/DocsUploadFolderModal.vue'));
  const modal = await modalController.create({
    component,
  });
  await modal.present();
};

export const openDocsCreateFileModal = async (
  withAutoSave = false,
  groupId: number | null = null,
  folderId: number | null = null
): Promise<FileModel | null> => {
  const modal = await modalController.create({
    component: defineAsyncComponent(() => import('@/modals/Docs/DocsOfficeChooseType.vue')),
    componentProps: {
      autoSave: withAutoSave,
      groupId,
      folderId,
    },
    cssClass: 'custom-modal',
    mode: 'md',
  });

  await modal.present();

  const { data } = await modal.onDidDismiss();

  if (data) return data;

  return null;
};

export const openDocsAttachmentModal = async (
  groupData: GroupModel | UserGroupShortModel | null,
  withControllers?: boolean
): Promise<DocModel[] | undefined> => {
  const component = defineAsyncComponent(() => import('@/modals/Docs/DocsAttachmentFileModal.vue'));
  const modal = await modalController.create({
    component,
    componentProps: { groupData, withControllers },
    cssClass: 'custom-big-modal',
  });
  await modal.present();
  return modal.onDidDismiss().then(async (result: OverlayEventDetail<DocModel[] | undefined>) => {
    return result.data;
  });
};

export const openDocsFolderPreviewModal = async (folder: FolderModel): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/modals/Docs/DocsFolderPreviewModal.vue'));
  const modal = await modalController.create({
    component,
    cssClass: 'custom-big-modal',
    componentProps: { folder: folder },
  });
  await modal.present();
};

export const openDocsMainMenuModal = async (groupId: number | null): Promise<DocsMenuActionEnum | undefined> => {
  const component = defineAsyncComponent(() => import('@/components/Docs/DocsMainMenu.vue'));
  const modal = await modalController.create({
    component: component,
    cssClass: 'custom-modal',
    mode: 'md',
    componentProps: {
      groupId,
    },
  });
  await modal.present();

  return modal.onDidDismiss().then(async (result: OverlayEventDetail<DocsMenuActionEnum | undefined>) => result.data);
};

export const openDocsMoveFileModal = async (
  folderId: number | null
): Promise<{ folderId: number | null; groupId: number | null } | undefined> => {
  const component = defineAsyncComponent(() => import('@/modals/Docs/DocsMoveFileModal.vue'));
  const modal = await modalController.create({
    component,
    componentProps: { folderId },
  });
  await modal.present();

  return modal
    .onDidDismiss()
    .then(async (result: OverlayEventDetail<{ folderId: number | null; groupId: number | null } | undefined>) => {
      return result.data;
    });
};

export const openEntityShareModal = async (
  entity: FileModel | WikiModel | PostModel,
  type: ShareEntityType,
  action?: PostMenuActionEnum
): Promise<{ text: string; group?: GroupModel; userIds?: number[]; shareTo: PostShareToEnum } | undefined> => {
  const component = defineAsyncComponent(() => import('@/modals/Common/EntityShareModal.vue'));
  const modal = await modalController.create({
    component,
    componentProps: {
      entity,
      type,
      action,
    },
  });
  await modal.present();

  return modal
    .onDidDismiss()
    .then(
      async (
        result: OverlayEventDetail<
          { text: string; group?: GroupModel; userIds?: number[]; shareTo: PostShareToEnum } | undefined
        >
      ) => {
        return result.data;
      }
    );
};

export const openDocsCreateOfficeModal = async (
  type: string,
  autoSave: boolean,
  groupId?: number | null,
  folderId?: number | null
): Promise<FileModel | null | undefined> => {
  const component = defineAsyncComponent(() => import('@/modals/Docs/DocsCreateOffice.vue'));

  const modal = await modalController.create({
    component,
    componentProps: {
      type,
      autoSave,
      groupId,
      folderId,
    },
    cssClass: 'docs-create-document-title--modal',
  });

  await modal.present();
  const result: OverlayEventDetail<FileModel | null | undefined> = await modal.onDidDismiss();

  return result.data;
};
//#endregion

//#region Meet
export const openMeetRoomModal = async (roomId?: string): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/modals/Meet/MeetRoomModal.vue'));
  const modal = await modalController.create({
    component,
    componentProps: {
      roomId: roomId,
    },
    keyboardClose: false,
    cssClass: 'fullscreen',
    id: 'callUser',
  });

  await modal.present();
};

export const openMeetCallModal = async (
  title: string,
  image: MediaModel | null,
  avatarUrl: string
): Promise<string | undefined> => {
  const modal = await modalController.create({
    component: defineAsyncComponent(() => import('@/modals/Meet/MeetCallModal.vue')),
    componentProps: {
      title,
      image,
      avatarUrl,
    },
    cssClass: ['custom-small-modal-longer', 'auto-height'],
    keyboardClose: false,
    backdropDismiss: false,
    id: 'incomingCall',
  });

  await modal.present();

  return modal.onDidDismiss().then(async (result: OverlayEventDetail<string | undefined>) => {
    return result.data;
  });
};
//#endregion

//#region Network
export const openNetworkChooseModal = async (byUser: boolean): Promise<NetworkModel | undefined> => {
  const component = defineAsyncComponent(() => import('@/modals/Network/NetworkChooseModal.vue'));
  const modal = await modalController.create({
    component,
    componentProps: { byUser },
  });
  await modal.present();

  return modal.onDidDismiss().then(async (result: OverlayEventDetail<NetworkModel | undefined>) => {
    return result.data;
  });
};

export const openNetworkAcceptRulesModal = async (
  id: number | null,
  title: string | null,
  text: string | null
): Promise<boolean | undefined> => {
  const component = defineAsyncComponent(() => import('@/modals/Network/NetworkAcceptRulesModal.vue'));
  const modal = await modalController.create({
    component,
    backdropDismiss: false,
    keyboardClose: false,
    componentProps: {
      ruleId: id,
      title,
      text,
      isModal: true,
    },
    id: 'usage-rules-modal',
    cssClass: ['usage-rules--modal-container'],
  });
  await modal.present();
  return modal.onDidDismiss().then(async (result: OverlayEventDetail<boolean | undefined>) => {
    return result.data;
  });
};
//#endregion

//#region Task Management
export const openTaskManagementTaskModal = async (taskId: number, projectId: number): Promise<HTMLIonModalElement> => {
  const appStore = useAppStore();
  const component = defineAsyncComponent(() => import('@/modals/TaskManagement/TaskManagementTaskModal.vue'));
  const modal = await modalController.create({
    component,
    componentProps: { taskId, projectId },
    cssClass: appStore.isMDWidth
      ? ['custom-medium-modal', 'auto-height', 'ion-disable-focus-trap']
      : ['auto-full-height', 'ion-disable-focus-trap'],
    // https://ionicframework.com/docs/api/modal#focustrap
    // https://github.com/ionic-team/ionic-framework/issues/24646#issuecomment-2053585886
    // focusTrap: false,
  });
  await modal.present();

  return modal;
};

export const openTaskManagementMilestonesModal = async (
  cardData: TaskManagementTaskModel | null,
  milestoneData: TaskManagementMilestoneModel | null,
  projectId: number,
  onlyCreate: boolean
): Promise<TaskManagementMilestoneModel | undefined> => {
  const component = defineAsyncComponent(
    () => import('@/components/TaskManagement/Milestones/TaskManagementMilestonesModal.vue')
  );
  const modal = await modalController.create({
    component,
    componentProps: { cardData, milestoneData, projectId, onlyCreate },
  });
  await modal.present();
  return modal.onDidDismiss().then(async (result: OverlayEventDetail<TaskManagementMilestoneModel | undefined>) => {
    return result.data;
  });
};

export const openTaskManagementProjectSwitchModal = async (
  isPostCreation: boolean,
  isOnlyProjectCreation: boolean,
  withRouting?: boolean,
  groupId?: number
): Promise<void> => {
  const component = defineAsyncComponent(
    () => import('@/components/TaskManagement/Projects/TaskManagementProjectSwitchModal.vue')
  );
  const modal = await modalController.create({
    component,
    componentProps: {
      withRouting,
      groupId,
      isPostCreation,
      isOnlyProjectCreation,
    },
  });
  await modal.present();
};

export const openTaskManagementAttachmentsModal = async (taskId: number): Promise<void> => {
  const component = defineAsyncComponent(
    () => import('@/components/TaskManagement/Tasks/TaskManagementAttachmentsModal.vue')
  );
  const modal = await modalController.create({
    component,
    componentProps: { taskId },
  });
  await modal.present();
};
//#endregion

//#region Topics
export const openTagsPickerModal = async (
  selectedTags: TopicModel[],
  multiple: boolean,
  editable: boolean
): Promise<TopicModel[] | undefined> => {
  const component = defineAsyncComponent(() => import('@/components/Topics/TagsPickerModal.vue'));
  const modal = await modalController.create({
    component,
    componentProps: { selectedTags, multiple, editable },
  });
  await modal.present();
  return modal.onDidDismiss().then(async (result: OverlayEventDetail<TopicModel[] | undefined>) => {
    return result.data;
  });
};
//#endregion

//#region Office
export const openOfficeViewModal = async (props: object): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/modals/Office/NativeOfficeView.vue'));

  const modal = await modalController.create({
    component,
    cssClass: 'fullscreen',
    componentProps: props,
    id: 'office-view-modal',
  });

  await modal.present();
  const result: OverlayEventDetail<void> = await modal.onDidDismiss();
  return result.data;
};

export const openOfficeInfoModal = async (officeProps: OfficeProps): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/modals/Office/OfficeInfo.vue'));

  const modal = await modalController.create({
    component,
    componentProps: { officeProps },
    cssClass: isAnyMobile ? 'custom-modal' : 'custom-modal-wider',
    mode: 'md',
    canDismiss: true,
  });

  await modal.present();
};
//#endregion

//#region Custom Pages
export const openCustomPageCreateModal = async (): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/modals/CustomPages/CustomPageCreateModal.vue'));
  const modal = await modalController.create({
    component,
    cssClass: ['auto-height'],
  });
  await modal.present();
};

export const openCustomPageInfoModal = async (
  pageId: number,
  isGroupDashboard: boolean,
  groupId?: undefined | number
): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/modals/CustomPages/CustomPageInfoModal.vue'));
  const modal = await modalController.create({
    component,
    componentProps: { pageId, isGroupDashboard, groupId },
    cssClass: 'fullscreen',
  });
  await modal.present();
};

export const openCustomPageWidgetSettingsModal = async (
  pageId: number,
  widget: CustomPagesWidgetModel,
  widgetPath: WidgetPathModel
): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/modals/CustomPages/CustomPageWidgetSettingsModal.vue'));
  const customPagesHelper = useCustomPages();
  const modal = await modalController.create({
    component,
    componentProps: { pageId, widget, widgetPath },
    cssClass: customPagesHelper.getWidgetsListForModalStyling().includes(widget.systemName)
      ? ['custom-medium-modal', 'ion-disable-focus-trap']
      : ['custom-medium-modal', 'auto-height', 'ion-disable-focus-trap'],
    // https://ionicframework.com/docs/api/modal#focustrap
    // https://github.com/ionic-team/ionic-framework/issues/24646#issuecomment-2053585886
    // focusTrap: false,
  });
  await modal.present();
};
//#endregion

//#region Auth
/**
 * Opens a login form modal
 *
 * @todo Refactor to separate modal NOT same component
 * @todo Refactor to return something and handle it in context of this function consumer NOT in modal itself
 */
export const openLoginFormModal = async (): Promise<void> => {
  try {
    const component = defineAsyncComponent(() => import('@/components/Auth/Login/LoginForm.vue'));
    const modal = await modalController.create({
      component,
      componentProps: {
        isModal: true,
      },
      mode: 'md',
      cssClass: ['auto-height'],
    });
    await modal.present();
  } catch (e) {
    console.error('[ERROR] Error on componentLoginModal', e);
  }
};
//#endregion

//#region Ai Assistant
export const openAiChangeModeMenuModal = async (): Promise<AiModeEnum | undefined> => {
  const component = defineAsyncComponent(() => import('@/modals/AiAssistant/AiChangeModeMenuModal.vue'));
  const modal = await modalController.create({
    component,
    cssClass: 'custom-medium-modal',
  });
  await modal.present();
  return modal.onDidDismiss().then(async (result: OverlayEventDetail<AiModeEnum | undefined>) => {
    return result.data;
  });
};

/**
 * Opens a chat list modal
 *
 * @todo Refactor to return something and handle it in context of this function consumer NOT in modal itself
 */
export const openAiChatListModal = async (type: string): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/modals/AiAssistant/AiChatListModal.vue'));
  const modal = await modalController.create({
    component,
    componentProps: { type },
  });
  await modal.present();
};
//#endregion

//#region Settings
export const openUserSubscribeSettingsModal = async (): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/components/Settings/UserSubscribeSettingsModal.vue'));
  const modal = await modalController.create({
    component,
  });
  await modal.present();
};
//#endregion

//#region Admin
export const openAdminDomainManageModal = async (domain: DomainModel | null): Promise<boolean | undefined> => {
  const component = defineAsyncComponent(() => import('@/modals/Admin/AdminDomainManageModal.vue'));
  const modal = await modalController.create({
    component,
    componentProps: { domain },
  });
  await modal.present();
  return modal.onDidDismiss().then(async (result: OverlayEventDetail<boolean | undefined>) => {
    return result.data;
  });
};

export const openAdminRightMenuModal = async (): Promise<void> => {
  const component = defineAsyncComponent(() => import('@/modals/Admin/AdminRightMenuModal.vue'));
  const modal = await modalController.create({
    component,
  });
  await modal.present();
};

export const openAdminUserRoleModal = async (
  userId: number,
  userRoleId: UserRoleEnum
): Promise<boolean | undefined> => {
  const component = defineAsyncComponent(() => import('@/modals/Admin/AdminUserRoleModal.vue'));
  const modal = await modalController.create({
    component,
    componentProps: { userId, userRoleId },
    cssClass: 'custom-medium-modal',
  });
  await modal.present();
  return modal.onDidDismiss().then(async (result: OverlayEventDetail<boolean | undefined>) => {
    return result.data;
  });
};

export const openAdminHomePageSelectModal = async (
  homePage: HomePageModel | undefined
): Promise<HomePageModel | undefined> => {
  const component = defineAsyncComponent(() => import('@/modals/Admin/AdminHomePageSelectModal.vue'));
  const modal = await modalController.create({
    component,
    componentProps: { homePage },
  });
  await modal.present();
  return modal.onDidDismiss().then(async (result: OverlayEventDetail<HomePageModel | undefined>) => {
    return result.data;
  });
};

export const openAdminUserAccessModal = async (userId: number): Promise<boolean | undefined> => {
  const component = defineAsyncComponent(() => import('@/modals/Admin/AdminUserAccessModal.vue'));
  const modal = await modalController.create({
    component,
    componentProps: { userId },
    cssClass: 'custom-medium-modal',
  });
  await modal.present();
  return modal.onDidDismiss().then(async (result: OverlayEventDetail<boolean | undefined>) => {
    return result.data;
  });
};
//#endregion

//#region Wiki
/**
 * Opens a wiki followers modal
 *
 * @todo Refactor to return something and handle it in context of this function consumer NOT in modal itself
 */
export const openWikiFollowersModal = async (): Promise<void> => {
  const wikiFollowers = defineAsyncComponent(() => import('@/modals/Wikis/WikiFollowersModal.vue'));
  const modal = await modalController.create({
    component: wikiFollowers,
    id: 'wiki_followers',
    cssClass: 'custom-medium-modal',
    mode: 'md',
  });
  await modal.present();
};

export const openWikiRelationsModal = async (): Promise<WikiRelationsModel | undefined> => {
  const relations = defineAsyncComponent(() => import('@/modals/Wikis/WikiRelationsModal.vue'));
  const modal = await modalController.create({
    component: relations,
    mode: 'md',
  });
  await modal.present();
  const result: OverlayEventDetail<WikiRelationsModel | undefined> = await modal.onDidDismiss();
  return result.data;
};

/**
 * Opens a wiki creation modal
 *
 * @todo Refactor to separate modal NOT same view
 */
export const openWikiCreateModal = async (
  groupId: number | undefined,
  folderId: number | undefined,
  isModal: boolean
): Promise<number | undefined> => {
  const modal = await modalController.create({
    component: defineAsyncComponent(() => import('@/views/Wikis/WikiCreatePage.vue')),
    componentProps: {
      groupId: Number(groupId) || 0,
      folderId: Number(folderId) || 0,
      isModal,
    },
    cssClass: ['fullscreen', 'create-wiki-modal', 'ion-disable-focus-trap'],
  });
  await modal.present();
  const result: OverlayEventDetail<number | undefined> = await modal.onDidDismiss();
  return result.data;
};

export const openWikiTemplatesModal = async (title: string): Promise<WikiTemplateModel | undefined> => {
  const relations = defineAsyncComponent(() => import('@/modals/Wikis/WikiTemplatesModal.vue'));
  const modal = await modalController.create({
    component: relations,
    mode: 'md',
    componentProps: { title },
    cssClass: ['custom-medium-modal', 'auto-height'],
  });
  await modal.present();
  const result: OverlayEventDetail<WikiTemplateModel | undefined> = await modal.onDidDismiss();
  return result.data;
};

export const openWikiTemplateSaveModal = async (
  templateData: CreateWikiTemplateModel
): Promise<boolean | undefined> => {
  const relations = defineAsyncComponent(() => import('@/modals/Wikis/WikiTemplateSaveModal.vue'));
  const modal = await modalController.create({
    component: relations,
    componentProps: { templateData },
    mode: 'md',
    cssClass: ['auto-height'],
  });

  await modal.present();
  const result: OverlayEventDetail<boolean | undefined> = await modal.onDidDismiss();
  return result.data;
};

export const openWikiHistoryModal = async (id: number, fromComparePage?: boolean): Promise<number | undefined> => {
  const component = defineAsyncComponent(() => import('@/modals/Wikis/WikiHistoryModal.vue'));
  const modal = await modalController.create({
    component,
    cssClass: 'custom-medium-modal',
    componentProps: { id, fromComparePage },
  });
  await modal.present();
  const result: OverlayEventDetail<number | undefined> = await modal.onDidDismiss();
  return result.data;
};
//#endregion
