<template>
  <div class="poll">
    <div class="poll-title">
      <div class="poll-title text">
        <feed-text :text-data="pollData.mainHtml" :feed-flag="feedFlag" />
      </div>
    </div>
    <ion-list v-if="pollData.allowSeveralAnswers">
      <ion-item
        v-for="(option, index) in pollData.options"
        :key="`option_item_${index}`"
        class="poll-item"
        lines="none"
        mode="md"
        :disabled="disabledToVote"
      >
        <ion-checkbox
          mode="md"
          color="medium"
          :checked="option.isAnswered"
          :value="index"
          label-placement="start"
          @ionChange="voteCheckbox($event, option.isAnswered)"
          @click.stop
        >
          <div class="custom-text">{{ option.text }}</div>
          <div class="custom-percentage">{{ getPercentage(option) }}</div>
        </ion-checkbox>

        <div class="progress-wrapper">
          <ion-progress-bar :value="option.votesCount / pollData.votes" />
        </div>
        <ion-label slot="end" color="medium">
          {{ option.votesCount }}
        </ion-label>
      </ion-item>
    </ion-list>

    <ion-list v-if="!pollData.allowSeveralAnswers">
      <ion-radio-group allow-empty-selection :value="radioAnswer" @ionChange="voteRadio($event)" @click.stop>
        <ion-item
          v-for="(option, index) in pollData.options"
          :key="`option_item_${index}`"
          lines="none"
          class="poll-item"
          mode="md"
          :disabled="(isAnsweredFlag && !option.isAnswered) || disabledToVote"
        >
          <ion-radio mode="md" color="medium" :value="index" label-placement="start">
            <div class="custom-text">{{ option.text }}</div>
            <div class="custom-percentage">{{ getPercentage(option) }}</div>
          </ion-radio>
          <div class="progress-wrapper">
            <ion-progress-bar :value="option.votesCount / pollData.votes" />
          </div>
          <ion-label slot="end" color="medium">
            {{ option.votesCount }}
          </ion-label>
        </ion-item>
      </ion-radio-group>
    </ion-list>

    <div v-if="pollData.options.length === 0" class="null">
      {{ $t('feed.poll.nullAnswers') }}
    </div>

    <div v-if="pollData.allowAddOptions && isEdit === true" class="form">
      <form novalidate @submit.prevent="onSubmit">
        <form-input
          v-model:value="form.option"
          type="text"
          :placeholder="$t('feed.poll.addOption')"
          :required="true"
          @click.stop
        >
          <ion-icon slot="end" color="medium" :icon="icons.checkmarkOutline" @click.stop="onSubmit()" />
        </form-input>
        <div v-if="optionErrors.length > 0">
          <form-error name="optionErrors" :errors-array="optionErrors" />
        </div>
      </form>
    </div>
    <div class="poll-title button">
      <ion-button
        v-if="pollData.allowAddOptions"
        slot="end"
        mode="md"
        :disabled="disabledToVote"
        fill="clear"
        color="dark"
        class="add-poll-option"
        @click.stop="addOption()"
      >
        <ion-icon
          slot="icon-only"
          class="add-poll-option-icon"
          size="small"
          :icon="isEdit ? icons.closeOutline : icons.addOutline"
        />
        <ion-label
          ><h3>{{ $t('feed.poll.addOption') }}</h3></ion-label
        >
      </ion-button>
    </div>

    <ion-item lines="none" class="results">
      <ion-label>
        <span v-if="pollData.voters === 0 || pollData.anonymousMode === true">
          {{ $t('feed.voted', { n: pollData.voters }) }}
        </span>

        <span v-else class="votes" @click.stop="openVotesModal(postId, pollData.options, pollData.votes)">
          {{ $t('feed.voted', { n: pollData.voters }) }}
        </span>
        {{ pollData.anonymousMode ? `&#8729; ${$t('feed.poll.anonymous')}` : '' }}
        {{
          pollData.timeLimit !== null
            ? `&#8729; ${$t('feed.poll.until')}
            ${formatDateHelper(pollData.timeLimit, 'long')}
            `
            : ''
        }}
      </ion-label>
    </ion-item>
  </div>
</template>

<script lang="ts" setup>
import type { RadioGroupCustomEvent, CheckboxCustomEvent } from '@ionic/vue';
import {
  IonItem,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonCheckbox,
  IonIcon,
  IonButton,
  IonProgressBar,
} from '@ionic/vue';
import type { ErrorObject } from '@vuelidate/core';
import useVuelidate from '@vuelidate/core';
import { addOutline, checkmarkOutline, closeOutline } from 'ionicons/icons';
import type { ComputedRef, PropType } from 'vue';
import { computed, reactive, ref } from 'vue';

import { FormInput, FormError, FeedText } from '@/components';
import type { FeedFlagEnum } from '@/enums';
import { required, openVotesModal, DateHelper, formatDateHelper, useToasts } from '@/helpers';
import { useI18n } from '@/i18n';
import { usePostStore } from '@/store';
import type { PostPollModel, PollOptionsModel } from '@/types';

const { t } = useI18n();
const { showSonnerToast } = useToasts();
const postStore = usePostStore();

const icons = {
  addOutline,
  checkmarkOutline,
  closeOutline,
};
const props = defineProps({
  pollData: {
    type: Object as PropType<PostPollModel>,
    required: true,
  },
  postId: {
    type: Number,
    required: true,
  },
  feedFlag: {
    type: String as PropType<FeedFlagEnum>,
    required: true,
  },
  parentPostId: {
    type: Number || null,
    default: null,
  },
  disableVoting: {
    type: Boolean,
    default: false,
  },
});
const isEdit = ref<boolean>(false);
const pollData: ComputedRef<PostPollModel> = computed(() => props.pollData);
const postId: ComputedRef<number> = computed(() => props.postId);
const isLoading = ref<boolean>(false);

const disabledToVote: ComputedRef<boolean> = computed(() => {
  if (isLoading.value) return true;
  if (props.disableVoting) {
    return true;
  } else if (pollData.value.timeLimit !== null) {
    return new Date(DateHelper.getUtcNow()) > new Date(pollData.value.timeLimit) ? true : false;
  } else {
    return false;
  }
});

const rules = computed(() => ({
  option: {
    required,
  },
}));
const form = reactive<any>({
  option: '',
});

const getPercentage = (item: PollOptionsModel) => {
  const firstStep = pollData.value.votes / item.votesCount;
  if (item.votesCount === 0) {
    return '0%';
  } else {
    const answer = 100 / firstStep;
    const percentage = answer.toFixed() + '%';
    return percentage;
  }
};

const radioCurrentAnswer: ComputedRef<any> = computed(() => {
  if (props.pollData.options.length > 0) {
    for (let index = 0; index < props.pollData.options.length; index++) {
      const element = props.pollData.options[index];
      if (element.isAnswered === true) {
        return index;
      }
    }
  }
  return undefined;
});

const isAnsweredFlag: ComputedRef<boolean> = computed(() => {
  if (pollData.value.options.length > 0) {
    if (pollData.value.options.some((n: any) => n.isAnswered === true)) {
      return true;
    } else {
      return false;
    }
  }
  return false;
});

const optionErrors: ComputedRef<string[]> = computed(() => {
  const errors = v$.value.option.$errors.map((m: ErrorObject) => m.$message as string);
  return [...errors];
});

const addOption = () => {
  isEdit.value = !isEdit.value;
  form.option = '';
  v$.value.$reset();
};

const v$ = useVuelidate(rules, form);
const onSubmit = async () => {
  const isValid = await v$.value.$validate();
  if (isValid) {
    const data = { id: postId.value, option: form.option };
    if (await postStore.pollAddOption(data, props.parentPostId)) {
      addOption();
    } else {
      showSonnerToast(t('feed.poll.newOptionNotAdded'), false);
    }
  }
};

const radioAnswer = ref<number | undefined>(radioCurrentAnswer.value);
const voteRadio = async (e: RadioGroupCustomEvent) => {
  isLoading.value = true;

  radioAnswer.value = e.detail.value;

  if (e.detail.value === undefined) {
    const pollOptionIndex = radioCurrentAnswer.value;
    if (~pollOptionIndex) {
      const data = { messageId: postId.value, optionIndex: pollOptionIndex };
      if (!(await postStore.pollRemoveVote(data, props.parentPostId))) {
        showSonnerToast(t('feed.answerNotDeleted'), false);
        radioAnswer.value = radioCurrentAnswer.value;
      }
    }
  } else if (
    radioAnswer.value !== undefined &&
    radioAnswer.value !== null &&
    radioAnswer.value !== radioCurrentAnswer.value
  ) {
    const data = { messageId: postId.value, optionIndex: e.detail.value };
    if (!(await postStore.pollAddVote(data, props.parentPostId))) {
      showSonnerToast(t('feed.answerNotSaved'), false);
    }
  }
  isLoading.value = false;
};

const voteCheckbox = async (e: CheckboxCustomEvent, isAnswered: boolean) => {
  isLoading.value = true;
  const data = { messageId: postId.value, optionIndex: e.detail.value };
  if (e.detail.checked && !isAnswered) {
    if (!(await postStore.pollAddVote(data, props.parentPostId))) {
      showSonnerToast(t('feed.answerNotSaved'), false);
    }
  } else if (!e.detail.checked && isAnswered) {
    if (!(await postStore.pollRemoveVote(data, props.parentPostId))) {
      showSonnerToast(t('feed.answerNotDeleted'), false);
    }
  }
  isLoading.value = false;
};
</script>
<style scoped lang="scss">
.add-poll-option {
  text-transform: none;
}
.add-poll-option-icon {
  padding-right: 10px;
}
.poll {
  position: relative;
  border-radius: 4px;
}
ion-item.poll-item {
  margin-bottom: 0.5rem;
  --padding-end: 0;
  --padding-start: 0;
}
.poll-title {
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.poll-title.button ion-button {
  --padding-end: 0;
  --padding-start: 0;
  --padding-top: 0;
  margin: 0 0.5rem 0 0;
}
.poll .poll-text {
  width: 100%;
  z-index: 1;
}
.poll .poll-text-block {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background: transparent;
}
.poll .progress-wrapper {
  position: absolute;
  padding-right: app-padding(2xl);
  width: 100%;
  height: 100%;
}
.poll .poll-text-block ion-label.option {
  white-space: normal;
}
.poll .poll-text-block ion-label.percentage {
  white-space: nowrap;
  overflow: unset;
}
.poll .votes:hover {
  cursor: pointer;
  opacity: 0.7;
}
.poll ion-progress-bar {
  height: 100%;
  border-radius: 4px;
  --background: var(--ion-color-custom-element-lighter);
  --progress-background: var(--ion-color-custom-element-darker);
}
ion-list {
  background-color: transparent;
  border-radius: 4px;
}
ion-list ion-item {
  --background: transparent;
  --ripple-color: var(--ion-color-medium);
}
.poll .form {
  display: flex;
  justify-content: start;
  width: 100%;
  padding: 0.5rem;
}
.poll .form ion-item ion-icon:hover {
  cursor: pointer;
  color: var(--ion-color-intra);
}
.poll .null {
  background-color: transparent;
  display: flex;
  justify-content: center;
  width: 100%;
}
.poll ion-item.results {
  --background: transparent;
  border-radius: 4px;
  --padding-start: 0;
  --padding-top: 0;
}
.poll ion-item.results ion-label {
  white-space: normal;
}
.poll ion-item.results ion-label span.votes {
  text-decoration: underline;
  user-select: none;
}
ion-radio::part(container) {
  display: none;
}
ion-checkbox::part(container) {
  display: none;
}
ion-item.poll-item.item-disabled {
  opacity: 0.9;
}
ion-item.poll-item.item-disabled ion-progress-bar {
  opacity: 0.7;
}

.custom {
  &-text {
    position: relative;
    margin-right: calc(app-padding(sm) * 5);
    margin-left: app-padding(md);
    word-break: normal;
    white-space: wrap;
    text-align: justify;
  }
  &-percentage {
    position: absolute;
    top: 0;
    right: app-padding(sm);
    display: flex;
    align-items: center;
    height: 100%;
  }
}
</style>
