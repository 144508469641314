import { defineStore } from 'pinia';
import {
  type ErrorMessageModel,
  LinkApiViewModel,
  PostModelWithLink,
  type ResponseErrorModel,
  type ResponseLinksPreviewModel,
} from '@/types';
import { $api } from '@/services';
import { cloneDeep } from 'lodash';

interface LinksStore {
  errors: ErrorMessageModel[];
  currentLinksPreviewData: LinkApiViewModel[];
  currentInternalPostLinksPreviewData: PostModelWithLink[];
  currentInternalPostLinks: string[];
}

export const useLinksStore = defineStore({
  id: 'links',
  state: (): LinksStore => ({
    errors: [],
    currentLinksPreviewData: [],
    currentInternalPostLinksPreviewData: [],
    currentInternalPostLinks: [],
  }),
  getters: {},
  actions: {
    setCurrentLinksPreviewData(data: LinkApiViewModel[]): void {
      this.currentLinksPreviewData = data;
    },
    setCurrentInternalPostLinksPreviewData(data: PostModelWithLink[]): void {
      this.currentInternalPostLinksPreviewData = data;
    },
    setCurrentInternalPostLinks(data: string[]): void {
      this.currentInternalPostLinks = data;
    },
    resetLinksPreview(): void {
      this.currentLinksPreviewData = [];
      this.currentInternalPostLinksPreviewData = [];
      this.currentInternalPostLinks = [];
    },
    filterCurrentLinksPreviewData(url: string): void {
      this.currentLinksPreviewData = this.currentLinksPreviewData.filter((n) => n.url !== url);
    },
    filterCurrentInternalPostLinksPreviewData(id: number): void {
      this.currentInternalPostLinksPreviewData = this.currentInternalPostLinksPreviewData.filter((n) => n.id !== id);
    },
    filterCurrentInternalPostLinks(url: string): void {
      this.currentInternalPostLinks = this.currentInternalPostLinks.filter((n) => n !== url);
    },
    async linksPreviewCreate(urls: string[]): Promise<LinkApiViewModel[]> {
      this.errors = [];
      const response = await $api.post.linksPreviewCreate(urls);

      if (response.statusCode === 200) {
        const model = response as ResponseLinksPreviewModel;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return [];
    },
  },
});
