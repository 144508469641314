<template>
  <div
    class="block"
    :class="{
      'modal-view': modalView,
      announcement: postData.messageType === FeedTypeEnum.Announcement,
    }"
  >
    <div class="information-bar">
      <feed-user-item
        v-if="postData.messageType !== FeedTypeEnum.Announcement"
        :group="postData.group"
        :author="postData.author"
        :initiator="postData.initiator"
        :created-at="postData.createdAt"
        :planned-date="postData.plannedPostData?.plannedDate"
        :message-type="postData.messageType"
        :is-pinned="postData.isPinned"
      />
    </div>
    <div class="message">
      <feed-text
        v-if="postData.messageType === FeedTypeEnum.Text"
        class="text-content"
        :text-data="postData.bodyHtml"
        :feed-flag="feedFlag"
      />

      <feed-users
        v-if="postData.ccUsers.length > 0 && postData.messageType !== FeedTypeEnum.Event"
        :users-data="postData.ccUsers"
      />

      <feed-announcement
        v-if="postData.messageType === FeedTypeEnum.Announcement"
        :announcement-data="postData"
        :feed-flag="feedFlag"
      >
        <div class="information-bar">
          <feed-user-item
            :group="postData.group"
            :author="postData.author"
            :initiator="postData.initiator"
            :created-at="postData.createdAt"
            :planned-date="postData.plannedPostData?.plannedDate"
            :message-type="postData.messageType"
            :is-pinned="postData.isPinned"
          />
        </div>
      </feed-announcement>
    </div>
    <feed-shared-messages
      v-if="postData.sharedMessages.count > 0"
      :shared-messages-data="postData.sharedMessages"
      :feed-flag="feedFlag"
      :parent-post-id="postData.id"
    />
    <feed-badge
      v-if="postData.messageType === FeedTypeEnum.Badge && postData.attachedBadge !== null"
      :badge-data="postData.attachedBadge"
      :text="postData.bodyHtml"
      :feed-flag="feedFlag"
    />

    <feed-idea
      v-if="postData.ideaData !== null"
      :idea-data="postData.ideaData"
      :likes-data="postData.likes"
      :feed-flag="feedFlag"
      :disable-status-update="true"
      :post-id="postData.id"
    />

    <feed-event
      v-if="eventData !== null"
      :event-data="eventData"
      :users="postData.ccUsers"
      :text-data="postData.bodyHtml"
      :post-id="postData.id"
      :author="postData.author"
      :feed-flag="feedFlag"
      :preview-mode="true"
      @onEventDataChange="onEventDataChange"
    />

    <feed-poll
      v-if="postData.pollData !== null"
      :poll-data="postData.pollData"
      :post-id="postData.id"
      :feed-flag="feedFlag"
      :disable-voting="true"
    />

    <feed-task
      v-if="postData.messageType === FeedTypeEnum.Task && postData.taskData"
      :task-data="postData.taskData"
      :post-id="postData.id"
      :feed-flag="feedFlag"
      :preview-mode="true"
    />

    <div v-if="postData.messageType !== FeedTypeEnum.Task">
      <feed-wiki
        v-if="postData.attachedWikis.count > 0"
        :wiki-data="postData.attachedWikis.data"
        :post-created-at="postData.createdAt"
      />

      <feed-files
        v-if="postData.attachedFiles.count > 0"
        :files-data="postData.attachedFiles.data"
        :post-created-at="postData.createdAt"
      />

      <feed-files
        v-if="postData.plannedPostData !== null && postData.plannedPostData?.tempFiles?.length"
        :files-data="postData.plannedPostData.tempFiles"
        :post-created-at="postData.createdAt"
      />

      <div v-for="link in getAttachedLinks(postData)" :key="`link_${link.id}`" class="mt-lg">
        <post-preview
          v-if="isPostGuard(link)"
          class="mb-lg"
          :post="link"
          :feed-flag="FeedFlagEnum.SharedPost"
          :modal-view="false"
        />
        <feed-link v-else class="mb-lg" :link-data="link" />
      </div>
    </div>
    <div class="controls">
      <feed-controls-buttons
        :access="postData.access"
        :preview-mode="true"
        :disabled-buttons="true"
        :data="postData"
        :type-for-buttons="FeedTypeForButtonsEnum.Post"
        :feed-flag="feedFlag"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ComputedRef, PropType } from 'vue';
import { computed, ref } from 'vue';

import {
  FeedAnnouncement,
  FeedBadge,
  FeedControlsButtons,
  FeedEvent,
  FeedFiles,
  FeedIdea,
  FeedPoll,
  FeedTask,
  FeedText,
  FeedUserItem,
  FeedUsers,
  FeedWiki,
  FeedSharedMessages,
  FeedLink,
  PostPreview,
} from '@/components';
import { FeedFlagEnum, FeedTypeEnum, FeedTypeForButtonsEnum } from '@/enums';
import type { PostModel, PostEventDataModel, LinkApiViewModel } from '@/types';
import { isPostGuard } from '@/helpers/guards';
import { isInternalPostURL } from '@/helpers';
import { usePostStore } from '@/store';

// Props
const props = defineProps({
  post: {
    type: Object as PropType<PostModel>,
    required: true,
  },
  eventData: {
    type: [Object, null] as PropType<PostEventDataModel | null>,
    default: () => null,
  },
  feedFlag: {
    type: String as PropType<FeedFlagEnum>,
    default: FeedFlagEnum.FeedPage,
  },
  modalView: {
    type: Boolean,
    default: true,
  },
});

const postStore = usePostStore();

// Refs
const eventData = ref<PostEventDataModel | null>(props.post.eventData);

const postData: ComputedRef<PostModel> = computed(() => props.post);

// Methods
const getAttachedLinks = (post: PostModel): Array<LinkApiViewModel | PostModel> => {
  return post.attachedLinks.data.map((link) => {
    if (isInternalPostURL(link.url)) {
      const urlParts = link.url.split('/');
      const postId = +urlParts[urlParts.indexOf('post') + 1];
      return postStore.getPostById(postId);
    }
    return link;
  });
};

const onEventDataChange = (postId: number, attending: number) => {
  if (eventData.value) {
    const attendingsCount = eventData.value.attendingsCount + 1;
    eventData.value = Object.assign(eventData.value, {
      answer: attending,
      attendingsCount: attendingsCount,
    });
  }
};
</script>
<style scoped lang="scss">
.block {
  background: var(--ion-color-light-background-contrast);
  border-radius: app-radius(md);
  padding: app-padding(lg);
  border: 1px solid var(--ion-color-custom-element-darker);
}

.modal-view {
  margin: 0 app-padding(md);
}

.post-page .block {
  margin-bottom: 0;
}
.text-content {
  margin-top: 0.5rem;
}
.information-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.controls {
  margin-top: app-padding(lg);
}
</style>
