import * as Sentry from '@sentry/capacitor';
import * as SentrySibling from '@sentry/vue';
import type { App } from 'vue';

import packageJson from '../../package.json';

import { useErrors, useUserFlow } from '@/helpers';
import { useUserStore } from '@/store';

export const initSentry = (app: App<Element>): void => {
  try {
    console.log('Sentry init is', import.meta.env.MODE);
    const userStore = useUserStore();
    const userFlow = useUserFlow();
    const id = userStore?.current?.id;
    const currentUser = {
      name: (!id ? userFlow?.localUser?.fullName : userStore.getUserProfile(id).fullName) ?? 'Anonymous',
      email: (!id ? userFlow?.localUser?.email : userStore.getUserProfile(id).email) ?? 'Anonymous',
    };

    // https://docs.sentry.io/platforms/javascript/guides/vue/user-feedback/configuration/
    /** @note Feedback integration is temporarily disabled */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const feedback = SentrySibling.feedbackIntegration({
      autoInject: false,
      showBranding: false,
      colorScheme: 'system',
      isNameRequired: true,
      isEmailRequired: true,
      showName: true,
      showEmail: true,
      useSentryUser: currentUser,
      buttonLabel: 'Report a Bug',
      formTitle: 'Report a Bug',
      submitButtonLabel: 'Send Bug Report',
      cancelButtonLabel: 'Cancel',
      nameLabel: 'Name',
      namePlaceholder: 'Your Name',
      emailLabel: 'Email',
      isRequiredLabel: '(required)',
      messageLabel: 'Description',
      messagePlaceholder: `What's the bug? What did you expect?`,
      successMessageText: 'Thank you for your report!',
      onSubmitSuccess: () => {
        useErrors().handleSuccessfulSubmit();
      },
      onSubmitError: async () => {
        console.error('Feedback form error');
      },
    });

    // https://docs.sentry.io/platforms/javascript/guides/vue/session-replay/configuration/
    /** @note Replay integration is temporarily disabled */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const replay = SentrySibling.replayIntegration({
      stickySession: true,
      maskAllText: import.meta.env.DEV ? false : true,
      maskAllInputs: import.meta.env.DEV ? false : true,
      blockAllMedia: import.meta.env.DEV ? false : true,
      networkDetailAllowUrls: [/.*/],
      networkRequestHeaders: [
        'Accept-Language',
        'Authorization',
        'BrowserPlatforms',
        'CapacitorPlatform',
        'IsNativePlatform',
        'ApiVersion',
      ],
      networkResponseHeaders: [],
    });

    // https://docs.sentry.io/platforms/javascript/guides/vue/configuration/integrations/browsertracing/
    const browserTracing = SentrySibling.browserTracingIntegration({
      idleTimeout: 1000,
    });

    Sentry.init(
      {
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        release: `intra-ionic@${packageJson.version}${import.meta.env.VITE_APP_STAGE}`,
        dist: `${packageJson.build}`,
        environment: import.meta.env.DEV ? 'dev' : 'production',
        logErrors: true,
        integrations: [browserTracing],
        /*
        ! Feedback and Replay integrations are temporarily disabled
        integrations: [feedback, replay, browserTracing],
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: import.meta.env.DEV ? 0 : 0.5,
        replaysOnErrorSampleRate: 0,
        */
        trackComponents: true,
        tracesSampleRate: 1.0,
        beforeSend(event) {
          useErrors().setSentryEvent(event);
          return event;
        },
      },
      SentrySibling.init
    );
  } catch (error) {
    console.error('Error initializing Sentry:', error);
  }
};

export { Sentry, SentrySibling };
