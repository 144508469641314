import { IndexedDBStoresEnum } from '@/enums';
import { isBlob } from './guards';
import { useIDB } from './useIDBHelper';

type IImageCacheEntry = {
  blob: Blob;
  timestamp: number;
};

type IUseImages = {
  /**
   * Count cached images
   * @readonly
   */
  count: number;
  /**
   * Size cached images in Bytes
   * @readonly
   */
  size: number;
  /** Use it to clear all stored images */
  clearImages: () => Promise<void>;
  /**
   * Use it to fetch an image with a unique key and a function that fetches the image Blob
   * Function can hit cache | pending promise | fetch new promise
   * To avoid multiple fetches of the same image, Deferred (promise) pattern is used as well as Higher-Order Functions (passing a function as an argument)
   * It then caches the image and returns it's URL
   *
   * @param key Unique key for the image request
   * @param fetchFunction Function that fetches the image Blob
   * @returns URL of the image
   */
  fetchImage: (key: string, fetchFunction: () => Promise<Blob>) => Promise<string>;
  /**
   * Revalidate cache by removing expired items from IndexedDB.
   * Deletes cached items based on expiration threshold (CACHE_EXPIRATION_MS).
   */
  revalidateCache: () => Promise<void>;
  /**
   * ???
   *
   * @param canvas ???
   * @param image ???
   * @todo Add type
   */
  updateImageAfterCropping: (canvas: any, image: Blob) => Promise<File>;
};

let instance: IUseImages | null = null;

export function useImages(): IUseImages {
  if (instance) return instance;

  //#region Variables
  let count = 0;

  let size = 0;

  const inMemoryCache = new Map<string, Blob>();

  const pendingRequests = new Map<string, Promise<Blob>>();

  /** @note Size threshold in bytes to store in IndexedDB = 50KB in bytes */
  const SIZE_THRESHOLD = 50 * 1024;

  /** @note Cache expiration threshold = 1 week in milliseconds */
  const CACHE_EXPIRATION_MS = 7 * 24 * 60 * 60 * 1000;
  //#endregion

  //#region Private methods
  /**
   * Recalculates and updates the cached image count and total size
   * by examining both In-memory and IndexedDB caches.
   *
   * First, calculate In-memory cache info
   * Then, calculate IndexedDB cache info
   * Finally, update total count and size
   */
  const _setCacheInfo = async (): Promise<void> => {
    const memoryCount = inMemoryCache.size;
    const memorySize = Array.from(inMemoryCache.values()).reduce((total, blob) => total + blob.size, 0);

    const db = await useIDB([IndexedDBStoresEnum.Images]);
    const dbKeys = await db.getAllKeys('images');
    const dbSize = (await Promise.all(dbKeys.map((key) => db.get('images', key)))).reduce(
      (total, item) => (item ? total + item.blob.size : total),
      0
    );

    count = memoryCount + dbKeys.length;
    size = memorySize + dbSize;
  };

  /** @note Upon module instantiation, initialize count and size immediately */
  _setCacheInfo().catch((error) => console.error('[ERROR] Failed to initialize cache info', error));
  //#endregion

  //#region Public methods
  const clearImages = async (): Promise<void> => {
    console.log(`[INFO] ImagesHelper: clearImages()`); //! DEBUG
    count = 0;
    size = 0;

    inMemoryCache.clear();

    pendingRequests.clear();

    const db = await useIDB([IndexedDBStoresEnum.Images]);
    try {
      await db.clear('images');
    } catch (e) {
      console.error('[ERROR] Failed to clear IndexedDB', e);
    }
  };

  const fetchImage = async (key: string, fetchFunction: () => Promise<Blob>): Promise<string> => {
    /** @note Find the hash key for the server-side key */
    if (!key) {
      console.warn('[WARN] Failed to fetch image: key is required');
      return '';
    }

    /** @note Load from In-memory if found */
    try {
      if (inMemoryCache.has(key)) {
        console.log(`[INFO] ImagesHelper: fetchImage(${key}) - found in In-memory`); //! DEBUG

        const memoryBlob = inMemoryCache.get(key);
        if (!memoryBlob || !isBlob(memoryBlob)) throw new Error('Memory blob is undefined or not a Blob');

        return URL.createObjectURL(memoryBlob);
      }
    } catch (e) {
      console.error(`[ERROR] Failed to get image from In-memory for key ${key}`, e);
    }

    /** @note Load from IndexedDB if found */
    try {
      const db = await useIDB([IndexedDBStoresEnum.Images]);
      const dbImageEntry: IImageCacheEntry | undefined = await db.get('images', key);
      const dbBlob = dbImageEntry?.blob;
      if (dbBlob && isBlob(dbBlob)) {
        console.log(`[INFO] ImagesHelper: fetchImage(${key}) - found in IndexedDB`); //! DEBUG

        /**
         * @note We are not setting blob from `IndexedDB` to `In-memory cache`, it can be a memory leak
         * `IndexedDB` contains only those images that have size > `SIZE_THRESHOLD`, bigger than 50KB
         * So we definitely should not set it to `In-memory cache`
         */
        // inMemoryCache.set(key, dbBlob);

        return URL.createObjectURL(dbBlob);
      }
    } catch (e) {
      console.error(`[ERROR] Failed to get image from IndexedDB for key ${key}`, e);
    }

    try {
      /** @note Wait for pending request if found */
      if (pendingRequests.has(key)) {
        console.log(`[INFO] ImagesHelper: fetchImage(${key}) - pending hit`); //! DEBUG

        const pendingBlob = await pendingRequests.get(key);
        if (!pendingBlob || !isBlob(pendingBlob)) throw new Error('Pending blob is undefined or not a Blob');

        return URL.createObjectURL(pendingBlob);
      }
    } catch (e) {
      console.error(`[ERROR] Failed to get image from pendingRequests for key ${key}`, e);
    }

    /** @note Initiate a new fetch request */
    const fetchPromise = fetchFunction()
      .then(async (blob): Promise<Blob> => {
        if (blob.size > SIZE_THRESHOLD) {
          const db = await useIDB([IndexedDBStoresEnum.Images]);
          try {
            const newImageEntry: IImageCacheEntry = { blob, timestamp: Date.now() };
            await db.put('images', newImageEntry, key);
            console.log(`[INFO] ImagesHelper: fetchImage(${key}) - stored in IndexedDB`); //! DEBUG
          } catch (e) {
            console.error(`[ERROR] Failed to store image entry in IndexedDB for key ${key}`, e);
          }
        } else {
          inMemoryCache.set(key, blob);
          console.log(`[INFO] ImagesHelper: fetchImage(${key}) - stored in memory cache`); //! DEBUG
        }

        /** @note Delete key from pendingRequests */
        pendingRequests.delete(key);

        /** @note Update count and size after fetching */
        await _setCacheInfo();

        return blob;
      })
      .catch((e) => {
        console.error(`[ERROR] Failed to fetch image for key: ${key}`, e);
        pendingRequests.delete(key);
        throw e;
      });

    /** @note Store key to pendingRequests */
    pendingRequests.set(key, fetchPromise);

    /** @note Wait for fetchPromise to resolve with blob */
    const blob = await fetchPromise;

    return URL.createObjectURL(blob);
  };

  const revalidateCache = async (): Promise<void> => {
    const db = await useIDB([IndexedDBStoresEnum.Images]);

    try {
      const tx = db.transaction('images', 'readwrite');
      const store = tx.objectStore('images');

      const now = Date.now();
      const expiredKeys: string[] = [];

      /** @note Collect expired keys */
      for await (const cursor of store) {
        const entry = cursor.value;
        if (now - entry.timestamp > CACHE_EXPIRATION_MS) {
          expiredKeys.push(cursor.key as string);
        }
      }

      /** @note Delete expired keys */
      if (expiredKeys.length > 0) {
        console.log(`[INFO] Collected ${expiredKeys.length} expired keys, deleting...`); //! DEBUG
        expiredKeys.forEach(async (key) => {
          try {
            await store.delete(key);
          } catch (e) {
            console.error(`[ERROR] Failed to delete expired item with key: ${key}`, e);
          }
        });
        console.log(`[INFO] Deleted ${expiredKeys.length} expired keys successfully`); //! DEBUG
      }

      /** @note Update count and size after revalidation */
      await _setCacheInfo();
    } catch (e) {
      console.error('[ERROR] Failed to revalidate cache', e);
    }
  };

  /** @note Revalidate cache on module instantiation */
  revalidateCache().catch((error) => console.error('[ERROR] Failed to revalidate cache', error));

  const updateImageAfterCropping = (canvas: any, image: Blob): Promise<File> => {
    console.log(`[INFO] ImagesHelper: updateImageAfterCropping()`); //! DEBUG
    return new Promise((resolve, reject) => {
      const imageType = image.type;
      const imageExtension = imageType.split('/')[1];

      canvas.toBlob((blob: Blob | null) => {
        if (blob) {
          const filename = `image.${imageExtension}`;
          const changedImage = new File([blob], filename, { type: imageType });
          resolve(changedImage);
        } else {
          reject(new Error('Failed to create Blob from canvas'));
        }
      }, imageType);
    });
  };
  //#endregion

  instance = {
    get count() {
      return count;
    },
    get size() {
      return size;
    },
    clearImages,
    fetchImage,
    revalidateCache,
    updateImageAfterCropping,
  };

  return instance;
}
