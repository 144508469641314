import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { LocalNotifications } from '@capacitor/local-notifications';
import { PushNotifications } from '@capacitor/push-notifications';
import type { ActionPerformed, PushNotificationSchema } from '@capacitor/push-notifications';
import { Badge } from '@capawesome/capacitor-badge';
import { isPlatform } from '@ionic/vue';
import { MessageTypeEnum, NotificationsPushActionsEnum, PushType, WikiActionEnum } from '@/enums';
import { htmlToText, isNativeMobile, useLoading, useTaskManagement, useToasts, useWiki } from '@/helpers';
import { useI18n } from '@/i18n';
import router, { ROUTES_NAME } from '@/router';
import { $api } from '@/services';
import type { AppState } from '@/store';
import { useAppStore, useChatStore, useMessengerStore, useNotificationsStore, useProjectsStore } from '@/store';
import type { MessageModel, PushModel } from '@/types';

type PushNotificationHandler = (data: PushModel) => Promise<void>;

type PushNotificationLogType =
  | 'error'
  | 'none'
  | 'note'
  | 'message'
  | 'group'
  | 'user'
  | 'external-url'
  | 'wiki'
  | 'file';

type IUseNotifications = {
  /**
   * @description Initialize local notifications
   * @see https://capacitorjs.com/docs/apis/local-notifications
   */
  initLocalNotifications(): Promise<void>;
  /**
   * @description Schedule local notification to be called
   * @see https://capacitorjs.com/docs/apis/local-notifications#schedule
   * @todo Rewrite to be more abstract to take any type of notification and schedule it
   */
  scheduleLocalNotification(newMess: MessageModel): Promise<void>;
  /**
   * @description Cancel local pending notifications
   * @see https://capacitorjs.com/docs/apis/local-notifications#cancel
   */
  cancelLocalNotifications: (chainId: number, count?: number) => Promise<void>;
  /**
   * @description Reset badge count to 0
   */
  resetBadgeCount(): Promise<void>;
  /**
   * @description Set unread notifications count
   * @see notifications from src/store/notification.pinia.ts
   */
  setBadgeCount(): Promise<void>;
  /**
   * @description Initialize push notifications
   * @see https://capacitorjs.com/docs/apis/push-notifications
   */
  initPushNotifications(): Promise<void>;
  /**
   * @description Cancel push notifications
   */
  cancelPushNotifications(): Promise<void>;
  /**
   * @description Handles the click event on the notification on NotificationsPage - /notifications
   * @see src/views/Notifications/NotificationsPage.vue
   */
  handleNotificationClick(id: number, isRead: boolean, type: string, entityId: number): Promise<void>;
};

let pushInitialized = false;

export function useNotifications(): IUseNotifications {
  //#region Helpers
  const { t } = useI18n();
  //#endregion

  //#region Private methods
  const _updateBadgeCount = async () => {
    await useNotificationsStore().unreadNotifications();
    const notificationsCount = useNotificationsStore().getUnreadNotificationsCount;

    await useMessengerStore().updateUnreadCounter();
    const messagesCount = useMessengerStore().getUnreadMessagesCount;

    const total = notificationsCount + messagesCount;
    const badgeCount = total > 99 ? 99 : total;
    await Badge.set({ count: badgeCount });
  };

  const _getMessageBody = (newMess: MessageModel): string => {
    const handleQuoteMessage = (message: MessageModel) => {
      return message?.originalMessage ? t('messenger.chatPage.forwarded') : '';
    };

    const handleForwardMessage = () => {
      return t('messenger.chatPage.forwarded');
    };

    const handleDefaultMessage = (message: MessageModel) => {
      const withFiles =
        message.attachedFiles.count > 0 ||
        (message?.forwardedAttachedFiles && message.forwardedAttachedFiles.count > 0);
      return message.bodyHtml ? htmlToText(message.bodyHtml) : withFiles ? t('messenger.messengerSend.file') : '';
    };

    switch (newMess.messageType) {
      case MessageTypeEnum.Quote:
        return handleQuoteMessage(newMess);
      case MessageTypeEnum.Forward:
      case MessageTypeEnum.ForwardOld:
        return handleForwardMessage();
      default:
        return handleDefaultMessage(newMess);
    }
  };

  const _logAndShowToast = async (message: string, type: PushNotificationLogType): Promise<void> => {
    console.log(`[INFO] ${type}: `, message);
    useToasts().showSonnerToast(message, false);
  };

  const _isBadgeSupported = async (): Promise<boolean> => {
    if (!isNativeMobile || !(await Badge.isSupported())) {
      return false;
    }
    return true;
  };
  //#endregion

  //#region Handlers
  const _handleLocalNotification = async (chainId: number) => {
    if (chainId > 0) {
      await router?.push({
        name: ROUTES_NAME.MESSENGER_CHAT_BY_CHAIN,
        params: { id: chainId },
      });
      useMessengerStore().resetUnreadMessagesCount(chainId);
      /* NOTE:
        Since we have reset the unread messages count in messengerStore,
        we just need to call _updateBadgeCount that is using messengerStore.getUnreadMessagesCount.
        So no need to increment the badge count manually
      */
      await _updateBadgeCount();
    }
  };

  const _handleNonePushNotification = async (): Promise<void> => {
    console.warn('[WARN] Push notification type is None. No action needed. Check the server response.');

    await _logAndShowToast('Push notification type is None. No action needed. Check the server response.', 'none');
  };

  const _handleNotePushNotification = async (data: PushModel): Promise<void> => {
    await router?.push({
      name: ROUTES_NAME.POST_BY_ID,
      params: { id: data.id },
    });
  };

  const _handleMessagePushNotification = async (data: PushModel): Promise<void> => {
    const result = await useChatStore().getChainById(Number(data.id));
    if (!result) {
      console.error('[ERROR] Failed to get chat by chain id: ', data);
      return;
    }

    await router?.push({
      name: ROUTES_NAME.MESSENGER_CHAT_BY_CHAIN,
      params: { id: data.id },
    });
  };

  const _handleGroupPushNotification = async (data: PushModel): Promise<void> => {
    await router?.push({
      name: ROUTES_NAME.GROUP_BY_ID,
      params: { id: data.id },
    });
  };

  const _handleUserPushNotification = async (data: PushModel): Promise<void> => {
    await router?.push({
      name: ROUTES_NAME.USER_BY_ID,
      params: { id: data.id },
    });
  };

  const _handleMessageNotification = async (goToId: number) => {
    await router.push({
      name: ROUTES_NAME.MESSENGER_CHAT_BY_CHAIN,
      params: { id: goToId },
    });
  };

  const _handleUserRelatedNotification = async (goToId: number) => {
    await router.push({
      name: ROUTES_NAME.USER_BY_ID,
      params: { id: goToId },
    });
  };

  const _handlePostRelatedNotification = async (goToId: number) => {
    await router.push({
      name: ROUTES_NAME.POST_BY_ID,
      params: { id: goToId },
    });
  };

  const _handleGroupNotification = async (goToId: number) => {
    await router.push({
      name: ROUTES_NAME.GROUP_BY_ID,
      params: { id: goToId },
    });
  };

  const _handleWikiNotification = async (goToId: number) => {
    await useWiki().handleAction({ type: WikiActionEnum.ToCurrent, id: goToId });
  };

  const _handleFileNotification = async (goToId: number) => {
    await router.push({
      name: ROUTES_NAME.FILE_BY_ID,
      params: { id: goToId },
    });
  };

  const _handleTaskNotification = async (goToId: number) => {
    if (!useTaskManagement().getAccessToTaskManagement()) {
      console.warn('User does not have access to task management');
    }

    await useLoading().create(useI18n().t('loading'));

    const isExists = await useProjectsStore().taskById(goToId);

    if (isExists) {
      const projectId = useProjectsStore().getProjectIdByTaskId(goToId);
      if (projectId) {
        if (!useProjectsStore().getProjectById(projectId)) {
          await useProjectsStore().projectById(projectId);
        }

        await router.push({
          query: { showTaskId: goToId, projectId: projectId },
        });
      }
    }

    await useLoading().dismiss();
  };

  /** @todo */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _handleExternalUrlPushNotification = async (data: PushModel): Promise<void> => {
    await _logAndShowToast('External URL push notification', 'external-url');
  };

  /** @todo */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _handleWikiPushNotification = async (data: PushModel): Promise<void> => {
    await _logAndShowToast('Wiki push notification', 'wiki');
  };

  /** @todo */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _handleFilePushNotification = async (data: PushModel): Promise<void> => {
    await _logAndShowToast('File push notification', 'file');
  };
  //#endregion

  //#region Public methods
  async function initLocalNotifications(): Promise<void> {
    if (isNativeMobile) {
      let permStatus = await LocalNotifications.checkPermissions();
      if (permStatus.display === 'prompt') {
        permStatus = await LocalNotifications.requestPermissions();
      }
      if (permStatus.display !== 'granted') {
        console.debug('[DEBUG] Permissions for local notifications denied. Showing alert...');
        /**
         * As per @link https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1865#note_101788
         */
        // alert(t('deniedPermissions'));
      }

      await LocalNotifications.registerActionTypes({
        types: [
          {
            id: 'NEW_CHAT_MESSAGE',
            actions: [
              {
                id: 'view',
                title: t('open'),
              },
            ],
          },
        ],
      });

      await LocalNotifications.addListener('localNotificationActionPerformed', (notification: any) => {
        useAppStore().$patch((state: AppState) => {
          state.fromMobilePush = true;
        });

        _handleLocalNotification(notification.notification.id);
      });
    }
  }

  async function scheduleLocalNotification(newMess: MessageModel): Promise<void> {
    const scheduleObj = {
      notifications: [
        {
          title: newMess.authorFullName,
          body: _getMessageBody(newMess),
          id: newMess.chainId,
          actionTypeId: 'NEW_CHAT_MESSAGE',
        },
      ],
    };
    /**
     * @note Since we are scheduling a new local notification
     * when a _onPushMessage is called (connection.on('pushMessage', _onPushMessage))
     * _onPushMessage inside calls messengerStore.updateLastMessage that updates messengerStore.data.unreadMessagesCount
     * So we just need to call _updateBadgeCount that is using messengerStore.getUnreadMessagesCount,
     * no need to increment the badge count manually
     *
     * @see _registerConnectionEvents - src/helpers/useWebSocketsHelper.ts
     */
    await _updateBadgeCount();
    await LocalNotifications.schedule(scheduleObj);
  }

  async function cancelLocalNotifications(chainId: number): Promise<void> {
    await LocalNotifications.cancel({ notifications: [{ id: chainId }] });
    /**
     * @note Updating the badge count - unreadMessagesCount in messengerStore has already been updated in this chain
     * @see src/components/Messenger/MessengerContent.vue
     */
    await _updateBadgeCount();
  }

  async function resetBadgeCount(): Promise<void> {
    if (!(await _isBadgeSupported())) return;

    await Badge.clear();
  }

  async function setBadgeCount(): Promise<void> {
    if (!(await _isBadgeSupported())) return;

    await resetBadgeCount();

    let permStatus = await Badge.checkPermissions();
    if (permStatus.display === 'prompt') {
      permStatus = await Badge.requestPermissions();
    }

    try {
      await _updateBadgeCount();
    } catch (error) {
      console.error('[ERROR] Failed to set unread notifications count: ', error);
      await _logAndShowToast('Failed to set unread notifications count.', 'error');
    }
  }

  async function initPushNotifications(): Promise<void> {
    if (!isNativeMobile) return;

    await PushNotifications.addListener('registration', async (token) => {
      pushInitialized = true;

      await setBadgeCount();

      const deviceId = await Device.getId();
      const appInfo = await App.getInfo();

      await $api.account.register({
        pushToken: token.value,
        deviceId: deviceId.identifier,
        platform: isPlatform('android') ? 'android' : 'ios',
        packageId: appInfo.name,
        allNetworks: true,
      });
    });

    await PushNotifications.addListener('registrationError', (err) => {
      console.error('[ERROR] Registration error: ', err.error);
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    await PushNotifications.addListener('pushNotificationReceived', async (notification: PushNotificationSchema) => {
      /**
       * @note Since _updateBadgeCount is using notificationsStore.getUnreadNotificationsCount,
       * we just need to call _updateBadgeCount to update the badge count,
       * no need to increment the badge count manually
       * notificationsStore.getUnreadNotificationsCount + messengerStore.getUnreadMessagesCount
       * SHOULD BE equal to the badge count ALWAYS
       */
      await _updateBadgeCount();
    });

    await PushNotifications.addListener('pushNotificationActionPerformed', (action: ActionPerformed) => {
      useAppStore().$patch((state: AppState) => {
        state.fromMobilePush = true;
      });

      handlePushNotification(action.notification);
    });

    console.debug('[DEBUG] Push notifications initialized. Checking permissions...');
    let permStatus = await PushNotifications.checkPermissions();
    if (permStatus.receive === 'prompt') {
      console.debug('[DEBUG] Requesting permissions...');
      permStatus = await PushNotifications.requestPermissions();
    }
    if (permStatus.receive !== 'granted') {
      console.debug('[DEBUG] Permissions for push notifications denied. Showing alert...');
      /**
       * As per @link https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1865#note_101788
       */
      // alert(t('deniedPermissions'));
    }

    await PushNotifications.register();
  }

  async function cancelPushNotifications(): Promise<void> {
    if (isNativeMobile) {
      const deviceId = await Device.getId();
      const appInfo = await App.getInfo();
      await $api.account.unRegister({
        deviceId: deviceId.identifier,
        platform: isPlatform('android') ? 'android' : 'ios',
        packageId: appInfo.name,
        allNetworks: true,
      });
    }
  }

  async function handlePushNotification(notification: PushNotificationSchema): Promise<void> {
    const data = notification.data as PushModel;

    try {
      const handleFunction = _pushTypeHandlers[data.t];
      if (handleFunction) {
        await handleFunction(data);
      } else {
        throw new Error(`Unknown push notification type: ${data.t}`);
      }

      await useNotificationsStore().markAs([Number(data.id)], true);
    } catch (error) {
      console.error('[ERROR] Error handling push notification: ', error, data);
      await _logAndShowToast('Error handling push notification.', 'error');
    } finally {
      await _updateBadgeCount();
    }
  }

  async function handleNotificationClick(
    id: number,
    isRead: boolean,
    type: NotificationsPushActionsEnum,
    entityId: number
  ): Promise<void> {
    const handler = _notificationHandlers[type];

    if (handler) {
      await handler(entityId);
    } else {
      console.warn(`[WARN] No handler for notification type: ${type}`);
    }

    if (!isRead) {
      const result = await useNotificationsStore().markAs([id], true);
      if (!result) {
        useToasts().showSonnerToast(t('errorResponse'), false);
      }
    }
  }
  //#endregion

  //#region Mapping
  const _pushTypeHandlers: Record<PushType, PushNotificationHandler> = Object.freeze({
    [PushType.None]: _handleNonePushNotification,
    [PushType.Note]: _handleNotePushNotification,
    [PushType.Message]: _handleMessagePushNotification,
    [PushType.AddedToGroup]: _handleGroupPushNotification,
    [PushType.InvitedToGroup]: _handleGroupPushNotification,
    [PushType.NewFollower]: _handleUserPushNotification,
    [PushType.ExternalUrl]: _handleExternalUrlPushNotification,
    [PushType.Wiki]: _handleWikiPushNotification,
    [PushType.File]: _handleFilePushNotification,
  });

  const _notificationHandlers: Record<NotificationsPushActionsEnum, (goToId: number) => Promise<void> | void> =
    Object.freeze({
      // User related notification types
      [NotificationsPushActionsEnum.User]: _handleUserRelatedNotification,
      [NotificationsPushActionsEnum.UserCreate]: _handleUserRelatedNotification,
      [NotificationsPushActionsEnum.UserNewFollower]: _handleUserRelatedNotification,
      [NotificationsPushActionsEnum.UserNewManager]: _handleUserRelatedNotification,
      [NotificationsPushActionsEnum.UserNewSubordinate]: _handleUserRelatedNotification,
      [NotificationsPushActionsEnum.UserNewLevel]: _handleUserRelatedNotification,

      // Post related notification types
      [NotificationsPushActionsEnum.Post]: _handlePostRelatedNotification,
      [NotificationsPushActionsEnum.PostCreate]: _handlePostRelatedNotification,
      [NotificationsPushActionsEnum.PostLiked]: _handlePostRelatedNotification,
      [NotificationsPushActionsEnum.PostCommentCreate]: _handlePostRelatedNotification,
      [NotificationsPushActionsEnum.PostCommentAfterYouComment]: _handlePostRelatedNotification,
      [NotificationsPushActionsEnum.PostCommentToYourUserItem]: _handlePostRelatedNotification,
      [NotificationsPushActionsEnum.PostCommentMarkedAsHelpful]: _handlePostRelatedNotification,
      [NotificationsPushActionsEnum.PostCommentCreateMentioned]: _handlePostRelatedNotification,
      [NotificationsPushActionsEnum.PostCommentLiked]: _handlePostRelatedNotification,
      [NotificationsPushActionsEnum.PostAnnouncement]: _handlePostRelatedNotification,
      [NotificationsPushActionsEnum.PostPlannedCreated]: _handlePostRelatedNotification,
      [NotificationsPushActionsEnum.PostPlannedFailed]: _handlePostRelatedNotification,

      // Message related notification type]:
      [NotificationsPushActionsEnum.Message]: _handleMessageNotification,
      [NotificationsPushActionsEnum.MessageCreate]: _handleMessageNotification,

      // Group related notification type]:
      [NotificationsPushActionsEnum.Group]: _handleGroupNotification,
      [NotificationsPushActionsEnum.GroupCreate]: _handleGroupNotification,
      [NotificationsPushActionsEnum.GroupAdded]: _handleGroupNotification,
      [NotificationsPushActionsEnum.GroupInvited]: _handleGroupNotification,
      [NotificationsPushActionsEnum.GroupRequestJoin]: _handleGroupNotification,
      [NotificationsPushActionsEnum.GroupRequestJoinDeny]: _handleGroupNotification,

      // Wiki related notification type]:
      [NotificationsPushActionsEnum.Wiki]: _handleWikiNotification,
      [NotificationsPushActionsEnum.WikiCreate]: _handleWikiNotification,
      [NotificationsPushActionsEnum.WikiNewVersion]: _handleWikiNotification,
      [NotificationsPushActionsEnum.WikiMentioned]: _handleWikiNotification,

      // File related notification type]:
      [NotificationsPushActionsEnum.File]: _handleFileNotification,
      [NotificationsPushActionsEnum.FileCreate]: _handleFileNotification,
      [NotificationsPushActionsEnum.FileNewVersion]: _handleFileNotification,

      // Task management related notification type]:
      [NotificationsPushActionsEnum.Task]: _handleTaskNotification,
      [NotificationsPushActionsEnum.TaskCreated]: _handleTaskNotification,
      [NotificationsPushActionsEnum.TaskClosed]: _handleTaskNotification,
      [NotificationsPushActionsEnum.TaskReopened]: _handleTaskNotification,
      [NotificationsPushActionsEnum.TaskArchived]: _handleTaskNotification,
      [NotificationsPushActionsEnum.TaskDeArchived]: _handleTaskNotification,
      [NotificationsPushActionsEnum.TaskTitleUpdated]: _handleTaskNotification,
      [NotificationsPushActionsEnum.TaskDescriptionUpdated]: _handleTaskNotification,
      [NotificationsPushActionsEnum.TaskAssigneeChanged]: _handleTaskNotification,
      [NotificationsPushActionsEnum.TaskMilestoneChanged]: _handleTaskNotification,
      [NotificationsPushActionsEnum.TaskDateStartUpdated]: _handleTaskNotification,
      [NotificationsPushActionsEnum.TaskDateDueUpdated]: _handleTaskNotification,
      [NotificationsPushActionsEnum.TaskTagsAdded]: _handleTaskNotification,
      [NotificationsPushActionsEnum.TaskTagsRemoved]: _handleTaskNotification,
      [NotificationsPushActionsEnum.TaskFilesAdded]: _handleTaskNotification,
      [NotificationsPushActionsEnum.TaskFilesRemoved]: _handleTaskNotification,
      [NotificationsPushActionsEnum.TaskWikisAdded]: _handleTaskNotification,
      [NotificationsPushActionsEnum.TaskWikisRemoved]: _handleTaskNotification,
      [NotificationsPushActionsEnum.TaskLinksAdded]: _handleTaskNotification,
      [NotificationsPushActionsEnum.TaskLinksRemoved]: _handleTaskNotification,
      [NotificationsPushActionsEnum.TaskParticipantsAdded]: _handleTaskNotification,
      [NotificationsPushActionsEnum.TaskParticipantsRemoved]: _handleTaskNotification,
      [NotificationsPushActionsEnum.TaskCommentCreated]: _handleTaskNotification,
      [NotificationsPushActionsEnum.TaskCommentUpdated]: _handleTaskNotification,
      [NotificationsPushActionsEnum.TaskCommentDeleted]: _handleTaskNotification,
      [NotificationsPushActionsEnum.TaskMoved]: _handleTaskNotification,
      [NotificationsPushActionsEnum.TaskReporterChanged]: _handleTaskNotification,

      // Other
      [NotificationsPushActionsEnum.None]: () =>
        console.warn(`[WARN] No handler for notification type: ${NotificationsPushActionsEnum.None}`),
      [NotificationsPushActionsEnum.ExternalUrlCreate]: () =>
        console.warn(`[WARN] No handler for notification type: ${NotificationsPushActionsEnum.ExternalUrlCreate}`),
    });
  //#endregion

  return {
    resetBadgeCount,
    setBadgeCount,
    initLocalNotifications,
    scheduleLocalNotification,
    cancelLocalNotifications,
    initPushNotifications,
    cancelPushNotifications,
    handleNotificationClick,
  };
}

export const isPushInitialized = (): boolean => pushInitialized;
