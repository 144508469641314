<template>
  <div class="folder">
    <div
      v-for="(item, index) in folderData"
      :key="`folder_${index}`"
      class="folder-block"
      @click.stop="openFolder(item)"
    >
      <div class="icon">
        <ion-icon color="medium" size="large" :icon="icons.folderOutline"></ion-icon>
        <ion-badge color="medium">{{ $t('feed.folder.title') }}</ion-badge>
      </div>
      <div class="info">
        <ion-label class="title">{{ item.name }}</ion-label>
        <ion-text
          class="text"
          v-html="item.author?.fullName ?? 'Someone' + ', ' + formatDateHelper(item.createdAt, 'year')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IonText, IonIcon, IonLabel, IonBadge } from '@ionic/vue';
import { folderOutline, caretForwardOutline } from 'ionicons/icons';
import { cloneDeep } from 'lodash';
import type { PropType } from 'vue';

import { openDocsFolderPreviewModal, formatDateHelper } from '@/helpers';
import type { FolderModel } from '@/types';

const icons = {
  folderOutline,
  caretForwardOutline,
};
defineProps({
  folderData: {
    type: Object as PropType<FolderModel[]>,
    required: true,
  },
});

const openFolder = async (folder: FolderModel) => {
  await openDocsFolderPreviewModal(cloneDeep(folder));
};
</script>

<style scoped lang="scss">
.folder {
  margin-top: 1rem;
}
.folder-block {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border: 1px solid var(--ion-color-light-custom);
  border-radius: 4px;
}
.folder-block:not(:last-child) {
  margin-bottom: 0.5rem;
}
.folder-block .icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.folder-block .icon ion-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 44px;
  flex-shrink: 0;
}
.folder-block ion-badge {
  border-radius: 4px;
  font-size: 0.7rem;
  width: 44px;
}
.folder-block:hover {
  cursor: pointer;
  opacity: 0.7;
}
.folder-block .info {
  margin-left: 1rem;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.folder-block ion-text {
  line-clamp: 3;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--ion-color-medium);
}
</style>
